import type { FC } from 'react';
import { useTranslation } from 'next-i18next';
import type { LabelProps } from './Label.types';
import styles from './Label.module.scss';
const Label: FC<LabelProps> = ({
  children,
  variant = 'top',
  className,
  skew = 'none'
}) => {
  const {
    t
  } = useTranslation();
  const defaultText = t(`labels.${variant}`, variant);
  return <div className={cn(styles.root, styles[variant], styles[`${skew}Skew`], className)} data-sentry-component="Label" data-sentry-source-file="Label.tsx">{children ?? defaultText}</div>;
};
export { Label };