;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"vD-VZHeDsPonfmB9N5ymc"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';

import { BASE_CONFIG, isProdDeploy } from './sentry.constants';

if (isProdDeploy) {
  Sentry.init({
    ...BASE_CONFIG,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [Sentry.replayIntegration()],
    beforeSend(event, hint) {
      // https://github.com/getsentry/sentry-javascript/issues/2514#issuecomment-603971338
      if (hint.originalException === 'Timeout') {
        return null;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const extra = event.extra as { arguments?: Array<any> };

      if (extra?.arguments?.[0]?.currentTarget === 'head > link.jv-css') {
        return null;
      }

      return event;
    },
  });
}
