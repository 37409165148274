import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { extendAnalyticsData, selectUserIsAuthenticated, showModal, useQueryPopupOpen } from '@mwl/core-lib';
import type { ButtonAsButton, ButtonAsLink } from '@/components/Button/Button';
import { Button } from '@/components/Button/Button';
import { useTypedDispatch, useTypedSelector } from '@/hooks';
import { bonusPacketModalId } from '../../BonusPacketCard.constants';
import { BonusPacketCardPopup } from '../BonusPacketCardPopup/BonusPacketCardPopup';
import type { BonusPacketCardButtonProps } from './BonusPacketCardButton.types';
const BonusPacketCardButton: FC<BonusPacketCardButtonProps> = ({
  className,
  available,
  analytics
}) => {
  const {
    t
  } = useTranslation('common');
  const {
    createLinkProps
  } = useQueryPopupOpen();
  const isAuth = useTypedSelector(selectUserIsAuthenticated);
  const dispatch = useTypedDispatch();
  const handleClickReceive = useCallback(() => {
    if (!isAuth) {
      dispatch(showModal({
        id: bonusPacketModalId,
        content: <BonusPacketCardPopup />
      }));
    }
  }, [isAuth, dispatch]);
  const buttonProps = useMemo(() => {
    if (isAuth) {
      return ({
        as: 'link',
        ...createLinkProps({
          queryName: 'deposit'
        })
      } as Omit<ButtonAsLink, 'children'>);
    }
    return ({
      as: 'button',
      onClick: handleClickReceive
    } as Omit<ButtonAsButton, 'children'>);
  }, [isAuth, handleClickReceive, createLinkProps]);
  const buttonText = useMemo(() => {
    if (isAuth && !available) {
      return t('bonus_packet.soon', 'Soon');
    }
    if (!isAuth) {
      return t('bonus_packet.take_it', 'Take it');
    }
    return t('bonus_packet.receive', 'Receive');
  }, [available, isAuth, t]);
  return <Button {...buttonProps} className={className} size="md" fontWeight="bold" fontSize="lg" fullWidth variant="fulfilled" color="red" type="button" onClick={handleClickReceive} disabled={!available && isAuth} data-bonus-button-info rounded analytics={extendAnalyticsData(analytics, {
    href: 'href' in buttonProps ? buttonProps.href : undefined
  })} data-sentry-element="Button" data-sentry-component="BonusPacketCardButton" data-sentry-source-file="BonusPacketCardButton.tsx">
      {buttonText}
    </Button>;
};
export { BonusPacketCardButton };