import type { FC } from 'react';
import type { ProductType } from '@mwl/core-lib';
import { selectCasinoFiltersShowResults } from '@mwl/core-lib';
import { CasinoSearchResults } from '@/components/CasinoSearchResults/CasinoSearchResults';
import { useMediaWidthDown, useTypedSelector } from '@/hooks';
import { casinoLayoutObject, casinoPageObject } from '@/utils';
import { Layout } from '../Layout/Layout';
import { CasinoLayoutBanners } from './components/CasinoLayoutBanners/CasinoLayoutBanners';
import { CasinoLayoutNavigation } from './components/CasinoLayoutNavigation/CasinoLayoutNavigation';
import { Sidebar } from './components/Sidebar/Sidebar';
import type { CasinoLayoutProps } from './CasinoLayout.types';
import styles from './CasinoLayout.module.scss';
const defaultProductType: Array<ProductType> = ['casino', 'fast_games'];
const CasinoLayout: FC<CasinoLayoutProps> = ({
  children,
  banners,
  productType = defaultProductType,
  navigation = true,
  title
}) => {
  const is850Screen = useMediaWidthDown('screen850');
  const showFilterResult = useTypedSelector(selectCasinoFiltersShowResults);
  const withSidebar = !is850Screen && navigation;
  return <Layout title={title} sticky={withSidebar} data-sentry-element="Layout" data-sentry-component="CasinoLayout" data-sentry-source-file="CasinoLayout.tsx">
      <div className={cn(styles.root, {
      [styles.withSidebar]: withSidebar
    })}>
        {withSidebar && <Sidebar />}
        <div className={styles.content} {...casinoLayoutObject.nodeProps}>
          <CasinoLayoutBanners banners={banners} data-sentry-element="CasinoLayoutBanners" data-sentry-source-file="CasinoLayout.tsx" />
          <CasinoLayoutNavigation productType={productType} navigation={is850Screen && navigation} data-sentry-element="CasinoLayoutNavigation" data-sentry-source-file="CasinoLayout.tsx" />
          {showFilterResult && <CasinoSearchResults {...casinoPageObject.casinoSearchResults.nodeProps} />}
          {!showFilterResult && children}
        </div>
      </div>
    </Layout>;
};
export { CasinoLayout };