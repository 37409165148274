import type { AxiosInstance, GetCasinoProviderListRequestV2, ItemsLoadableLoadParams } from '@mwl/core-lib';
import { getCasinoProviderListV2 } from '@mwl/core-lib';

export const loadProviders =
  ({ genre = [], feature = [], productType = ['casino'] }: Partial<GetCasinoProviderListRequestV2>) =>
  async ({ page, size, axiosConfig }: ItemsLoadableLoadParams, axiosInstance?: AxiosInstance) => {
    const { data, status, error } = await getCasinoProviderListV2(
      {
        genre,
        feature,
        productType,
        size,
        page,
      },
      axiosConfig,
      axiosInstance,
    );

    if (status !== 200 || !data) {
      return { isError: true, error };
    }

    return {
      page: page + 1,
      items: data.content ?? [],
      hasMore: !data.last,
      totalElements: data.totalElements || 0,
    };
  };
