import type { FC } from 'react';
import { memo, useMemo } from 'react';
import { Message } from '../Message/Message';
import type { PopupClasses } from '../Popup/Popup';
import { Popup } from '../Popup/Popup';
import type { ModalMessageProps } from './ModalMessage.types';
import styles from './ModalMessage.module.scss';
const defaultPopupClasses = {
  wrapper: styles.wrapper
};
const BaseModalMessage: FC<ModalMessageProps> = ({
  show,
  onClick,
  onClose,
  content,
  children,
  popupClasses,
  withCloseButton,
  analytics,
  portalContainer,
  ...props
}) => {
  const {
    click: messageAnalytics,
    ...popupAnalytics
  } = analytics || {};
  const popupClassesResult = useMemo<PopupClasses>(() => ({
    ...defaultPopupClasses,
    ...(popupClasses ?? {})
  }), [popupClasses]);
  return <Popup show={show} onClose={onClose} classes={popupClassesResult} className={styles.root} withCloseButton={withCloseButton} analytics={popupAnalytics} portalContainer={portalContainer} data-sentry-element="Popup" data-sentry-component="BaseModalMessage" data-sentry-source-file="ModalMessage.tsx">
      {children || <Message {...props} onClick={onClick || onClose} analytics={{
      click: messageAnalytics
    }}>
          {content}
        </Message>}
    </Popup>;
};
const ModalMessage = memo(BaseModalMessage);
export * from './ModalMessage.types';
export { ModalMessage };