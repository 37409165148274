import type { FC } from 'react';
import { ButtonIcon } from '../ButtonIcon/ButtonIcon';
import type { CutButtonProps } from './CutButton.types';
import styles from './CutButton.module.scss';
export const CutButton: FC<CutButtonProps> = ({
  color,
  children,
  className,
  ...props
}) => {
  return <ButtonIcon {...props} as="button" variant="fulfilled" color={color} cut className={cn(styles.root, className)} data-sentry-element="ButtonIcon" data-sentry-component="CutButton" data-sentry-source-file="CutButton.tsx">
      {children}
    </ButtonIcon>;
};
export * from './CutButton.types';