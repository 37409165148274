import type { FC } from 'react';
import BtcIcon from '@public/assets/payments/icons/btc.svg';
import MasterCardIcon from '@public/assets/payments/icons/mastercard.svg';
import UsdtIcon from '@public/assets/payments/icons/usdt.svg';
import UzcardIcon from '@public/assets/payments/icons/uzcard.svg';
import VisaIcon from '@public/assets/payments/icons/visa.svg';
import { appPageObject } from '@/utils';
import type { PaymentsListProps } from './PaymentsList.types';
import styles from './PaymentsList.module.scss';
const icons = [VisaIcon, MasterCardIcon, UzcardIcon, BtcIcon, UsdtIcon];
const PaymentsList: FC<PaymentsListProps> = ({
  className
}) => {
  return <div className={cn(styles.root, className)} data-sentry-component="PaymentsList" data-sentry-source-file="PaymentsList.tsx">
      {icons.map((Icon, index) => <Icon {...appPageObject.footer.payment(index.toString()).nodeProps} key={index} />)}
    </div>;
};
export { PaymentsList };