import type { FC } from 'react';
import { memo, useCallback, useState } from 'react';
import DefaultSportIcon from '@public/assets/sport/icons/defaultSport.svg';
import { Image } from '@/components/Image/Image';
import type { SportIconProps } from './SportIcon.types';
import styles from './SportIcon.module.scss';
const BaseSportIcon: FC<SportIconProps> = ({
  url,
  fallbackUrl,
  className,
  classes,
  size,
  alt,
  fill,
  isActive,
  ...otherProps
}) => {
  const [isImageLoadError, setIsImageLoadError] = useState(false);
  const [isFallbackImageLoadError, setIsFallbackImageLoadError] = useState(false);
  const onErrorHandler = useCallback(() => setIsImageLoadError(true), []);
  const onFallbackErrorHandler = useCallback(() => setIsFallbackImageLoadError(true), []);
  const altText = alt || 'Sport Icon';
  if (url && !isImageLoadError) {
    return <Image {...otherProps} className={cn(className, isActive && styles.active, isActive && classes?.customIsActive)} alt={altText} src={url} width={size} height={size} fill={fill} onError={onErrorHandler} />;
  }
  if (fallbackUrl && !isFallbackImageLoadError) {
    return <Image {...otherProps} className={cn(className, isActive && styles.active, isActive && classes?.customIsActive)} alt={altText} src={fallbackUrl} width={size} height={size} fill={fill} onError={onFallbackErrorHandler} />;
  }
  return <DefaultSportIcon {...otherProps} className={cn(styles.defaultSportIcon, className, isActive && styles.active, isActive && classes?.customIsActive)} style={{
    maxHeight: size
  }} alt={altText} width={size} height={size} data-sentry-element="DefaultSportIcon" data-sentry-component="BaseSportIcon" data-sentry-source-file="SportIcon.tsx" />;
};
export * from './SportIcon.types';
export const SportIcon = memo(BaseSportIcon);