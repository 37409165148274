import type { FC } from 'react';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { ButtonHaveAccount } from './components/ButtonHaveAccount/ButtonHaveAccount';
import { ButtonRegistration } from './components/ButtonRegistration/ButtonRegistration';
import { SecondStepRegistration } from './components/SecondStepRegistration/SecondStepRegistration';
import { ThirdStepRegistration } from './components/ThirdStepRegistration/ThirdStepRegistration';
import type { FormRegistrationFullData, FormRegistrationFullProps } from './FormRegistrationFull.types';
import styles from './FormRegistrationFull.module.scss';
const BaseFormRegistrationFull: FC<FormRegistrationFullProps> = ({
  isModal,
  bonusType,
  className,
  step,
  onChangeStep
}) => {
  const [formData, setFormData] = useState<FormRegistrationFullData>();
  const formRef = useRef(null);
  const handleClickRegistration = useCallback(() => onChangeStep('second'), [onChangeStep]);
  useEffect(() => {
    if (formData && formData?.bonusType !== bonusType) {
      setFormData({
        ...formData,
        bonusType
      });
    }
  }, [bonusType, formData]);
  return <div className={cn(styles.root, className)} ref={formRef} data-sentry-component="BaseFormRegistrationFull" data-sentry-source-file="FormRegistrationFull.tsx">
      {step === 'first' && <div className={styles.buttonContainer}>
          <ButtonRegistration className={styles.column} onClick={handleClickRegistration} />
          <ButtonHaveAccount className={cn(styles.column, styles.accountButton)} />
        </div>}
      {step === 'second' && <SecondStepRegistration isModal={isModal} bonusType={bonusType} onChangeStep={onChangeStep} setFormData={setFormData} parentRef={formRef} />}
      {step === 'third' && <ThirdStepRegistration onChangeStep={onChangeStep} bonusType={bonusType} formData={formData} />}
    </div>;
};
const FormRegistrationFull = memo(BaseFormRegistrationFull);
export * from './FormRegistrationFull.types';
export { FormRegistrationFull };