import type { FC } from 'react';
import { memo, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import { getEighteenYearsAgoDate, useAuthFormOptions, useRegistrationPhoneHandlers } from '@mwl/core-lib';
import { FlagIcon } from '@mwl/ui';
import MailIcon from '@public/assets/common/icons/mail.svg';
import ProfileIcon from '@public/assets/common/icons/profile.svg';
import HomeIcon from '@public/assets/store/icons/home.svg';
import { Button } from '@/components/Button/Button';
import type { CheckboxClasses } from '@/components/Checkbox/Checkbox.types';
import { DatepickerAdaptive } from '@/components/DatepickerAdaptive/DatepickerAdaptive';
import { FormField } from '@/components/FormField/FormField';
import { FormFieldHidden } from '@/components/FormFieldHidden/FormFieldHidden';
import { FormFieldPhone } from '@/components/FormFieldPhone/FormFieldPhone';
import { FormMessage } from '@/components/FormMessage/FormMessage';
import { Loader } from '@/components/Loader/Loader';
import { Select } from '@/components/Select/Select';
import { routes } from '@/constants';
import { useRegister } from '@/context';
import { useTogglePopupRegistrationBonus } from '@/hooks';
import { ButtonSignIn } from '../ButtonSignIn/ButtonSignIn';
import { ButtonSignUp } from '../ButtonSignUp/ButtonSignUp';
import { CheckboxSignUp } from '../CheckboxSignUp/CheckboxSignUp';
import { PromoCodeField } from '../PromoCodeField/PromoCodeField';
import { useRegistrationExtendedForm, useRegistrationExtendedHandlers } from './FormRegistrationExtended.hooks';
import type { FormRegistrationExtendedProps, FormRegistrationExtendedValues } from './FormRegistrationExtended.types';
import styles from './FormRegistrationExtended.module.scss';
const checkboxClasses: CheckboxClasses = {
  text: styles.checkboxText
};
const BaseFormRegistrationExtended: FC<FormRegistrationExtendedProps> = ({
  className,
  variant = 'default',
  parentRef
}) => {
  const {
    t
  } = useTranslation('common');
  const {
    updateCurrency
  } = useRegister();
  const {
    codes,
    currencies,
    countries,
    userCountry,
    defaultCountry
  } = useAuthFormOptions({
    FlagIcon
  });
  const {
    handleSubmit,
    isLoading,
    error,
    formState,
    changeField
  } = useRegistrationExtendedForm();
  const {
    handleInputChange,
    handleChangeDate
  } = useRegistrationExtendedHandlers({
    changeField
  });
  const {
    handleSelectCode,
    handlePhoneChange,
    handleSelectCountry,
    handleSelectCurrency
  } = useRegistrationPhoneHandlers<FormRegistrationExtendedValues>({
    countries,
    codes,
    changeField,
    updateCurrency
  });
  useTogglePopupRegistrationBonus({
    fieldsValues: [formState.values.email, formState.values.phone.replace(/\D+/g, ''), formState.values.password]
  });
  const isAllAccepted = formState.values.agree1 && formState.values.agree2 && formState.values.agree3 && formState.values.agree4 && formState.values.agree5 && formState.values.agree6;
  const isAnyAccepted = formState.values.agree1 || formState.values.agree2 || formState.values.agree3 || formState.values.agree4 || formState.values.agree5 || formState.values.agree6;
  const commonError = useMemo(() => {
    if (error && error.type !== '/email-already-exists' && error.type !== '/phone-already-exists') {
      return Array.isArray(error.message) ? t(error.message) : error.message;
    }
    return null;
  }, [error, t]);
  const handleAcceptAll = () => {
    const value = !isAllAccepted || !isAnyAccepted;
    changeField('agree1', value);
    changeField('agree2', value);
    changeField('agree3', value);
    changeField('agree4', value);
    changeField('agree5', value);
    changeField('agree6', value);
  };
  useEffect(() => {
    if (codes.length) {
      handleSelectCode(codes[0]);
    }
  }, [codes, handleSelectCode]);
  useEffect(() => {
    if (countries.length) {
      const countryValue = countries.find(country => country.value === userCountry) ?? defaultCountry;
      handleSelectCountry(countryValue);
    }
  }, [countries, defaultCountry, handleSelectCountry, userCountry]);
  useEffect(() => {
    if (formState.values.country && currencies?.length) {
      const currencyOption = currencies.find(value => value.value === formState.values.country.currency);
      handleSelectCurrency(currencyOption ?? currencies[0]);
    }
  }, [formState.values.country, currencies, handleSelectCurrency]);
  return <form className={cn(styles.root, className, styles[`${variant}Variant`])} onSubmit={handleSubmit} data-sentry-component="BaseFormRegistrationExtended" data-sentry-source-file="FormRegistrationExtended.tsx">
      {commonError && <FormMessage className={styles.message}>{commonError}</FormMessage>}
      {isLoading && <Loader />}
      <div className={styles.content}>
        <FormField as="input" placeholder={t('form.field.name', 'Name')} value={formState.values.name} name="name" autoComplete="name" onChange={handleInputChange} isError={Boolean(formState.isTouched && formState.errors?.name)} className={cn({
        [styles.isEmpty]: !formState.values.name?.length
      })} classes={{
        componentWrapper: styles.input
      }} prefix={<ProfileIcon className={styles.prefixIcon} />} data-sentry-element="FormField" data-sentry-source-file="FormRegistrationExtended.tsx" />
        <DatepickerAdaptive className={styles.datePicker} classes={{
        input: styles.dateInput
      }} label={t('form.date_of_birth', 'Birthday')} placeholder={t('form.date_of_birth', 'Date of birth')} selected={new Date(formState.values.dateOfBirth).getTime()} onChange={handleChangeDate} dateFormat="dd.MM.yyyy" error={Boolean(formState.isTouched && formState.errors?.dateOfBirth)} maxDate={new Date(getEighteenYearsAgoDate())} adaptive data-sentry-element="DatepickerAdaptive" data-sentry-source-file="FormRegistrationExtended.tsx" />

        <FormField as="input" placeholder={t('form.field.address', 'Address')} value={formState.values.address} name="address" autoComplete="off" data-preserve-space="true" onChange={handleInputChange} isError={Boolean(formState.isTouched && formState.errors?.address)} className={cn({
        [styles.isEmpty]: !formState.values.address?.length
      })} classes={{
        componentWrapper: styles.input
      }} prefix={<HomeIcon className={styles.prefixIcon} />} data-sentry-element="FormField" data-sentry-source-file="FormRegistrationExtended.tsx" />
        <FormField as="input" placeholder={t('form.field.email', 'E-mail')} value={formState.values.email} name="email" inputMode="email" autoComplete="email" autoCapitalize="off" prefix={<MailIcon className={styles.prefixIcon} />} onChange={handleInputChange} isError={Boolean(formState.isTouched && formState.errors?.email)} className={cn({
        [styles.isEmpty]: !formState.values.email?.length
      })} classes={{
        componentWrapper: styles.input
      }} data-sentry-element="FormField" data-sentry-source-file="FormRegistrationExtended.tsx" />

        <FormFieldPhone value={formState.values.phone} options={codes} name="phone" autoComplete="tel" className={cn(styles.row, styles.phoneField)} selected={formState.values.code} parentRef={parentRef} onSelect={handleSelectCode} onChange={handlePhoneChange} isError={Boolean(formState.isTouched && formState.errors?.phone)} classes={{
        componentWrapper: styles.input
      }} data-sentry-element="FormFieldPhone" data-sentry-source-file="FormRegistrationExtended.tsx" />
        <FormFieldHidden placeholder={t('form.field.password', 'Password')} value={formState.values.password} onChange={handleInputChange} name="password" autoComplete="new-password" isError={Boolean(formState.isTouched && formState.errors?.password)} className={cn(styles.password, {
        [styles.isEmpty]: !formState.values.password?.length
      })} classes={{
        component: styles.input
      }} onFocus={event => event.target.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })} data-sentry-element="FormFieldHidden" data-sentry-source-file="FormRegistrationExtended.tsx" />

        <Select className={styles.selectSmall} selected={formState.values.currency} options={currencies} onSelect={option => handleSelectCurrency(option)} parentRef={parentRef} headerClasses={{
        input: styles.input
      }} data-sentry-element="Select" data-sentry-source-file="FormRegistrationExtended.tsx" />
        <Select selected={formState.values.country} options={countries} onSelect={handleSelectCountry} parentRef={parentRef} className={styles.field} headerClasses={{
        input: styles.input
      }} data-sentry-element="Select" data-sentry-source-file="FormRegistrationExtended.tsx" />

        <PromoCodeField name="promoCode" value={formState.values.promoCode} onChange={handleInputChange} className={styles.promoCode} data-sentry-element="PromoCodeField" data-sentry-source-file="FormRegistrationExtended.tsx" />

        <div className={styles.terms}>
          <CheckboxSignUp classes={checkboxClasses} name="agree1" checked={formState.values.agree1} onChange={handleInputChange} className={cn(styles.column, styles.checkbox)} variant={formState.isTouched && formState.errors?.agree1 ? 'red' : 'default'} data-sentry-element="CheckboxSignUp" data-sentry-source-file="FormRegistrationExtended.tsx">
            {t('register.extended.confirm_1', 'I am 18 years old or older, I play for myself and with my own money')}
          </CheckboxSignUp>
          <CheckboxSignUp classes={checkboxClasses} name="agree2" checked={formState.values.agree2} onChange={handleInputChange} className={cn(styles.column, styles.checkbox)} variant={formState.isTouched && formState.errors?.agree2 ? 'red' : 'default'} data-sentry-element="CheckboxSignUp" data-sentry-source-file="FormRegistrationExtended.tsx">
            {t('register.extended.confirm_2', 'This is my first and only registration on banzai.bet')}
          </CheckboxSignUp>
          <CheckboxSignUp classes={checkboxClasses} name="agree3" checked={formState.values.agree3} onChange={handleInputChange} className={cn(styles.column, styles.checkbox)} variant={formState.isTouched && formState.errors?.agree3 ? 'red' : 'default'} data-sentry-element="CheckboxSignUp" data-sentry-source-file="FormRegistrationExtended.tsx">
            {t('register.extended.confirm_3', 'I have no history of self-exclusions on any betting site in the last 12 months')}
          </CheckboxSignUp>
          <CheckboxSignUp classes={checkboxClasses} name="agree4" checked={formState.values.agree4} onChange={handleInputChange} className={cn(styles.column, styles.checkbox)} variant={formState.isTouched && formState.errors?.agree4 ? 'red' : 'default'} data-sentry-element="CheckboxSignUp" data-sentry-source-file="FormRegistrationExtended.tsx">
            <Trans i18nKey="register.extended.confirm_4" components={[<Button key="rules" as="external" target="_blank" variant="text" href={routes.rules} className={styles.rulesButton} />]} data-sentry-element="Trans" data-sentry-source-file="FormRegistrationExtended.tsx">
              {'I have read and accept the <0>Rules</0>'}
            </Trans>
          </CheckboxSignUp>
          <CheckboxSignUp classes={checkboxClasses} name="agree5" checked={formState.values.agree5} onChange={handleInputChange} className={cn(styles.column, styles.checkbox)} variant={formState.isTouched && formState.errors?.agree5 ? 'red' : 'default'} data-sentry-element="CheckboxSignUp" data-sentry-source-file="FormRegistrationExtended.tsx">
            {t('register.extended.confirm_5', 'I declare that all information provided by me for banzai.bet is correct and can be verified, and I understand that providing false information in any form will result in forfeiture of all winnings from my games on banzai.bet')}
          </CheckboxSignUp>
          <CheckboxSignUp name="agree6" classes={checkboxClasses} checked={formState.values.agree6} onChange={handleInputChange} className={cn(styles.column, styles.checkbox)} variant={formState.isTouched && formState.errors?.agree6 ? 'red' : 'default'} data-sentry-element="CheckboxSignUp" data-sentry-source-file="FormRegistrationExtended.tsx">
            {t('register.extended.confirm_6', 'I understand and acknowledge that banzai.bet sets certain limits on the maximum number of prizes that can be won and/or withdrawn per day/week/month')}
          </CheckboxSignUp>
          <CheckboxSignUp classes={{
          text: styles.acceptAllText
        }} checked={isAllAccepted} onChange={handleAcceptAll} className={cn(styles.column, styles.checkbox)} variant="red" data-sentry-element="CheckboxSignUp" data-sentry-source-file="FormRegistrationExtended.tsx">
            {t('register.extended.confirm_all', 'Confirm all')}
          </CheckboxSignUp>
        </div>

        <div className={cn(styles.row, styles.buttonContainer, styles.rowWrap)}>
          <ButtonSignUp disabled={!isAllAccepted} data-sentry-element="ButtonSignUp" data-sentry-source-file="FormRegistrationExtended.tsx" />
          <ButtonSignIn data-sentry-element="ButtonSignIn" data-sentry-source-file="FormRegistrationExtended.tsx" />
        </div>
      </div>
    </form>;
};
const FormRegistrationExtended = memo(BaseFormRegistrationExtended);
export * from './FormRegistrationExtended.types';
export { FormRegistrationExtended };