import type { ChangeEventHandler, FC } from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { getFieldAttrValue, getNumberFieldFormat, selectUserBalanceCurrencySymbol } from '@mwl/core-lib';
import type { FormFieldAsInput, FormFieldAsNumberFormat } from '@/components/FormField/FormField';
import { FormField } from '@/components/FormField/FormField';
import { useTypedSelector } from '@/hooks';
import { getNumberFieldPropsByName, prepareValue } from './PaymentFieldText.utils';
import type { PaymentTextFieldProps } from './PaymentTextField.types';
import styles from './PaymentTextField.module.scss';
const maskChar = 'X';
export const PaymentTextField: FC<PaymentTextFieldProps> = ({
  type,
  title,
  name,
  value,
  onChange,
  onFocus,
  onBlur,
  isError,
  attr,
  analytics
}) => {
  const {
    t
  } = useTranslation('payment');
  const currencySymbol = useTypedSelector(selectUserBalanceCurrencySymbol);
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(event => onChange(prepareValue(event.target.value, attr)), [attr, onChange]);
  const scaleValue = getFieldAttrValue('scale', attr);
  const mask = getFieldAttrValue('pattern', attr);
  const fieldProps: FormFieldAsNumberFormat | FormFieldAsInput = useMemo(() => {
    switch (type) {
      case 'number':
        return ({
          ...getNumberFieldPropsByName({
            name,
            currencySymbol
          }),
          as: 'numberFormat',
          decimalScale: scaleValue ? Math.max(Number(scaleValue), 0) : undefined,
          onValueChange: ({
            value: currentValue
          }) => onChange(currentValue),
          format: getNumberFieldFormat(mask),
          mask: mask ? maskChar : undefined
        } as FormFieldAsNumberFormat);
      default:
        return {
          as: 'input',
          onChange: handleChange
        };
    }
  }, [type, name, currencySymbol, scaleValue, mask, handleChange, onChange]);
  return <FormField {...fieldProps} className={styles.root} classes={{
    componentWrapper: styles.input
  }} name={name} value={value} autoComplete="off" isError={isError} placeholder={title ? t(title) : ''} onFocus={onFocus} onBlur={onBlur} analytics={analytics} data-sentry-element="FormField" data-sentry-component="PaymentTextField" data-sentry-source-file="PaymentTextField.tsx" />;
};