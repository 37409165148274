import { numberFormatter, removeURLSearchParams } from '@mwl/core-lib';

type Nullable<T> = T | null | undefined;

interface GetButtonPlayOptions {
  id: number;
  pathname: string;
  url: string;
  isAuth: boolean;
}

export function getButtonPlayProps({ id, pathname, url, isAuth }: GetButtonPlayOptions) {
  if (isAuth) {
    return {
      href: {
        pathname: url,
        query: { gameId: id, mode: 'real' },
      },
    } as const;
  }

  return {
    href: { pathname: removeURLSearchParams({ url: pathname }), query: { register: 1 } },
    scroll: false,
    shallow: true,
  } as const;
}

export function getLimitText(
  minBet: Nullable<number>,
  maxBet: Nullable<number>,
  currencySign: string,
  userCurrency: string,
  enabledCurrencies: Nullable<Array<string>>,
) {
  const formatterOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  };

  const definedCurrencySign = enabledCurrencies?.includes(userCurrency) ? currencySign : '€';

  let limitPrefix = '';

  const minBetText = minBet ? numberFormatter(minBet, formatterOptions) : null;
  const maxBetText = maxBet ? numberFormatter(maxBet, formatterOptions) : null;

  if (minBetText && !maxBetText) {
    limitPrefix = `MIN: `;
  }

  if (maxBetText && !minBetText) {
    limitPrefix = `MAX: `;
  }

  return `${limitPrefix}${definedCurrencySign.toUpperCase()} ${[minBetText, maxBetText].filter(Boolean).join(' - ')}`;
}
