import type { FC } from 'react';
import { memo } from 'react';
import { useTranslation } from 'next-i18next';
import { extendAnalyticsData } from '@mwl/core-lib';
import { Button } from '@/components/Button/Button';
import { useGameHref } from './MenuItem.hooks';
import type { MenuItemProps } from './MenuItem.types';
import styles from './MenuItem.module.scss';
const BaseMenuItem: FC<MenuItemProps> = ({
  href,
  text,
  id,
  Icon,
  className,
  variant = 'default',
  isActive,
  count,
  scrollableRef,
  analytics
}) => {
  const {
    t
  } = useTranslation();
  const gameHref = useGameHref(href);
  const labelText = t(id, text);
  return <Button onClick={() => {
    if (scrollableRef?.current) {
      // eslint-disable-next-line no-param-reassign
      scrollableRef.current.scrollLeft = 0;
    }
  }} as="link" variant="text" skew="right" fontSize="sm" fontWeight="bold" color={isActive ? 'white' : 'grey-light'} href={gameHref} className={cn(styles.root, className, {
    [styles.active]: isActive,
    [styles[`${variant}Color`]]: variant !== 'default'
  })} analytics={extendAnalyticsData(analytics, {
    href: gameHref,
    text: labelText
  })} data-sentry-element="Button" data-sentry-component="BaseMenuItem" data-sentry-source-file="MenuItem.tsx">
      {count && <div className={styles.count}>{count > 99 ? '99+' : count}</div>}
      {Icon && <Icon className={styles.icon} />}
      <div className={styles.text}>{labelText}</div>
    </Button>;
};
export const MenuItem = memo(BaseMenuItem);