import type { FC } from 'react';
import BonusIcon from '@public/assets/common/icons/bonus.svg';
import BonusOpenIcon from '@public/assets/common/icons/bonusOpen.svg';
import { ButtonIcon } from '../ButtonIcon/ButtonIcon';
import type { BonusButtonProps } from './BonusButton.types';
import styles from './BonusButton.module.scss';
const BonusButton: FC<BonusButtonProps> = ({
  className,
  Icon = BonusIcon,
  IconHovered = BonusOpenIcon,
  ...props
}) => {
  return <ButtonIcon color="white" variant="fulfilled" className={cn(styles.root, className)} {...props} aria-label="bonus" data-sentry-element="ButtonIcon" data-sentry-component="BonusButton" data-sentry-source-file="BonusButton.tsx">
      <Icon className={styles.icon} data-sentry-element="Icon" data-sentry-source-file="BonusButton.tsx" />
      <IconHovered className={styles.iconHover} data-sentry-element="IconHovered" data-sentry-source-file="BonusButton.tsx" />
    </ButtonIcon>;
};
export { BonusButton };