import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { loadBonusListRequest, loadFreespinListRequest, refuseBonus, refuseFreespin } from '@mwl/core-lib';
import GiftIcon from '@public/assets/sidebar/icons/gift.svg';
import { Accordion } from '@/components/Accordion/Accordion';
import type { AccordionClasses } from '@/components/Accordion/Accordion.types';
import { useTypedDispatch } from '@/hooks';
import { useSidebarBonus } from '../../hooks/useSidebarBonus';
import type { FreespinsOrBonuses } from '../../Sidebar.types';
import { RefuseBonusModal } from '../RefuseBonusModal/RefuseBonusModal';
import { SidebarBonusItem } from '../SidebarBonusItem/SidebarBonusItem';
import { SidebarFreespinItem } from '../SidebarFreespinItem/SidebarFreespinItem';
import styles from './SidebarBonus.module.scss';
const accordeonClasses: AccordionClasses = {
  text: styles.accordeonText,
  button: styles.accordeonButton
};
const SidebarBonus = () => {
  const dispatch = useTypedDispatch();
  const [modalShown, setModalShown] = useState(false);
  const [typeForRefuse, setTypeForRefuse] = useState<null | FreespinsOrBonuses>(null);
  const {
    t
  } = useTranslation();
  const {
    handleComplete,
    bonusItem,
    freespinItem,
    bonuses,
    freespins,
    freespinsList,
    bonusList
  } = useSidebarBonus();
  const hasFreespin = freespinItem && freespins;
  const hasBonus = bonusItem && bonuses;
  const presentsCount = +Boolean(hasFreespin) + +Boolean(hasBonus);
  const title = t('sidebar.myPresents', 'My presents');
  if (presentsCount === 0) {
    return null;
  }
  const handleCloseModal = () => {
    setModalShown(false);
    setTimeout(() => setTypeForRefuse(null), 500);
  };
  const handleOkModal = async () => {
    if (typeForRefuse === null) {
      return;
    }
    if (typeForRefuse === 'bonuses') {
      await refuseBonus({
        bonus_id: String(bonusItem?.id)
      });
      const otherBonuses = bonusList?.filter(({
        id: bonusId
      }) => bonusId !== bonusItem?.id);
      if (otherBonuses.length === 0) {
        handleComplete('bonuses');
      } else {
        dispatch(loadBonusListRequest());
      }
    }
    if (typeForRefuse === 'freespins') {
      await refuseFreespin({
        freespinId: String(freespinItem?.freespinsId)
      });
      const otherFreespins = freespinsList?.filter(({
        id: freespinsId
      }) => freespinsId !== freespinItem?.freespinsId);
      if (otherFreespins.length === 0) {
        handleComplete('freespins');
      } else {
        dispatch(loadFreespinListRequest());
      }
    }
    setModalShown(false);
  };
  const handleRefuse = (type: FreespinsOrBonuses) => {
    setTypeForRefuse(type);
    setModalShown(true);
  };
  return <>
      <Accordion classes={accordeonClasses} title={<>
            <GiftIcon className={styles.giftIcon} /> {title} ({presentsCount})
          </>} data-sentry-element="Accordion" data-sentry-source-file="SidebarBonus.tsx">
        <div className={styles.root}>
          {hasFreespin && <SidebarFreespinItem className={styles.item} {...freespinItem} onComplete={() => handleRefuse('freespins')} />}
          {hasBonus && <SidebarBonusItem className={styles.item} {...bonusItem} onComplete={() => handleRefuse('bonuses')} />}
        </div>
      </Accordion>
      <RefuseBonusModal bonusType={typeForRefuse} show={modalShown} onClose={handleCloseModal} onOk={handleOkModal} data-sentry-element="RefuseBonusModal" data-sentry-source-file="SidebarBonus.tsx" />
    </>;
};
export { SidebarBonus };