import type { FC } from 'react';
import { useSwitchValue } from '@mwl/core-lib';
import { Loader } from '@/components/Loader/Loader';
import { PerfectScrollbar } from '@/components/PerfectScrollbar/PerfectScrollbar';
import { PaymentGrid } from '../PaymentGrid/PaymentGrid';
import type { PaymentLayoutProps } from './PaymentLayout.types';
import styles from './PaymentLayout.module.scss';
export const PaymentLayout: FC<PaymentLayoutProps> = ({
  className,
  classes,
  isLoading,
  payment,
  onChangePayment,
  methods,
  children,
  firstMethodRef,
  analytics
}) => {
  const {
    value: isGradientVisible,
    on: showGradient,
    off: hideGradient
  } = useSwitchValue(true);
  return <div className={cn(styles.root, className)} data-sentry-component="PaymentLayout" data-sentry-source-file="PaymentLayout.tsx">
      {isLoading ? <div className={styles.loader}>
          <Loader />
        </div> : <div className={cn(styles.content, classes?.content, {
      [styles.hasSelected]: !!payment
    })}>
          {methods.length > 0 && <div className={cn(styles.methodsWrapper, isGradientVisible && styles.methodsWrapperVisible)}>
              <PerfectScrollbar className={cn(styles.methods, classes?.methods)} onYReachEnd={hideGradient} onScrollUp={showGradient}>
                <PaymentGrid firstMethodRef={firstMethodRef} payment={payment} list={methods} onChangePayment={onChangePayment} analytics={analytics} />
              </PerfectScrollbar>
            </div>}
          <PerfectScrollbar className={cn(styles.form, classes?.form)}>{children}</PerfectScrollbar>
        </div>}
    </div>;
};
export * from './PaymentLayout.types';