import { useMemo } from 'react';
import type { FormBase, PaymentMethodField } from '@mwl/core-lib';
import { isPaymentFieldReadonly } from '@mwl/core-lib';
import type { PaymentFieldValue } from '../../types';
import { PaymentField } from '../PaymentField/PaymentField';
import type { PaymentFormTextFieldsGroups, PaymentFormTextFieldsProps } from './PaymentFormTextFields.types';
import styles from './PaymentFormTextFields.module.scss';
export const PaymentFormTextFields = <T extends FormBase,>({
  fields,
  formState
}: PaymentFormTextFieldsProps<T>) => {
  const groupedFields = useMemo(() => fields?.reduce((acc, curr) => {
    if (curr.type === 'qrCode') {
      acc.qrCode.push(curr);
    } else {
      acc.text.push(curr);
    }
    return acc;
  }, ({
    qrCode: [],
    text: []
  } as PaymentFormTextFieldsGroups)), [fields]);
  const renderField = (field: PaymentMethodField) => <PaymentField className={styles.field} key={field.name} {...field} onChange={() => undefined} value={((formState.values[field.name] || '') as PaymentFieldValue)} readOnly={isPaymentFieldReadonly(field)} data-sentry-element="PaymentField" data-sentry-component="renderField" data-sentry-source-file="PaymentFormTextFields.tsx" />;
  if (!groupedFields?.qrCode.length && !groupedFields?.text.length) {
    return null;
  }
  return <div className={styles.root} data-sentry-component="PaymentFormTextFields" data-sentry-source-file="PaymentFormTextFields.tsx">
      {groupedFields?.qrCode.map(renderField)}

      {!!groupedFields?.text.length && <div className={styles.textFieldsWrapper}>{groupedFields?.text.map(renderField)}</div>}
    </div>;
};