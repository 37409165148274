import type { FC } from 'react';
import PoweredByIcon from '@public/assets/common/icons/poweredBy.svg';
import type { PoweredByProps } from './PoweredBy.types';
import styles from './PoweredBy.module.scss';
const PoweredBy: FC<PoweredByProps> = ({
  className
}) => {
  return <div className={cn(styles.root, className)} data-sentry-component="PoweredBy" data-sentry-source-file="PoweredBy.tsx">
      <PoweredByIcon className={styles.icon} data-sentry-element="PoweredByIcon" data-sentry-source-file="PoweredBy.tsx" />
    </div>;
};
export { PoweredBy };