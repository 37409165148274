import type { FC } from 'react';
import { variants } from './MobileMenuButton.data';
import type { MobileMenuButtonProps } from './MobileMenuButton.types';
import styles from './MobileMenuButton.module.scss';
const MobileMenuButton: FC<MobileMenuButtonProps> = ({
  variant,
  onClick,
  counter
}) => {
  const {
    MainIcon,
    LeftCornerIcon,
    counterBackground,
    withBorder
  } = variants[variant];
  const counterBackgroundClass = counterBackground ? `${counterBackground}CounterBackground` : '';
  if (!MainIcon) {
    return null;
  }
  return <div className={styles.root} role="presentation" onClick={onClick} data-sentry-component="MobileMenuButton" data-sentry-source-file="MobileMenuButton.tsx">
      <div className={cn(styles.container, {
      [styles.border]: withBorder
    })}>
        {LeftCornerIcon && <div className={styles.leftCornerIconContainer}>
            <div className={styles.leftCornerIcon}>
              <LeftCornerIcon width={12} height={12} />
            </div>
            <div className={cn(styles.arc, styles.arcStart)} />
            <div className={cn(styles.arc, styles.arcEnd)} />
          </div>}
        <MainIcon width={20} height={20} data-sentry-element="MainIcon" data-sentry-source-file="MobileMenuButton.tsx" />
        {counter > 0 && <span className={cn(styles.counter, styles[counterBackgroundClass])}>{counter}</span>}
      </div>
    </div>;
};
export { MobileMenuButton };