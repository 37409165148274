import type { FC } from 'react';
import type { NotificationWrapperProps } from './NotificationWrapper.types';
import styles from './NotificationWrapper.module.scss';
const maxCount = 99;
const NotificationWrapper: FC<NotificationWrapperProps> = ({
  count,
  children
}) => {
  const collapsed = count > maxCount;
  if (!count) {
    return <>{children}</>;
  }
  return <div className={styles.root} data-sentry-component="NotificationWrapper" data-sentry-source-file="NotificationWrapper.tsx">
      <div className={styles.count}>
        {Math.min(count, maxCount)}
        {collapsed && '+'}
      </div>
      {children}
    </div>;
};
export { NotificationWrapper };