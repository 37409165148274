import AllIcon from '@public/assets/casino/icons/all.svg';
import BuyBonusIcon from '@public/assets/casino/icons/buyBonus.svg';
import CardsIcon from '@public/assets/casino/icons/cards.svg';
import FastGamesIcon from '@public/assets/casino/icons/fastGames.svg';
import JackpotsIcon from '@public/assets/casino/icons/jackpots.svg';
import RouletteIcon from '@public/assets/casino/icons/roulette.svg';
import SlotsIcon from '@public/assets/casino/icons/slots.svg';
import TableGamesIcon from '@public/assets/casino/icons/tableGames.svg';

import type { NavMenuItem } from '@/types';

import { routes } from '../routes';

export const casinoNavMenu: Array<NavMenuItem> = [
  {
    key: 'header.navigation.casino.bonus_buy',
    slug: 'bonus_buy',
    text: 'Buy bonus',
    href: {
      pathname: routes.casino.category,
      query: { category: 'bonus_buy' },
    },
    Icon: BuyBonusIcon,
    variant: 'yellow',
    analytics: {
      click: {
        eventName: 'casinoMenu_bonus_buyBtn_click',
      },
    },
  },
  {
    key: 'header.navigation.casino.slots',
    text: 'Slots',
    slug: 'slots',
    href: {
      pathname: routes.casino.category,
      query: { category: 'slots' },
    },
    Icon: SlotsIcon,
    analytics: {
      click: {
        eventName: 'casinoMenu_slotsBtn_click',
      },
    },
  },
  {
    key: 'header.navigation.casino.fast_games',
    slug: 'fast_games',
    text: 'Fast games',
    href: {
      pathname: routes.casino.category,
      query: { category: 'fast_games' },
    },
    Icon: FastGamesIcon,
    analytics: {
      click: {
        eventName: 'casinoMenu_fastGamesBtn_click',
      },
    },
  },
  {
    key: 'header.navigation.casino.jackpots',
    slug: 'jackpots',
    text: 'Jackpots',
    href: {
      pathname: routes.casino.category,
      query: { category: 'jackpots' },
    },
    Icon: JackpotsIcon,
    analytics: {
      click: {
        eventName: 'casinoMenu_jackpotsBtn_click',
      },
    },
  },
  {
    key: 'header.navigation.casino.roulette',
    slug: 'roulette',
    text: 'Roulette',
    href: {
      pathname: routes.casino.category,
      query: { category: 'roulette' },
    },
    Icon: RouletteIcon,
    analytics: {
      click: {
        eventName: 'casinoMenu_rouletteBtn_click',
      },
    },
  },
  {
    key: 'header.navigation.casino.cards',
    slug: 'cards',
    text: 'Cards',
    href: {
      pathname: routes.casino.category,
      query: { category: 'cards' },
    },
    Icon: CardsIcon,
    analytics: {
      click: {
        eventName: 'casinoMenu_cardsBtn_click',
      },
    },
  },
  {
    key: 'header.navigation.casino.table_games',
    slug: 'table_games',
    text: 'Table games',
    href: {
      pathname: routes.casino.category,
      query: { category: 'table_games' },
    },
    Icon: TableGamesIcon,
    analytics: {
      click: {
        eventName: 'casinoMenu_table_gamesBtn_click',
      },
    },
  },
  {
    key: 'header.navigation.casino.all',
    text: 'All',
    slug: 'all',
    href: {
      pathname: routes.casino.category,
      query: { category: 'all' },
    },
    Icon: AllIcon,
    analytics: {
      click: {
        eventName: 'casinoMenu_allBtn_click',
      },
    },
  },
];
