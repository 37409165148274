import type { ChangeEventHandler, FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { hidePartCharacters, onlyNumbers, payoutConfirmationV2, selectUserPhoneNumber, useForm, useSubmitForm } from '@mwl/core-lib';
import { Button } from '@/components/Button/Button';
import { FormField } from '@/components/FormField/FormField';
import { CODE_LENGTH } from '@/constants';
import { useTypedSelector } from '@/hooks';
import { SendCodeAgainButton } from '../SendCodeAgainButton/SendCodeAgainButton';
import { initialState } from './FormConfirmation.data';
import { usePaymentSendCode } from './FormConfirmation.hooks';
import type { FormConfirmationProps, FormConfirmationValues } from './FormConfirmation.types';
import { formSettings } from './FormConfirmation.utils';
import styles from './FormConfirmation.module.scss';
export const FormConfirmation: FC<FormConfirmationProps> = ({
  timer,
  confirmation,
  successHandler,
  onLimitError,
  onComplete,
  onChangeTimer
}) => {
  const {
    t
  } = useTranslation(['payment', 'common']);
  const [state, setState] = useState(initialState);
  const userPhoneNumber = useTypedSelector(selectUserPhoneNumber);
  const {
    formState,
    changeField,
    handleSubmit
  } = useForm<FormConfirmationValues>(formSettings);
  const {
    error: codeError,
    isLoading: codeLoading,
    requestCode
  } = usePaymentSendCode({
    values: formState.values,
    payoutId: confirmation?.id,
    onChangeTimer,
    onLimitError
  });
  const {
    state: {
      error,
      isLoading
    },
    onSubmitConfirm
  } = useSubmitForm({
    requestHandler: payoutConfirmationV2,
    formState: formState.values,
    onSuccess: data => {
      if (data?.result === 'SUCCESS') {
        successHandler();
      }
      if (data?.result === 'ERROR') {
        setState({
          ...state,
          ...data
        });
      }
      if (data?.code === 'VERIFICATION_ATTEMPTS_LIMIT' || data?.hasMoreAttempts === false) {
        onLimitError(data?.timer);
      }
    }
  });
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    const {
      name,
      value
    } = event.target;
    const code = onlyNumbers(value).slice(0, CODE_LENGTH);
    changeField((name as keyof FormConfirmationValues), code);
  }, [changeField]);
  const submitConfirm = useCallback(({
    code
  }: FormConfirmationValues) => {
    if (confirmation) {
      onSubmitConfirm({
        code,
        payoutId: confirmation?.id
      });
    }
  }, [confirmation, onSubmitConfirm]);
  const errorMessage = useMemo(() => {
    let result: string | null = null;
    if (error) {
      result = Array.isArray(error.message) ? t(error.message) : error.message;
    } else if (codeError) {
      result = Array.isArray(codeError.message) ? t(codeError.message) : codeError.message;
    } else if (state.error.length) {
      result = t(state.error);
    }
    return result;
  }, [error, codeError, state, t]);
  return <form className={styles.root} onSubmit={handleSubmit(submitConfirm)} data-sentry-component="FormConfirmation" data-sentry-source-file="FormConfirmation.tsx">
      <p className={styles.phoneMessage}>
        {t('payment:need_confirmation.phone_message', 'A confirmation code has been sent to {{phone}}', {
        phone: hidePartCharacters({
          value: userPhoneNumber,
          count: userPhoneNumber.length - 4,
          position: 'start'
        })
      })}
      </p>

      <FormField as="input" autoCapitalize="off" autoComplete="one-time-code" inputMode="numeric" placeholder={t('payment:need_confirmation.confirmation_code', 'Confirmation code')} label={t('payment:need_confirmation.confirmation_code', 'Confirmation code')} value={formState.values.code} name="code" disabled={!state.hasMoreAttempts} onChange={handleChange} className={cn(styles.field, {
      [styles.disabled]: !state.hasMoreAttempts
    })} isError={Boolean(formState.isTouched && formState.errors.code || !!state.error.length)} errorText={errorMessage || state.error} data-sentry-element="FormField" data-sentry-source-file="FormConfirmation.tsx" />

      <Button as="button" disabled={!state.hasMoreAttempts || isLoading || codeLoading} variant="fulfilled" color="green" size="md" fontSize="xl" fullWidth className={cn(styles.button, styles.confirm)} type="submit" rounded data-sentry-element="Button" data-sentry-source-file="FormConfirmation.tsx">
        {t('payment:need_confirmation.confirm', 'Confirm')}
      </Button>
      <SendCodeAgainButton className={styles.button} timer={timer} onClick={requestCode} onComplete={onComplete} data-sentry-element="SendCodeAgainButton" data-sentry-source-file="FormConfirmation.tsx" />
    </form>;
};