import type { FC } from 'react';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { getCookie, selectBonusPackets, selectUserInitialAuthCompleted, selectUserIsAuthenticated, setCookie, useBonusesCount, useQueryPopupOpen, useShowNotAuthPopup } from '@mwl/core-lib';
import { routes } from '@/constants';
import { useMediaWidthDown, useTypedSelector } from '@/hooks';
import { selectRegistrationBonusIsShowed, selectSidebarIsOpen } from '@/store/features/ui/selectors';
import { getLanguageDirection, popupsObject } from '@/utils';
import { BonusButton } from '../BonusButton/BonusButton';
import { Button } from '../Button/Button';
import { NotificationWrapper } from '../NotificationWrapper/NotificationWrapper';
import { Popup } from '../Popup/Popup';
import type { PopupClasses } from '../Popup/Popup.types';
import type { PopupRegistrationBonusProps, PopupRegistrationBonusState } from './PopupRegistrationBonus.types';
import styles from './PopupRegistrationBonus.module.scss';
const popupClasses: PopupClasses = {
  wrapper: styles.wrapper
};
const initialState: PopupRegistrationBonusState = {
  show: false,
  showed: false
};
const keyPopupInCookie = 'popupRegistrationBonusHide';
// Существование куки 3 часа
const cookieExpiresTime = 3 * 60 * 60 * 1000;
const BasePopupRegistrationBonus: FC<PopupRegistrationBonusProps> = ({
  show: initialShow,
  onClose
}) => {
  const {
    t,
    i18n
  } = useTranslation('notification');
  const router = useRouter();
  const {
    inWebView
  } = router.query;
  const langDirection = getLanguageDirection(i18n);
  const isSmallScreen = useMediaWidthDown('screenSm');
  const bonusesCount = useBonusesCount();
  const isAuth = useTypedSelector(selectUserIsAuthenticated);
  const isAuthCompleted = useTypedSelector(selectUserInitialAuthCompleted);
  const sidebarIsOpen = useTypedSelector(selectSidebarIsOpen);
  const bonuses = useTypedSelector(selectBonusPackets);
  const isRegistrationBonusIsShowed = useTypedSelector(selectRegistrationBonusIsShowed);
  const [state, setState] = useState<PopupRegistrationBonusState>(initialState);
  const {
    createLinkProps
  } = useQueryPopupOpen();
  const linkProps = useMemo(() => createLinkProps({
    queryName: 'register'
  }), [createLinkProps]);
  const bonus = bonuses.find(({
    registrationBonusType
  }) => registrationBonusType === 'CASINO');
  const inCookie = getCookie(keyPopupInCookie);

  /**
   * Отвечает за скрытие модалки (Получите бонус при...)
   *
   * @description Модалка не будет показываться если:
   *  - Если она была уже открыта: {inCookie}
   *  - Если пользователь авторизован: {isAuth}
   *  - Если пользователь находится на home
   *  - Если пользователь открыл сайдбар в мобилке: {sidebarIsOpen && isSmallScreen}
   *  - Если пользователь ввел хоть один символ в форму {isRegistrationBonusIsShowed}
   */
  const disableAuthShowPopup = !isAuthCompleted || isAuth || !!inCookie || state.showed || router.pathname !== routes.home || sidebarIsOpen && isSmallScreen || !isRegistrationBonusIsShowed;
  const handleShow = useCallback(() => {
    setState(prev => ({
      ...prev,
      show: true,
      showed: true
    }));
  }, []);
  const handleClose = useCallback(() => {
    onClose?.();
    setCookie(keyPopupInCookie, true, {
      expires: new Date(Date.now() + cookieExpiresTime)
    });
    setState(prev => ({
      ...prev,
      show: false
    }));
  }, [onClose]);
  useShowNotAuthPopup({
    disable: disableAuthShowPopup,
    delay: 10_000,
    onShow: handleShow
  });
  useEffect(() => {
    if (initialShow) {
      handleShow();
    }
  }, [handleShow, initialShow]);
  if (!bonus?.bonusTitle || inWebView === '1') {
    return null;
  }
  return <Popup {...popupsObject.bonus.nodeProps} closeButtonColor="red" show={state.show} onClose={handleClose} classes={popupClasses} className={styles.popup} disableClickOutside data-sentry-element="Popup" data-sentry-component="BasePopupRegistrationBonus" data-sentry-source-file="PopupRegistrationBonus.tsx">
      <div className={styles.content}>
        <NotificationWrapper count={bonusesCount} data-sentry-element="NotificationWrapper" data-sentry-source-file="PopupRegistrationBonus.tsx">
          <BonusButton className={cn(styles.bonusButton, styles.bonusBgColor)} as="button" cut={false} disabled data-sentry-element="BonusButton" data-sentry-source-file="PopupRegistrationBonus.tsx" />
        </NotificationWrapper>

        <div className={cn(styles.text, {
        [styles.right]: langDirection === 'rtl'
      })}>
          <span className={styles.register}>{t('reg_popup_register_to_get_a_bonus', 'Register to get a bonus!')} </span>
          <span className={styles.bonus}>{bonus?.bonusTitle}</span>
        </div>

        <Button as="link" fontSize="xl" fontWeight="bold" size="md" variant="fulfilled" color="red" rounded onClick={handleClose} {...linkProps} data-sentry-element="Button" data-sentry-source-file="PopupRegistrationBonus.tsx">
          {t('reg_popup_get_a_bonus', 'Get a bonus!')}
        </Button>
      </div>
    </Popup>;
};
const PopupRegistrationBonus = memo(BasePopupRegistrationBonus);
export { PopupRegistrationBonus };