import type { FC } from 'react';
import type { SearchResultTitleProps } from './SearchResultTitle.types';
import styles from './SearchResultTitle.module.scss';
const SearchResultTitle: FC<SearchResultTitleProps> = ({
  text,
  count
}) => {
  return <div className={styles.root} data-sentry-component="SearchResultTitle" data-sentry-source-file="SearchResultTitle.tsx">
      <span>{text}</span>
      <span className={styles.count}>{count}</span>
    </div>;
};
export { SearchResultTitle };