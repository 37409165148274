import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import { payoutRequestConfirmationCode, payoutVerificationSuccessKey, useMutate } from '@mwl/core-lib';
import { Loader } from '@/components/Loader/Loader';
import { Message } from '@/components/Message/Message';
import type { PopupClasses } from '@/components/Popup/Popup';
import { Popup } from '@/components/Popup/Popup';
import { MessageConfirmCancelling } from '@/modules/payment/components/MessageConfirmCancelling/MessageConfirmCancelling';
import { FormConfirmation } from '../FormConfirmation/FormConfirmation';
import { FormTimerError } from '../FormTimerError/FormTimerError';
import { initialState, reducer } from './ConfirmationPopup.state';
import type { ConfirmationPopupProps } from './ConfirmationPopup.types';
import { createFields } from './ConfirmationPopup.utils';
import styles from './ConfirmationPopup.module.scss';
const popupClasses: PopupClasses = {
  wrapper: styles.wrapper
};
export const ConfirmationPopup: FC<ConfirmationPopupProps> = ({
  show,
  onClose,
  confirmation
}) => {
  const {
    t
  } = useTranslation('payment');
  const [state, dispatch] = useReducer(reducer, initialState);
  const prevIsShow = useRef(false);
  const handleClose = useCallback(() => onClose(state.isSuccess || state.form === 'SUCCESS_VERIFICATION'), [state.form, state.isSuccess, onClose]);
  const handleCompleteTimer = useCallback(() => dispatch({
    type: 'CHANGE_TIMER',
    payload: null
  }), []);
  const handleChangeTimer = useCallback((timer?: Nullable<number>) => dispatch({
    type: 'CHANGE_TIMER',
    payload: timer
  }), []);
  const handleLimitError = useCallback((timer?: Nullable<number>) => dispatch({
    type: 'CHANGE_FORM',
    payload: {
      form: 'TIMER_ERROR',
      timer
    }
  }), []);
  const handleCancelRequest = useCallback(() => dispatch({
    type: 'CHANGE_FORM',
    payload: {
      form: 'CONFIRM_CANCELLING'
    }
  }), []);
  const handleSuccessCancelling = useCallback(() => dispatch({
    type: 'SUCCESS_CANCELLING'
  }), []);
  const handleCompleteTimerError = useCallback(() => {
    dispatch({
      type: 'CHANGE_FORM',
      payload: {
        form: 'VERIFICATION',
        timer: null
      }
    });
  }, []);
  const handleSuccess = useCallback(() => {
    dispatch({
      type: 'CHANGE_FORM',
      payload: {
        form: 'SUCCESS_VERIFICATION'
      }
    });
  }, []);
  const {
    state: {
      isLoading
    },
    onMutate: onRequestConfirmationCode
  } = useMutate({
    requestHandler: payoutRequestConfirmationCode,
    onSuccess: data => {
      if (data) {
        if (data.result === 'SUCCESS') {
          dispatch({
            type: 'CHANGE_FORM',
            payload: {
              form: 'VERIFICATION',
              timer: data.timer
            }
          });
        } else {
          dispatch({
            type: 'CHANGE_FORM',
            payload: {
              form: 'TIMER_ERROR',
              timer: data.timer
            }
          });
        }
      }
    }
  });
  const requestCode = useCallback(() => {
    if (confirmation?.id) {
      onRequestConfirmationCode({
        payoutId: confirmation.id
      });
    }
  }, [confirmation?.id, onRequestConfirmationCode]);
  useEffect(() => {
    if (show && prevIsShow.current !== show) {
      requestCode();
    }
    prevIsShow.current = !!show;
  }, [show, requestCode]);
  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (!show) {
      timeout = setTimeout(() => dispatch({
        type: 'RESET_STATE'
      }), 300);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [show]);
  const fields = useMemo(() => confirmation ? createFields(confirmation, t) : [], [confirmation, t]);
  const isHideContent = state.form === 'SUCCESS_VERIFICATION' || state.form === 'CONFIRM_CANCELLING';
  return <Popup show={show} onClose={onClose} classes={popupClasses} data-sentry-element="Popup" data-sentry-component="ConfirmationPopup" data-sentry-source-file="ConfirmationPopup.tsx">
      <div className={cn(styles.content, {
      [styles.hidden]: isHideContent
    })}>
        <h5 className={styles.title}>{t('need_confirmation.title', 'Withdrawals')}</h5>

        {!!fields.length && <div className={styles.fields}>
            {fields.map(({
          key,
          value
        }, idx) => <div className={styles.field} key={`${key}_${idx}`}>
                <p className={styles.fieldTitle}>{key}</p>:&nbsp;
                <p className={styles.fieldValue}>{value}</p>
              </div>)}
          </div>}
        {isLoading && <Loader />}
        {state.form === 'VERIFICATION' && <FormConfirmation timer={state.timer} confirmation={confirmation} successHandler={handleSuccess} onComplete={handleCompleteTimer} onLimitError={handleLimitError} onChangeTimer={handleChangeTimer} />}
        {state.form === 'TIMER_ERROR' && <FormTimerError timer={state.timer} onComplete={handleCompleteTimerError} onCancel={handleCancelRequest} />}
      </div>

      {state.form === 'CONFIRM_CANCELLING' && <MessageConfirmCancelling payoutId={confirmation?.id} onClose={handleClose} onCancel={requestCode} onSuccess={handleSuccessCancelling} />}

      {state.form === 'SUCCESS_VERIFICATION' && <Message status="success" onClick={handleClose} title={t(payoutVerificationSuccessKey)} />}
    </Popup>;
};