import type { SwiperAndGridBreakpoints } from '@mwl/core-lib';

export const casinoCardsBreakpoints: SwiperAndGridBreakpoints = {
  0: {
    repeatCardInGrid: 2,
    gridCardMinWidth: '160px',
    gridCardMaxWidth: '230px',
    swiperCardMinWidth: '160px',
    swiperCardMaxWidth: '160px',
    slidesPerView: 2,
    spaceBetween: 8,
  },
  360: {
    repeatCardInGrid: 'auto-fill',
    gridCardMinWidth: '160px',
    gridCardMaxWidth: '1fr',
    swiperCardMinWidth: '160px',
    swiperCardMaxWidth: '160px',
    slidesPerView: 'auto',
    spaceBetween: 8,
  },
  768: {
    repeatCardInGrid: 4,
    gridCardMinWidth: '160px',
    gridCardMaxWidth: '1fr',
    slidesPerView: 4,
    spaceBetween: 8,
  },
  849: {
    repeatCardInGrid: 3,
    gridCardMinWidth: '160px',
    gridCardMaxWidth: '1fr',
    slidesPerView: 3,
    spaceBetween: 8,
  },
  1024: {
    repeatCardInGrid: 4,
    gridCardMinWidth: '160px',
    gridCardMaxWidth: '1fr',
    slidesPerView: 4,
    spaceBetween: 8,
  },
  1154: {
    repeatCardInGrid: 5,
    gridCardMinWidth: '160px',
    gridCardMaxWidth: '1fr',
    slidesPerView: 5,
    spaceBetween: 8,
  },
  1384: {
    repeatCardInGrid: 6,
    gridCardMinWidth: '160px',
    gridCardMaxWidth: '1fr',
    slidesPerView: 6,
    spaceBetween: 8,
  },
  1550: {
    repeatCardInGrid: 7,
    gridCardMinWidth: '160px',
    gridCardMaxWidth: '1fr',
    slidesPerView: 7,
    spaceBetween: 8,
  },
  1920: {
    repeatCardInGrid: 8,
    gridCardMinWidth: '160px',
    gridCardMaxWidth: '1fr',
    slidesPerView: 8,
    spaceBetween: 8,
  },
  2560: {
    repeatCardInGrid: 10,
    gridCardMinWidth: '160px',
    gridCardMaxWidth: '1fr',
    slidesPerView: 10,
    spaceBetween: 8,
  },
};

export const mainCardsBreakpoints: SwiperAndGridBreakpoints = {
  0: {
    repeatCardInGrid: 2,
    gridCardMinWidth: '160px',
    gridCardMaxWidth: '230px',
    swiperCardMinWidth: '160px',
    swiperCardMaxWidth: '160px',
    slidesPerView: 2,
    spaceBetween: 8,
  },
  360: {
    repeatCardInGrid: 'auto-fill',
    gridCardMinWidth: '160px',
    gridCardMaxWidth: '1fr',
    swiperCardMinWidth: '160px',
    swiperCardMaxWidth: '160px',
    slidesPerView: 'auto',
    spaceBetween: 8,
  },
  768: {
    repeatCardInGrid: 4,
    gridCardMinWidth: '160px',
    gridCardMaxWidth: '1fr',
    slidesPerView: 4,
    spaceBetween: 8,
  },
  1024: {
    repeatCardInGrid: 6,
    gridCardMinWidth: '160px',
    gridCardMaxWidth: '1fr',
    slidesPerView: 6,
    spaceBetween: 8,
  },
  1550: {
    repeatCardInGrid: 8,
    gridCardMinWidth: '160px',
    gridCardMaxWidth: '1fr',
    slidesPerView: 8,
    spaceBetween: 8,
  },
  1920: {
    repeatCardInGrid: 8,
    gridCardMinWidth: '160px',
    gridCardMaxWidth: '1fr',
    slidesPerView: 8,
    spaceBetween: 8,
  },
  2560: {
    repeatCardInGrid: 10,
    gridCardMinWidth: '160px',
    gridCardMaxWidth: '1fr',
    slidesPerView: 10,
    spaceBetween: 8,
  },
};

export const providersBreakpoints: SwiperAndGridBreakpoints = {
  0: {
    repeatCardInGrid: 2,
    gridCardMinWidth: '156px',
    gridCardMaxWidth: '1fr',
    swiperCardMinWidth: '156px',
    swiperCardMaxWidth: '156px',
    slidesPerView: 1,
    spaceBetween: 8,
  },
  360: {
    repeatCardInGrid: 'auto-fill',
    gridCardMinWidth: '156px',
    gridCardMaxWidth: '1fr',
    swiperCardMinWidth: '156px',
    swiperCardMaxWidth: '156px',
    slidesPerView: 'auto',
  },
  768: {
    repeatCardInGrid: 2,
    gridCardMinWidth: '240px',
    gridCardMaxWidth: '1fr',
    slidesPerView: 2,
  },
  1024: {
    repeatCardInGrid: 3,
    gridCardMinWidth: '240px',
    gridCardMaxWidth: '1fr',
    swiperCardMinWidth: 'auto',
    swiperCardMaxWidth: 'auto',
    slidesPerView: 3,
  },
  1550: {
    repeatCardInGrid: 4,
    gridCardMinWidth: '306px',
    gridCardMaxWidth: '1fr',
    slidesPerView: 4,
  },
};

export const promotionsBreakpoints: SwiperAndGridBreakpoints = {
  0: {
    swiperCardMinWidth: '156px',
    swiperCardMaxWidth: '156px',
    spaceBetween: 8,
    slidesPerView: 1,
  },
  360: {
    swiperCardMinWidth: '156px',
    swiperCardMaxWidth: 'auto',
    slidesPerView: 'auto',
    spaceBetween: 8,
  },
  768: {
    swiperCardMinWidth: '240px',
    swiperCardMaxWidth: 'auto',
    slidesPerView: 'auto',
    spaceBetween: 8,
  },
  1024: {
    swiperCardMinWidth: '310px',
    swiperCardMaxWidth: 'auto',
    slidesPerView: 'auto',
    spaceBetween: 8,
  },
};

export const tournamentsBreakpoints: SwiperAndGridBreakpoints = {
  0: {
    repeatCardInGrid: 2,
    gridCardMinWidth: '156px',
    gridCardMaxWidth: '1fr',
    swiperCardMinWidth: '156px',
    swiperCardMaxWidth: '156px',
    spaceBetween: 8,
    slidesPerView: 1,
  },
  360: {
    repeatCardInGrid: 2,
    gridCardMinWidth: '156px',
    gridCardMaxWidth: '1fr',
    swiperCardMinWidth: '156px',
    swiperCardMaxWidth: 'auto',
    slidesPerView: 'auto',
    spaceBetween: 8,
  },
  768: {
    repeatCardInGrid: 3,
    gridCardMinWidth: '240px',
    gridCardMaxWidth: '1fr',
    slidesPerView: 'auto',
    spaceBetween: 8,
  },
  1024: {
    repeatCardInGrid: 'auto-fill',
    gridCardMinWidth: '300px',
    gridCardMaxWidth: '1fr',
    swiperCardMinWidth: '300px',
    swiperCardMaxWidth: 'auto',
    slidesPerView: 'auto',
    spaceBetween: 8,
  },
};

export const sportPinnedBreakpoints: SwiperAndGridBreakpoints = {
  0: {
    swiperCardMinWidth: '304px',
    swiperCardMaxWidth: '304px',
    gridCardMinWidth: '304px',
    gridCardMaxWidth: '304px',
    repeatCardInGrid: 'auto-fill',
    slidesPerView: 'auto',
  },
  768: {
    swiperCardMinWidth: '376px',
    swiperCardMaxWidth: '376px',
    gridCardMinWidth: '376px',
    gridCardMaxWidth: '376px',
    repeatCardInGrid: 'auto-fill',
    slidesPerView: 'auto',
  },
};
