import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { Button } from '@/components/Button/Button';
import { Message } from '@/components/Message/Message';
import { ModalMessage } from '@/components/ModalMessage/ModalMessage';
import type { PopupClasses } from '@/components/Popup/Popup.types';
import type { RefuseBonusModalProps } from './RefuseBonusModal.types';
import styles from './RefuseBonusModal.module.scss';
const RefuseBonusModal = ({
  show,
  onOk,
  onClose,
  bonusType
}: RefuseBonusModalProps) => {
  const {
    t
  } = useTranslation('loyalty');
  const popupClasses: PopupClasses = {
    wrapper: styles.wrapper
  };
  const title = useMemo(() => {
    if (bonusType === 'freespins') {
      return t('refuse_bonus.text_freespin', 'Refuse the freespin irrevocably?');
    }
    return t('refuse_bonus.text', 'Refuse the bonus irrevocably?');
  }, [bonusType, t]);
  return <ModalMessage popupClasses={popupClasses} show={show} onClose={onClose} data-sentry-element="ModalMessage" data-sentry-component="RefuseBonusModal" data-sentry-source-file="RefuseBonusModal.tsx">
      <Message title={title} withIcon={false} customAction={<div className={styles.buttons}>
            <Button as="button" onClick={onClose} fontSize="lg" fontWeight="bold" size="md" fullWidth align="center" color="white" rounded>
              {t('refuse_bonus.cancel', 'Cancel')}
            </Button>
            <Button as="button" onClick={onOk} fontSize="lg" fontWeight="bold" size="md" fullWidth align="center" color="red" rounded>
              {t('refuse_bonus.submit', 'refuse')}
            </Button>
          </div>} data-sentry-element="Message" data-sentry-source-file="RefuseBonusModal.tsx" />
    </ModalMessage>;
};
export { RefuseBonusModal };