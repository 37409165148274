import type { FC } from 'react';
import { formatImageUrl, useHover } from '@mwl/core-lib';
import { useMediaWidthDown } from '@/hooks';
import { Button } from '../Button/Button';
import { Image } from '../Image/Image';
import type { CasinoBannerProps } from './CasinoBanner.types';
import styles from './CasinoBanner.module.scss';
const CasinoBanner: FC<CasinoBannerProps> = ({
  image,
  fullBackgroundImage,
  url,
  button,
  title,
  content,
  width = 'full',
  className
}) => {
  const {
    hoverRef,
    hovered
  } = useHover();
  const isSmallScreen = useMediaWidthDown('screenSm');
  const backgroundImage = typeof fullBackgroundImage === 'string' ? `${formatImageUrl(fullBackgroundImage, 450)}` : '';
  const bannerImage = image ? `${formatImageUrl(image, 180)}` : '';
  return <div className={cn(styles.root, className, styles[width], {
    [styles.hasBackground]: !!backgroundImage
  })} data-sentry-component="CasinoBanner" data-sentry-source-file="CasinoBanner.tsx">
      {backgroundImage && <div className={styles.background} ref={hoverRef}>
          <Image src={backgroundImage} width={450} alt={title || 'Casino Banner'} fill className={styles.backgroundImage} />
        </div>}

      {bannerImage && <div className={styles.bannerImage}>
          <Image src={image} fill alt={title || 'Banner image'} />
        </div>}

      {title && <div className={styles.body}>
          <div className={styles.title}>{title}</div>
          <div className={styles.content}>{content}</div>
        </div>}

      {button && <div className={styles.actions}>
          <Button as="link" href={url || ''} fontWeight="bold" fontSize="lg" color={hovered && !isSmallScreen ? 'white' : 'red'} variant="fulfilled" cut={false} rounded size="lg" className={styles.button}>
            {button}
          </Button>
        </div>}
    </div>;
};
export { CasinoBanner };