import type { FC } from 'react';
import { memo, useMemo } from 'react';
import { selectUserBonuses, selectUserName, selectUserNickname, selectUserProfile, useLoyaltyLevels, useLoyaltyProfile } from '@mwl/core-lib';
import ArrowIcon from '@public/assets/common/icons/arrowRightOutlined.svg';
import { Button } from '@/components/Button/Button';
import { routes } from '@/constants';
import { useTypedDispatch, useTypedSelector } from '@/hooks';
import { toggleSidebar } from '@/store/features/ui/actions';
import { Cashback } from '../Cashback/Cashback';
import { UserRank } from '../UserRank/UserRank';
import styles from './UserInfo.module.scss';
const BaseUserInfo: FC = () => {
  const dispatch = useTypedDispatch();
  const {
    loyaltyProfile
  } = useLoyaltyProfile();
  const levels = useLoyaltyLevels();
  const profile = useTypedSelector(selectUserProfile);
  const bonuses = useTypedSelector(selectUserBonuses);
  const name = useTypedSelector(selectUserName);
  const nickname = useTypedSelector(selectUserNickname);
  const cashbackBonus = useMemo(() => bonuses.find(bonus => bonus.type === 'CASHBACK'), [bonuses]);
  const handleClick = () => dispatch(toggleSidebar(false));
  if (!loyaltyProfile) {
    return null;
  }
  const {
    cashbackPercent
  } = loyaltyProfile.level.settings;
  const userName = profile.firstName ? name : nickname;
  const maxExperience = levels?.find(level => level.id === loyaltyProfile.level.id)?.experience;
  return <div className={styles.root} data-sentry-component="BaseUserInfo" data-sentry-source-file="UserInfo.tsx">
      <div className={styles.header}>
        <div className={styles.userNameContainer}>
          <Button as="link" variant="text" className={styles.userName} href={routes.profile.home} onClick={handleClick} data-sentry-element="Button" data-sentry-source-file="UserInfo.tsx">
            {userName}
          </Button>
          <ArrowIcon className={styles.icon} data-sentry-element="ArrowIcon" data-sentry-source-file="UserInfo.tsx" />
        </div>
        {profile.accountNumber && <span className={styles.userId}>ID: {profile.accountNumber}</span>}
      </div>
      {loyaltyProfile && <UserRank rank={loyaltyProfile.level.rank} progress={loyaltyProfile.progress} level={loyaltyProfile.level.level} maxExperience={maxExperience} />}
      <Cashback cashbackPercent={cashbackPercent} cashbackBonus={cashbackBonus} data-sentry-element="Cashback" data-sentry-source-file="UserInfo.tsx" />
    </div>;
};
export const UserInfo = memo(BaseUserInfo);