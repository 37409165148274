import type { FC } from 'react';
import { useLoadBlockGames } from '@mwl/core-lib';
import { GameCardsSlider } from '../GameCardsSlider/GameCardsSlider';
import type { SliderCardsLoadableProps } from './SliderCardsLoadable.types';
const SliderCardsLoadable: FC<SliderCardsLoadableProps> = ({
  loadMethod,
  onLoadFinished,
  ...props
}) => {
  const {
    items,
    totalElements
  } = useLoadBlockGames({
    loadMethod,
    onLoadFinished
  });
  return <GameCardsSlider games={items} totalElements={totalElements} {...props} data-sentry-element="GameCardsSlider" data-sentry-component="SliderCardsLoadable" data-sentry-source-file="SliderCardsLoadable.tsx" />;
};
export { SliderCardsLoadable };