import type { FC } from 'react';
import { getTestProps } from '@mwl/core-lib';
import { SportIcon } from '../SportIcon/SportIcon';
import type { MatchTeamProps } from './MatchTeam.types';
import styles from './MatchTeam.module.scss';
export const MatchTeam: FC<MatchTeamProps> = ({
  classes,
  sportCode,
  sportIcon,
  className,
  direction = 'column',
  image,
  title,
  ...props
}) => {
  const dataCyProps = getTestProps(props);
  return <div className={cn(styles.root, styles[direction], className)} {...dataCyProps} data-sentry-component="MatchTeam" data-sentry-source-file="MatchTeam.tsx">
      <div className={cn(styles.image, classes?.image)}>
        <SportIcon className={styles.unknownLogo} url={image} fallbackUrl={sportIcon} alt={title || sportCode} size={20} data-sentry-element="SportIcon" data-sentry-source-file="MatchTeam.tsx" />
      </div>
      <div className={cn(styles.nameContainer, classes?.nameContainer)}>
        <span className={cn(styles.name, classes?.name)} title={title}>
          {title}
        </span>
      </div>
    </div>;
};
export * from './MatchTeam.types';