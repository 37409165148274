import { type FC, useMemo } from 'react';
import { getTestProps } from '@mwl/core-lib';
import { CasinoFilterListItem } from '../CasinoFilterListItem/CasinoFilterListItem';
import type { CasinoFilterListProps } from './CasinoFilterList.types';
import styles from './CasinoFilterList.module.scss';
const CasinoFilterList: FC<CasinoFilterListProps> = ({
  items,
  type,
  ...props
}) => {
  const selectedItems = useMemo(() => items.filter(item => item.selected), [items]);
  return <div className={styles.root} data-sentry-component="CasinoFilterList" data-sentry-source-file="CasinoFilterList.tsx">
      {items.map((item, index) => <CasinoFilterListItem {...getTestProps(props)} key={item.id} index={index} type={type} item={item} selectedItems={selectedItems} totalCount={items.length} />)}
    </div>;
};
export { CasinoFilterList };