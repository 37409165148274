import type { FC, PropsWithChildren } from 'react';
import { createContext, useContext, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
interface RouterHistoryContextState {
  current: string;
  previous: string;
}
type RouterHistoryContextProps = RouterHistoryContextState;
const Context = createContext<RouterHistoryContextProps>(({} as RouterHistoryContextProps));
export const useRouterHistory = (): RouterHistoryContextProps => useContext(Context);
const initialState: RouterHistoryContextState = {
  current: '',
  previous: ''
};
const useProvideRouterHistory = (): RouterHistoryContextProps => {
  const {
    asPath
  } = useRouter();
  const ref = useRef<RouterHistoryContextState>(initialState);
  useEffect(() => {
    ref.current.previous = ref.current.current;
    ref.current.current = asPath;
  }, [asPath]);
  return ref.current;
};
export const RouterHistoryProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const value = useProvideRouterHistory();
  return <Context.Provider value={value} data-sentry-element="unknown" data-sentry-component="RouterHistoryProvider" data-sentry-source-file="routerHistory.tsx">{children}</Context.Provider>;
};