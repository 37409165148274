import type { FC } from 'react';
import { memo, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { selectBonusPackets, useQueryPopupOpen } from '@mwl/core-lib';
import imgLeft from '@public/assets/sidebar/images/regBonusLeft.webp';
import imgRight from '@public/assets/sidebar/images/regBonusRight.webp';
import { Button } from '@/components/Button/Button';
import { Image } from '@/components/Image/Image';
import { useTypedSelector } from '@/hooks';
import styles from './RegisterBanner.module.scss';
const BaseRegisterBanner: FC = () => {
  const {
    t
  } = useTranslation('notification');
  const {
    createLinkProps
  } = useQueryPopupOpen();
  const registerLinkProps = useMemo(() => createLinkProps({
    queryName: 'register'
  }), [createLinkProps]);
  const bonuses = useTypedSelector(selectBonusPackets);
  const bonus = bonuses.find(({
    registrationBonusType
  }) => registrationBonusType === 'CASINO');
  return <div className={styles.root} data-sentry-component="BaseRegisterBanner" data-sentry-source-file="RegisterBanner.tsx">
      <div className={styles.backImages}>
        <div className={styles.imgContainer}>
          <Image src={imgLeft} priority fill style={{
          objectFit: 'contain',
          objectPosition: 'left'
        }} alt={bonus?.bonusTitle || 'Bonus'} data-sentry-element="Image" data-sentry-source-file="RegisterBanner.tsx" />
        </div>
        <div className={styles.imgContainer}>
          <Image src={imgRight} priority fill style={{
          objectFit: 'contain',
          objectPosition: 'right'
        }} alt={bonus?.bonusTitle || 'Bonus'} data-sentry-element="Image" data-sentry-source-file="RegisterBanner.tsx" />
        </div>
      </div>
      <div className={styles.container}>
        <span className={styles.bonus}>{bonus?.bonusTitle}</span>
        <span className={styles.deposit}>
          {t('sidebar_register_bonus_for_the_first_deposit', 'For the first deposit')}
        </span>
        <Button as="link" variant="fulfilled" size="md" fontSize="xl" fontWeight="bold" fullWidth rounded {...registerLinkProps} data-sentry-element="Button" data-sentry-source-file="RegisterBanner.tsx">
          {t('sidebar_register_bonus_take_now', 'take now')}
        </Button>
      </div>
    </div>;
};
export const RegisterBanner = memo(BaseRegisterBanner);