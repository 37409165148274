import type { FC } from 'react';
import { memo, useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import type { FullRegistrationStep } from '@mwl/core-lib';
import { selectSettingsLoading, selectSettingsSystem } from '@mwl/core-lib';
import { FormRegistration } from '@/components/FormRegistration/FormRegistration';
import type { FormRegistrationFullOnChangeStep } from '@/components/FormRegistrationFull/FormRegistrationFull';
import { FormRegistrationFull } from '@/components/FormRegistrationFull/FormRegistrationFull';
import { Loader } from '@/components/Loader/Loader';
import { fullRegistrationSteps } from '@/constants';
import { RegisterContext, useProvideRegister } from '@/context';
import { fullRegistrationIsAllowed, registerObject } from '@/utils';
import { AuthContainerHeader } from '../../../AuthContainerHeader/AuthContainerHeader';
import { RegisterContainerFooter } from '../RegisterContainerFooter/RegisterContainerFooter';
import type { RegisterContainerProps } from './BaseRegisterContainer.types';
import styles from './RegisterContainer.module.scss';
const BaseRegisterContainer: FC<RegisterContainerProps> = ({
  changeBonusType,
  ...props
}) => {
  const {
    t
  } = useTranslation('common');
  const {
    bonus: bonusAnalytics,
    ...analytics
  } = props.analytics || {};
  const registrationSettings = useSelector(selectSettingsSystem);
  const isLoading = useSelector(selectSettingsLoading);
  const [step, setStep] = useState<FullRegistrationStep>('second');
  const formRef = useRef<HTMLDivElement>(null);
  const value = useProvideRegister();
  const handleChangeStep: FormRegistrationFullOnChangeStep = useCallback(currentStep => setStep(currentStep), []);
  return <RegisterContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="BaseRegisterContainer" data-sentry-source-file="RegisterContainer.tsx">
      {isLoading && <div className={styles.form}>
          <div className={styles.loader}>
            <Loader />
          </div>
        </div>}
      {!isLoading && registrationSettings && <div ref={formRef}>
          <AuthContainerHeader variant="register" />
          {registrationSettings?.type === 'FULL' && fullRegistrationIsAllowed() ? <div className={styles.form}>
              {(step === 'second' || step === 'third') && <>
                  <h1 className={styles.stepTitle}>
                    {t('auth_banner.step', 'Step')}:{' '}
                    <span className={styles.title}>{fullRegistrationSteps[step] - 1}</span> /2
                  </h1>
                  <p className={styles.stepDescription}>
                    {step === 'second' ? t('auth_banner.main_information', 'Main Information') : t('auth_banner.confirmation', 'The Confirmation')}
                  </p>
                </>}
              <FormRegistrationFull bonusType={value.selectedBonus.type} variant="banner" onChangeStep={handleChangeStep} step={step} isModal />
            </div> : <FormRegistration className={styles.form} parentRef={formRef} registerTestSchema={registerObject} analytics={analytics} {...props} />}
          <RegisterContainerFooter onChange={changeBonusType} analytics={bonusAnalytics} />
        </div>}
    </RegisterContext.Provider>;
};
const RegisterContainer = memo(BaseRegisterContainer);
export { RegisterContainer };