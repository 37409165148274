import { type FC } from 'react';
import { useRouter } from 'next/router';
import { sendAnalyticsData, useSwipe } from '@mwl/core-lib';
import { casinoNavMenu, liveCasinoNavMenu, routes, sidebarTopSectionNavMenuCasino, sidebarTopSectionNavMenuLiveCasino } from '@/constants';
import { useRenderMenuItems } from '@/hooks';
import { useMenuItems } from '../../hooks/useMenuItems';
import { MenuItem } from '../MenuItem/MenuItem';
import type { NavigationProps } from './Navigation.types';
import styles from './Navigation.module.scss';
const scrollableClassName = styles.scrollable;
export const Navigation: FC<NavigationProps> = ({
  className
}) => {
  const router = useRouter();
  const menuNavItems = router.asPath.includes(routes.liveCasino.home) ? liveCasinoNavMenu : casinoNavMenu;
  const menuItemsTop = router.asPath.includes(routes.liveCasino.home) ? sidebarTopSectionNavMenuLiveCasino : sidebarTopSectionNavMenuCasino;
  const filteredTopItems = useRenderMenuItems({
    items: menuItemsTop
  });
  const filteredNavItems = useRenderMenuItems({
    items: menuNavItems
  });
  const {
    topItems,
    navItems
  } = useMenuItems({
    topItems: filteredTopItems,
    navItems: filteredNavItems
  });
  const navMenu = [...topItems, ...navItems];
  const handleMenuSwipe = (position: 'left' | 'right') => {
    sendAnalyticsData('casinoMenu_swipe', {
      position,
      count: navMenu.length,
      cls: scrollableClassName
    });
  };
  const {
    ref: scrollableRef
  } = useSwipe({
    onSwipe: handleMenuSwipe
  });
  return <div className={styles.wrapper} data-sentry-component="Navigation" data-sentry-source-file="Navigation.tsx">
      <div className={cn(styles.root, className)}>
        <div className={styles.container}>
          <div ref={scrollableRef} className={scrollableClassName}>
            {navMenu?.sort(menuItem => menuItem.isActive ? -1 : 1).map(menuItem => <MenuItem {...menuItem} id={menuItem.key} key={menuItem.key} scrollableRef={scrollableRef} />)}
          </div>
        </div>
      </div>
    </div>;
};