import type { FC } from 'react';
import { useTranslation } from 'next-i18next';
import { numberFormatter, selectUserCurrencySymbol, selectUserIsAuthenticated } from '@mwl/core-lib';
import { useTypedSelector } from '@/hooks';
import type { BonusPacketCardProps } from '../../BonusPacketCard.types';
import styles from './BonusPacketCardTitle.module.scss';
export const BonusPacketCardTitle: FC<Pick<BonusPacketCardProps, 'title' | 'minimalDepositAmount' | 'depositNumber' | 'available' | 'size'>> = ({
  title,
  minimalDepositAmount,
  depositNumber,
  available,
  size
}) => {
  const {
    t
  } = useTranslation('common');
  const currencySymbol = useTypedSelector(selectUserCurrencySymbol);
  const isAuth = useTypedSelector(selectUserIsAuthenticated);
  return <div className={cn(styles.root, styles[`${size}Size`])} data-sentry-component="BonusPacketCardTitle" data-sentry-source-file="BonusPacketCardTitle.tsx">
      <h3 className={styles.title}>{title}</h3>

      <p className={styles.description}>
        {t('bonus_packet.front_title.default', 'When replenishing from {{amount}}', {
        amount: `${numberFormatter(minimalDepositAmount)} ${currencySymbol.toUpperCase()}`
      })}
      </p>

      {!available && depositNumber && isAuth && <p className={styles.disableMessage}>
          {t('bonus_packet.deposit_number_inactive', 'Available after the {{count}}nd deposit', {
        count: depositNumber - 1,
        ordinal: true
      })}
        </p>}
    </div>;
};