import type { FC, MouseEventHandler } from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { selectUserIsAuthenticated, sendAnalyticsData, useRootRef, useToggle } from '@mwl/core-lib';
import Tippy from '@tippyjs/react';
import QuestionIcon from '@public/assets/common/icons/questionCircle.svg';
import { defaultTippyProps } from '@/constants';
import { useTypedSelector } from '@/hooks';
import { ButtonIcon } from '../ButtonIcon/ButtonIcon';
import { Image } from '../Image/Image';
import { BonusPacketCardButton } from './components/BonusPacketCardButton/BonusPacketCardButton';
import { BonusPacketCardInfo } from './components/BonusPacketCardInfo/BonusPacketCardInfo';
import { BonusPacketCardTitle } from './components/BonusPacketCardTitle/BonusPacketCardTitle';
import { useBonusPacketTippyProps } from './BonusPacketCard.hooks';
import type { BonusPacketCardProps } from './BonusPacketCard.types';
import { getBonusClassNames } from './BonusPacketCard.utils';
import 'tippy.js/animations/scale-extreme.css';
import styles from './BonusPacketCard.module.scss';
import variables from '@/styles/helpers/export.module.scss';
const maxDepositNumber = 5;
const BonusPacketCard: FC<BonusPacketCardProps> = ({
  className,
  classes,
  containerRef,
  available,
  depositNumber,
  description,
  packetDetails,
  fullImage,
  isActive,
  locked,
  minimalDepositAmount,
  theme,
  title,
  size = 'large',
  withButton,
  onSelect,
  analytics
}) => {
  const {
    t
  } = useTranslation('common');
  const [isOpened, handleToggle] = useToggle(false);
  const {
    rootRef,
    offset
  } = useRootRef();
  const {
    getReferenceClientRect
  } = useBonusPacketTippyProps(containerRef, rootRef);
  const isAuth = useTypedSelector(selectUserIsAuthenticated);
  const bonusClassNames = useMemo(() => getBonusClassNames({
    className,
    available,
    size,
    isActive,
    theme,
    locked,
    onSelect,
    isAuth
  }), [className, available, size, isActive, theme, locked, onSelect, isAuth]);
  const handleClick = () => handleToggle();
  const handleClose = useCallback(() => handleToggle(false), [handleToggle]);
  const handleSelect: MouseEventHandler<HTMLDivElement> = useCallback(({
    target
  }) => {
    if (!(target as Element).closest('[data-bonus-button-info]') && onSelect) {
      onSelect();
      sendAnalyticsData(analytics?.card?.click?.eventName, {
        ...(analytics?.card?.click?.data || {}),
        cls: bonusClassNames
      });
    }
  }, [analytics, bonusClassNames, onSelect]);
  const depositNumberContent = useMemo(() => {
    if (!depositNumber) {
      return null;
    }
    return depositNumber < maxDepositNumber ? t('bonus_packet.deposit_number', '{{count}}nd deposit bonus', {
      count: depositNumber,
      ordinal: true
    }) : t('bonus_packet.deposit_number_more', '{{amount}} deposit bonus', {
      amount: `${5}+`
    });
  }, [t, depositNumber]);
  const content = useMemo(() => {
    return <BonusPacketCardInfo className={classes?.container} classes={{
      ...classes
    }} withButton={withButton} available={available} title={title} description={description} packetDetails={packetDetails} parentRef={rootRef} onClose={handleClose} />;
  }, [classes, withButton, available, title, description, packetDetails, rootRef, handleClose]);
  return <Tippy {...defaultTippyProps} zIndex={Number(variables.paymentPopupTippyZIndex)} offset={offset} getReferenceClientRect={getReferenceClientRect} className={styles.tooltip} visible={isOpened} content={content} onClickOutside={handleClose} data-sentry-element="Tippy" data-sentry-component="BonusPacketCard" data-sentry-source-file="BonusPacketCard.tsx">
      <>
        <div className={bonusClassNames} aria-hidden="true" onClick={handleSelect} ref={rootRef}>
          <div className={styles.content}>
            <ButtonIcon as="button" type="button" variant="outlined" className={styles.info} rounded data-bonus-button-info onClick={handleClick} aria-label="bonus packet card info" analytics={analytics?.info} data-sentry-element="ButtonIcon" data-sentry-source-file="BonusPacketCard.tsx">
              <QuestionIcon className={styles.infoIcon} data-sentry-element="QuestionIcon" data-sentry-source-file="BonusPacketCard.tsx" />
            </ButtonIcon>

            {fullImage && <div className={cn(styles.image, classes?.image)}>
                <Image src={fullImage} width={278} fill alt={title} />
              </div>}

            {isActive && <div className={styles.activeMessage}>{t('bonus_packet.selected_text', 'Selected bonus')}</div>}

            <BonusPacketCardTitle minimalDepositAmount={minimalDepositAmount} title={title} depositNumber={depositNumber} available={available} size={size} data-sentry-element="BonusPacketCardTitle" data-sentry-source-file="BonusPacketCard.tsx" />

            <div className={styles.footer}>
              {withButton && <BonusPacketCardButton available={available} className={styles.button} analytics={analytics?.button} />}
              {depositNumberContent && <p className={styles.depositNumber}>{depositNumberContent}</p>}
            </div>
          </div>
        </div>
        {locked && <>
            <div className={styles.lockedBg} />
            <div className={styles.lockedBorder} />
          </>}
      </>
    </Tippy>;
};
export { BonusPacketCard };