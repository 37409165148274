import type { FC } from 'react';
import { CasinoBanner } from '@/components/CasinoBanner/CasinoBanner';
import { type SliderClasses, Slider } from '@/components/Slider/Slider';
import { useSliderBanners } from '@/hooks';
import { sliderProps } from './CasinoBanners.data';
import type { CasinoBannersProps } from './CasinoBanners.types';
import styles from './CasinoBanners.module.scss';
const sliderClasses: SliderClasses = {
  wrapper: styles.sliderWrapper
};
export const CasinoBanners: FC<CasinoBannersProps> = ({
  position,
  className
}) => {
  const {
    banners
  } = useSliderBanners(position, {
    isCheckAuth: false
  });
  if (!banners.length) {
    return null;
  }
  return <div className={cn(styles.root, className)} data-sentry-component="CasinoBanners" data-sentry-source-file="CasinoBanners.tsx">
      <Slider classes={sliderClasses} {...sliderProps} data-sentry-element="Slider" data-sentry-source-file="CasinoBanners.tsx">
        {banners.map(banner => <CasinoBanner className={styles.banner} {...banner} key={banner.id} />)}
      </Slider>
    </div>;
};