import type { FC } from 'react';
import * as process from 'process';
import { mobileNavItems, mobileNavItemsWithSport } from '@/constants';
import { useRenderMenuItems } from '@/hooks';
import { MenuItem } from './components/MenuItem/MenuItem';
import type { MenuItemClasses } from './components/MenuItem/MenuItem.types';
import { MobileMenuButton } from './components/MobileMenuButton/MobileMenuButton';
import { useMobileMenuButton } from './MobileMenu.hooks';
import type { MobileMenuProps } from './MobileMenu.types';
import styles from './MobileMenu.module.scss';
const menuItemClasses: MenuItemClasses = {
  item: styles.item,
  activeItem: styles.activeItem
};
export const MobileMenu: FC<MobileMenuProps> = ({
  className,
  isSportPage = false
}) => {
  const isSportEnabled = process.env.SPORT_ACCESS;
  const renderedMenuItems = useRenderMenuItems({
    items: isSportEnabled ? mobileNavItemsWithSport : mobileNavItems
  });
  const {
    selectedBets,
    mobileButtonVariant,
    handleShowCouponPreview,
    isShowPreviewCoupon,
    isShowMobileCoupon
  } = useMobileMenuButton();
  return <div className={cn(styles.root, className)} data-sentry-component="MobileMenu" data-sentry-source-file="MobileMenu.tsx">
      {renderedMenuItems.map(menuItem => <MenuItem menuItem={menuItem} id={menuItem.key} key={menuItem.key} classes={menuItemClasses} />)}
      {isSportPage && !isShowPreviewCoupon && !isShowMobileCoupon && isSportEnabled && <MobileMenuButton onClick={handleShowCouponPreview} variant={mobileButtonVariant} counter={selectedBets.length} />}
    </div>;
};