import type { FC } from 'react';
import type { Placement } from 'tippy.js';
import Tippy from '@tippyjs/react';
import type { TooltipProps } from './Tooltip.types';
import 'tippy.js/animations/shift-toward-subtle.css';
import 'tippy.js/dist/tippy.css';
import styles from './Tooltip.module.scss';
const Tooltip: FC<TooltipProps> = ({
  className,
  tippyRef,
  children,
  content,
  placement,
  variant = 'arrow',
  classes,
  contentVariant = 'default',
  ...restProps
}) => {
  let tippyPlacement: Placement = variant === 'arrow' ? 'top' : 'right-end';
  if (placement) {
    tippyPlacement = placement;
  }
  return <Tippy content={contentVariant === 'default' ? <div className={cn(styles.content, classes?.content)}>{content}</div> : content} className={cn(styles.root, classes?.root, styles[variant])} interactive interactiveBorder={20} placement={tippyPlacement} animation="shift-toward-subtle" {...restProps} ref={tippyRef} data-sentry-element="Tippy" data-sentry-component="Tooltip" data-sentry-source-file="Tooltip.tsx">
      <span className={cn(className, classes?.wrapper)}>{children}</span>
    </Tippy>;
};
export { Tooltip };