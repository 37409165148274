import type { FC, ReactNode } from 'react';
import { useTranslation } from 'next-i18next';
import AviatorIcon from '@public/assets/common/icons/aviator.svg';
import BonusIcon from '@public/assets/common/icons/bonus.svg';
import CasinoIcon from '@public/assets/common/icons/casino.svg';
import SportIcon from '@public/assets/common/icons/sport.svg';
import type { IconLabelProps, IconLabelVariant } from './IconLabel.types';
import styles from './IconLabel.module.scss';
const iconsMap: Record<IconLabelVariant, ReactNode> = {
  aviator: <AviatorIcon width={20} height={20} />,
  bonus: <BonusIcon width={20} height={20} />,
  casino: <CasinoIcon width={20} height={20} />,
  sport: <SportIcon width={20} height={20} />
};
const IconLabel: FC<IconLabelProps> = ({
  icon,
  variant,
  children,
  noText,
  className
}) => {
  const {
    t
  } = useTranslation();
  const defaultText = t(`labels.${variant}`, variant);
  const defaultIcon = variant && iconsMap[variant];
  return <div className={cn(styles.root, className)} data-sentry-component="IconLabel" data-sentry-source-file="IconLabel.tsx">
      <div className={styles.icon}>{icon ?? defaultIcon}</div>
      {!noText && <div className={styles.text}>{children || defaultText}</div>}
    </div>;
};
export { IconLabel };