import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { format } from 'date-fns/format';
import { useTranslation } from 'next-i18next';
import { extendAnalyticsData, numberFormatter, selectUserCurrency, useClipboard, useDeposit } from '@mwl/core-lib';
import CopyIcon from '@public/assets/common/icons/copy.svg';
import { ButtonIcon } from '@/components/ButtonIcon/ButtonIcon';
import { Image } from '@/components/Image/Image';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import type { TooltipClasses } from '@/components/Tooltip/Tooltip.types';
import { useTypedSelector } from '@/hooks';
import { PaymentFormHeader } from '@/modules/payment/components/PaymentFormHeader/PaymentFormHeader';
import { usePayloadDeposit } from '@/modules/payment/views/Deposit/hooks/usePayloadDeposit/usePayloadDeposit';
import type { DepositFormQrCodeProps } from './DepositFormQrCode.types';
import styles from './DepositFormQrCode.module.scss';
const tooltipClasses: TooltipClasses = {
  content: styles.tooltipContent
};
export const DepositFormQrCode: FC<DepositFormQrCodeProps> = ({
  onClose,
  analytics
}) => {
  const {
    t
  } = useTranslation('payment');
  const userCurrency = useTypedSelector(selectUserCurrency);
  const {
    copied,
    copy
  } = useClipboard();
  const {
    content,
    payment
  } = useDeposit();
  const {
    methods
  } = usePayloadDeposit();
  const {
    address,
    expDate,
    minimumAmount,
    qrCode,
    rate,
    currency
  } = content.template ?? {};
  const handleClickCopy = useCallback(() => {
    if (address) {
      copy(address);
    }
  }, [address, copy]);
  const exchangeRate = useMemo(() => {
    const rateValue = Number.isNaN(rate) ? 0 : Number(rate);
    return `1 ${currency} = ${numberFormatter(rateValue, {
      suffix: ` ${userCurrency}`,
      maximumFractionDigits: 6
    })}`;
  }, [currency, rate, userCurrency]);
  const qrCodeAnalytics = extendAnalyticsData(analytics, {
    payment_method: payment?.card.footer,
    payment_identity: payment?.identity,
    payment_title: payment?.title,
    payment_form_type: payment?.type,
    position: methods.findIndex(method => method.identity === payment?.identity) + 1,
    min_amount: payment?.min
  });
  return <div className={styles.root} data-sentry-component="DepositFormQrCode" data-sentry-source-file="DepositFormQrCode.tsx">
      <PaymentFormHeader onClose={onClose} data-sentry-element="PaymentFormHeader" data-sentry-source-file="DepositFormQrCode.tsx" />

      <div className={styles.content}>
        {qrCode && <div className={styles.imgWrapper}>
            <Image src={qrCode} className={styles.qrCode} fill style={{
          objectFit: 'contain'
        }} alt="QR code" />
          </div>}

        <p className={styles.text}>{t('refill.crypto_text', 'Copy the wallet number or scan the QR code')}</p>

        <Tooltip className={styles.tooltip} classes={tooltipClasses} visible={copied} content={t('copy_success', 'Data copied successfully')} data-sentry-element="Tooltip" data-sentry-source-file="DepositFormQrCode.tsx">
          <div className={styles.codeContainer}>
            <p className={styles.address}>{address}</p>

            <ButtonIcon as="button" type="button" variant="text" color="red" className={styles.copyButton} onClick={handleClickCopy} aria-label="deposit copy code" analytics={qrCodeAnalytics} data-sentry-element="ButtonIcon" data-sentry-source-file="DepositFormQrCode.tsx">
              <CopyIcon data-sentry-element="CopyIcon" data-sentry-source-file="DepositFormQrCode.tsx" />
            </ButtonIcon>
          </div>
        </Tooltip>

        <div className={styles.exchangeRate}>{exchangeRate}</div>

        {expDate && <p className={cn(styles.text, styles.date)}>
            {t('refill.crypto_exp_date', 'Transfer to {{time}}', {
          time: format(new Date(expDate), 'HH:mm dd.MM.yyyy')
        })}
          </p>}

        {minimumAmount && <p className={styles.minimumAmount}>
            {t('refill.minimum_amount', 'Minimum deposit amount {{amount}}', {
          amount: numberFormatter(Number(minimumAmount), {
            suffix: ` ${currency}`,
            maximumFractionDigits: 6
          })
        })}
          </p>}
      </div>
    </div>;
};