import type { Ref } from 'react';
import { forwardRef, memo, useCallback, useMemo } from 'react';
import type { NumberFormatValues } from 'react-number-format';
import { FormField } from '@/components/FormField/FormField';
import type { SelectProps } from '@/components/Select/Select';
import { Select } from '@/components/Select/Select';
import type { FormFieldPhoneOption, FormFieldPhoneProps } from './FormFieldPhone.types';
import { createFieldMask, filterItem, getFieldFormat } from './FormFieldPhone.utils';
import styles from './FormFieldPhone.module.scss';
const BaseFormFieldPhone = <T extends FormFieldPhoneOption,>({
  className,
  options,
  value,
  selected,
  onChange,
  onSelect,
  parentRef,
  size = 'md',
  adaptive = true,
  classes = {},
  colorVariant = 'default',
  analytics,
  ...props
}: FormFieldPhoneProps<T>, ref: Ref<HTMLInputElement | HTMLTextAreaElement>): JSX.Element => {
  const selectNumberFormat: SelectProps<T>['numberFormat'] = useMemo(() => ({
    prefix: '+ ',
    allowEmptyFormatting: true
  }), []);
  const handleSelect: SelectProps<T>['onSelect'] = useCallback(option => onSelect(option), [onSelect]);
  const handleChange = useCallback((values: NumberFormatValues) => {
    if (values.value) {
      onChange(values.formattedValue);
    }
  }, [onChange]);
  const formFieldClasses = {
    ...classes,
    componentWrapper: cn(classes?.componentWrapper, styles.wrapper)
  };
  return <div className={cn(styles.root, className)} data-sentry-component="BaseFormFieldPhone" data-sentry-source-file="FormFieldPhone.tsx">
      <Select selected={selected} options={options} onSelect={handleSelect} className={styles.select} numberFormat={selectNumberFormat} filter={filterItem} parentRef={parentRef} size={size} headerClasses={{
      input: classes?.componentWrapper
    }} adaptive={adaptive} colorVariant={colorVariant} analytics={analytics} data-sentry-element="Select" data-sentry-source-file="FormFieldPhone.tsx" />
      <FormField {...props} ref={ref} as="numberFormat" autoComplete="off" value={value} onValueChange={handleChange} format={getFieldFormat(selected.format)} mask={createFieldMask(selected.format, 'X')} allowEmptyFormatting className={cn(styles.field, {
      [styles.empty]: !value.replace(/\D/g, '').length
    })} size={size} colorVariant={colorVariant} classes={formFieldClasses} data-sentry-element="FormField" data-sentry-source-file="FormFieldPhone.tsx" />
    </div>;
};
const FormFieldPhone = memo(forwardRef(BaseFormFieldPhone));
export * from './FormFieldPhone.types';
export { FormFieldPhone };