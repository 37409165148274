import type { ChangeEventHandler, FC, SyntheticEvent } from 'react';
import { useCallback, useRef, useState } from 'react';
import { format } from 'date-fns';
import { getTestProps } from '@mwl/core-lib';
import ArrowIcon from '@public/assets/common/icons/arrow.svg';
import CalendarIcon from '@public/assets/common/icons/calendar.svg';
import { Button } from '@/components/Button/Button';
import { FormField } from '@/components/FormField/FormField';
import { isoDateFormat } from './DatepickerNative.constants';
import type { DateInputRef, DatepickerNativeProps } from './DatepickerNative.types';
import styles from './DatepickerNative.module.scss';
export const DatepickerNative: FC<DatepickerNativeProps> = ({
  label,
  onChange,
  minDate,
  maxDate,
  placeholder,
  selected,
  disabled,
  dateFormat = 'dd.MM.yyyy',
  open,
  error,
  ...props
}) => {
  const inputRef = useRef<DateInputRef>(null);
  const [isPickerOpen, setPickerOpen] = useState(false);
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(({
    target: {
      value: val
    }
  }) => {
    setPickerOpen(false);
    if (!val) {
      return;
    }
    onChange(new Date(val).getTime());
  }, [onChange]);
  const handleOpenDatePicker = useCallback((event: SyntheticEvent) => {
    if (isPickerOpen) {
      event.preventDefault();
    }
    if (disabled || !inputRef?.current) {
      event.preventDefault();
      return;
    }
    if (inputRef?.current && 'showPicker' in inputRef.current && !isPickerOpen) {
      inputRef.current.showPicker();
    }
    setPickerOpen(prev => !prev);
  }, [isPickerOpen, disabled]);
  const handleBlur = () => {
    setPickerOpen(false);
  };
  return <div className={styles.root} data-sentry-component="DatepickerNative" data-sentry-source-file="DatepickerNative.tsx">
      <FormField {...getTestProps(props)} className={cn({
      [styles.open]: isPickerOpen,
      [styles.disabled]: disabled
    })} classes={{
      postfix: styles.postfix,
      component: styles.input
    }} as="input" label={label} postfix={<Button as="button" variant="text" type="button" color="default" className={styles.button} disabled={disabled}>
            <ArrowIcon className={styles.arrowIcon} />
          </Button>} placeholder={placeholder} prefix={<CalendarIcon className={styles.icon} />} value={selected ? format(selected, dateFormat) : ''} readOnly disabled={disabled} isError={error} data-sentry-element="FormField" data-sentry-source-file="DatepickerNative.tsx" />
      <input ref={inputRef} type="date" className={styles.dateInput} value={selected ? format(selected, isoDateFormat) : ''} onChange={handleChange} onBlur={handleBlur} readOnly={disabled} disabled={disabled} onClick={handleOpenDatePicker} />
    </div>;
};