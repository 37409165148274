import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'next-i18next';
import type { PaymentHistoryItem } from '@mwl/core-lib';
import { formatPriceByCurrencyCode, loadMoreHistoryRequest, selectPaymentHistoryIsShowMoreLoading, selectPaymentHistoryTotal } from '@mwl/core-lib';
import CloseIcon from '@public/assets/common/icons/close.svg';
import { Button } from '@/components/Button/Button';
import { ButtonIcon } from '@/components/ButtonIcon/ButtonIcon';
import { PerfectScrollbar } from '@/components/PerfectScrollbar/PerfectScrollbar';
import { useTypedDispatch, useTypedSelector } from '@/hooks';
import { PaymentStatus } from '@/modules/payment/components/PaymentStatus/PaymentStatus';
import { cancelStatuses, columns } from './HistoryTable.data';
import type { HistoryTableProps } from './HistoryTable.types';
import { formatHistoryDate } from './HistoryTable.utils';
import styles from './HistoryTable.module.scss';
export const HistoryTable: FC<HistoryTableProps> = ({
  items,
  className,
  onOpenConfirmPopup,
  onCancelPayment
}) => {
  const {
    t
  } = useTranslation(['common', 'payment']);
  const total = useTypedSelector(selectPaymentHistoryTotal);
  const isLoading = useTypedSelector(selectPaymentHistoryIsShowMoreLoading);
  const dispatch = useTypedDispatch();
  const handleLoadMore = useCallback(() => {
    if (isLoading) {
      return;
    }
    dispatch(loadMoreHistoryRequest());
  }, [isLoading, dispatch]);
  const getDisplayCreditAmount = (item: PaymentHistoryItem): string => {
    let amount: number | null = item.requestedAmount;
    amount ??= item.paymentType === 'DEPOSIT' ? item.creditAmount : item.debitAmount;
    return amount ? formatPriceByCurrencyCode(amount, item.currencyCode) : '';
  };
  const getDisplayPaidAmount = (item: PaymentHistoryItem): string => {
    let amount: number | null = item.requestedAmount;
    amount ??= item.paymentType === 'DEPOSIT' ? item.paidAmount : null;
    return amount ? formatPriceByCurrencyCode(amount, item.currencyCode) : '—';
  };
  return <>
      <PerfectScrollbar className={cn(styles.root, className)} options={{
      suppressScrollX: false
    }} isSwitchOffOnMobile={false} data-sentry-element="PerfectScrollbar" data-sentry-source-file="HistoryTable.tsx">
        <table className={styles.table}>
          <thead className={styles.header}>
            <tr>
              {columns.map(column => <th key={column.key} className={column.className}>
                  {t(column.key, column.text)}
                </th>)}
              <th className={styles.colActions} />
            </tr>
          </thead>
          <tbody className={styles.body}>
            {items.map(item => <tr key={item.id} className={styles.row}>
                <td>{formatHistoryDate(item.createdAt)}</td>
                <td className={cn(styles.colStatus, styles[item.status?.toLowerCase()])}>
                  <PaymentStatus item={item} onOpenConfirmPopup={onOpenConfirmPopup} />
                </td>
                <td>
                  <div className={styles.amountData}>{getDisplayCreditAmount(item)}</div>
                </td>
                <td>
                  <div className={styles.amountData}>{getDisplayPaidAmount(item)}</div>
                </td>
                <td>
                  {item.paymentType ? t(`payment_type.${item.paymentType.toLowerCase()}`, {
                ns: 'payment'
              }) : ''}
                </td>
                <td>{item.paymentMethod || '—'}</td>
                <td>{item.maskedCard || '—'}</td>
                <td className={styles.colActions}>
                  {item.paymentType === 'WITHDRAW' && cancelStatuses[item.status] && <ButtonIcon as="button" className={styles.actionBtn} onClick={() => onCancelPayment?.(item)} size="md" variant="text" color="grey" rounded aria-label="cancel payment">
                      <CloseIcon className={styles.removeIcon} />
                    </ButtonIcon>}
                </td>
              </tr>)}
          </tbody>
        </table>
      </PerfectScrollbar>

      {items.length < total && <Button className={styles.showMoreBtn} as="button" variant="fulfilled" color="red" size="sm" fontWeight="bold" rounded disabled={isLoading} onClick={handleLoadMore} cut={false}>
          {t('show_more', 'Show more', {
        ns: 'common'
      })}
        </Button>}
    </>;
};
export * from './HistoryTable.types';