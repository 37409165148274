import type { FC } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { Button } from '@/components/Button/Button';
import type { AdditionalNavItemProps } from './AdditionalNavItem.types';
import styles from './AdditionalNavItem.module.scss';
const AdditionalNavItem: FC<AdditionalNavItemProps> = ({
  classes,
  href,
  translationKey,
  translationText
}) => {
  const {
    t
  } = useTranslation();
  const router = useRouter();
  return <Button className={cn(styles.navItem, classes?.navItem, {
    [styles.active]: router.pathname === href
  })} as="link" color="default" href={href} fullWidth fontWeight="bold" align="left" data-sentry-element="Button" data-sentry-component="AdditionalNavItem" data-sentry-source-file="AdditionalNavItem.tsx">
      {t(translationKey, translationText)}
    </Button>;
};
export { AdditionalNavItem };