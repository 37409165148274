import type { FC } from 'react';
import { useTranslation } from 'next-i18next';
import { selectUserIsAuthenticated, useLoadTournaments } from '@mwl/core-lib';
import { ColoredButton } from '@/components/ColoredButton/ColoredButton';
import { GameCard } from '@/components/GameCard/GameCard';
import { Promotions } from '@/components/Promotions/Promotions';
import { SliderCards } from '@/components/SliderCards/SliderCards';
import { SliderCardsLoadable } from '@/components/SliderCardsLoadable/SliderCardsLoadable';
import { Tournaments } from '@/components/Tournaments/Tournaments';
import { listings, mainCardsBreakpoints, promotionsAnalytics, promotionsBreakpoints, providersAnalytics, routes, sliderCardsAnalytics, sportPinnedBreakpoints, tournamentsAnalytics, tournamentsBreakpoints } from '@/constants';
import { useMediaWidthDown, useTypedSelector } from '@/hooks';
import { loadCasinoNewGames, loadFavoritesGames } from '@/modules/casino/utils/loadGames';
import { getGameAnalytics, mainPageObject } from '@/utils';
import { Providers } from '../casino/components/Providers/Providers';
import { CasinoBanners } from './components/CasinoBanners/CasinoBanners';
import { TopLines } from './components/TopLines/TopLines';
import type { MainPageProps } from './MainPage.types';
import styles from './MainPage.module.scss';
const maxDisplayGamesCount = 20;
export const MainPage: FC<MainPageProps> = ({
  className,
  liveLines,
  pregameLines,
  topProviders,
  topGames
}) => {
  const isAuth = useTypedSelector(selectUserIsAuthenticated);
  const isXsScreen = useMediaWidthDown('screenXs');
  const {
    t
  } = useTranslation('casino');
  const {
    tournaments
  } = useLoadTournaments(true);
  const topGamesList = topGames.items.length ? topGames.items.slice(0, maxDisplayGamesCount) : [];
  return <div className={cn(styles.root, className)} {...mainPageObject.root.nodeProps} data-sentry-component="MainPage" data-sentry-source-file="MainPage.tsx">
      <div className={styles.buttons}>
        <ColoredButton color="yellow" className={styles.promoButton} href={routes.promo.home} image="/assets/bonus/images/promoButtonIcon.webp" data-sentry-element="ColoredButton" data-sentry-source-file="MainPage.tsx">
          {t('common:promo_bonuses', 'Promo and bonuses')}
        </ColoredButton>
      </div>
      {liveLines && <section className={styles.top}>
          <TopLines title={t('section.live', 'Top live')} moreButtonText={t('common:all', 'All')} moreButtonHref={routes.sport.home} breakpoints={sportPinnedBreakpoints} lines={liveLines} />
        </section>}

      <CasinoBanners position={isXsScreen ? 'main_banners_sport_mobile' : 'main_banners_sport'} data-sentry-element="CasinoBanners" data-sentry-source-file="MainPage.tsx" />

      {pregameLines && <TopLines breakpoints={sportPinnedBreakpoints} title={t('section.pregame', 'Top pregames')} moreButtonText={t('common:all', 'All')} moreButtonHref={routes.sport.home} lines={pregameLines} />}

      {!!topGamesList.length && <SliderCards className={styles.section} title={t('section.top', 'Top')} totalGamesCount={topGames.totalElements} breakpoints={mainCardsBreakpoints} analytics={sliderCardsAnalytics?.slider} moreButtonText={t('common:all', 'All')} moreButtonHref={{
      pathname: routes.casino.category,
      query: {
        category: 'slots',
        tag: 'top'
      }
    }} {...mainPageObject.topGames.nodeProps}>
          {topGamesList.map((game, index) => <GameCard key={game.id} {...game} withProviderButton analytics={getGameAnalytics({
        analytics: sliderCardsAnalytics?.game,
        listings: listings.topGames,
        count: topGamesList.length,
        game,
        index
      })} />)}
        </SliderCards>}

      <Providers className={styles.section} title={t('section.providers', 'Top providers')} href={routes.providers.home} analytics={providersAnalytics} items={topProviders} data-sentry-element="Providers" data-sentry-source-file="MainPage.tsx" />

      <SliderCardsLoadable className={styles.section} maxDisplayItemsCount={maxDisplayGamesCount} title={t('section.new', 'New')} loadMethod={loadCasinoNewGames} breakpoints={mainCardsBreakpoints} moreButtonText={t('common:all', 'All')} moreButtonHref={{
      pathname: routes.casino.category,
      query: {
        category: 'slots',
        tag: 'new'
      }
    }} analytics={sliderCardsAnalytics} listings={listings.newGames} data-sentry-element="SliderCardsLoadable" data-sentry-source-file="MainPage.tsx" />

      <CasinoBanners className={styles.subSection} position={isXsScreen ? 'main_banners_casino_mobile' : 'main_banners_casino'} data-sentry-element="CasinoBanners" data-sentry-source-file="MainPage.tsx" />

      {isAuth && <SliderCardsLoadable maxDisplayItemsCount={maxDisplayGamesCount} className={styles.section} title={t('section.favorites', 'Top favorites')} loadMethod={loadFavoritesGames} moreButtonText={t('common:all', 'All')} moreButtonHref={routes.casino.favorites} breakpoints={mainCardsBreakpoints} analytics={sliderCardsAnalytics} listings={listings.favorites} />}

      <Promotions className={styles.section} maxDisplayItemsCount={8} title={t('section.promotions', 'Promotions')} breakpoints={promotionsBreakpoints} moreButtonText={t('common:all', 'All')} moreButtonHref={{
      pathname: routes.promo.home
    }} analytics={promotionsAnalytics} listings={listings.promotions} data-sentry-element="Promotions" data-sentry-source-file="MainPage.tsx" />

      <Tournaments className={styles.section} maxDisplayItemsCount={8} title={t('section.tournaments', 'Tournaments')} breakpoints={tournamentsBreakpoints} moreButtonText={t('common:all', 'All')} moreButtonHref={{
      pathname: routes.tournaments.home
    }} tournaments={tournaments} analytics={tournamentsAnalytics} listings={listings.tournaments} data-sentry-element="Tournaments" data-sentry-source-file="MainPage.tsx" />
    </div>;
};