import type { FC, MouseEvent } from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { isBefore } from 'date-fns/isBefore';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { extendAnalyticsData, formatTournamentPrize, sendAnalyticsData, useTournamentTime } from '@mwl/core-lib';
import { nanoid } from '@reduxjs/toolkit';
import tournamentCardAngle from '@public/assets/casino/tournament/tournamentCardAngle.webp';
import tournamentCardAngleRed from '@public/assets/casino/tournament/tournamentCardAngleRed.webp';
import { Image } from '@/components/Image/Image';
import { useMediaWidthDown } from '@/hooks';
import { tournamentsPageObject } from '@/utils';
import { Button } from '../Button/Button';
import { IconLabel } from '../IconLabel/IconLabel';
import { TournamentPlace } from '../TournamentPlace/TournamentPlace';
import { TournamentTimer } from '../TournamentTimer/TournamentTimer';
import { SpecialBadge } from './components/SpecialBadge/SpecialBadge';
import type { CardSize, TournamentCardProps } from './TournamentCard.types';
import styles from './TournamentCard.module.scss';
const TournamentCard: FC<TournamentCardProps> = ({
  link,
  theme,
  prizeFund,
  prizeFundType,
  prizeFundCount,
  name,
  title,
  startAt,
  mediaBannerImage,
  place,
  score,
  endAt,
  badge,
  disabled = false,
  className,
  size,
  analytics
}) => {
  const isSmallScreen = useMediaWidthDown('screenXs');
  const sizeInternal: CardSize = size || (isSmallScreen ? 'small' : 'medium');
  const {
    title: dateTitle,
    Timer
  } = useTournamentTime({
    startAt,
    endAt,
    TimerComponent: TournamentTimer
  });
  const {
    t
  } = useTranslation('casino');
  const router = useRouter();
  const aboutButtonRef = useRef<HTMLAnchorElement>(null);
  const [refreshValue, setRefreshValue] = useState(nanoid());
  const disabledInternal = useMemo(() => {
    return disabled || isBefore(new Date(endAt), Date.now() + 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshValue, disabled, endAt]);
  const cardClassName = cn(styles.root, className, {
    [styles.disabled]: disabledInternal,
    [styles.compact]: sizeInternal === 'small'
  });

  // Fallback for old tournaments
  const prize = formatTournamentPrize({
    count: prizeFundCount,
    type: prizeFundType,
    title: t(`tournament.card.prize.${name}`, 'Prize fund')
  });
  const onComplete = useCallback(() => {
    setRefreshValue(nanoid());
  }, []);
  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    if (event.target === aboutButtonRef.current) {
      return;
    }
    sendAnalyticsData(analytics?.card?.click?.eventName, {
      ...(analytics?.card?.click?.data || {}),
      cls: cardClassName,
      href: link
    });
    router.push(link);
  };
  const isLottery = theme === 'lottery';
  const eventType = isLottery ? ['tournament.card.type.lottery', 'Lottery'] : ['tournament.card.type.tournament', 'Tournament'];
  const aboutButtonText = t('tournament.card.button', 'About');
  return <div {...tournamentsPageObject.card.nodeProps} className={cardClassName} onClick={handleClick} role="presentation" data-sentry-component="TournamentCard" data-sentry-source-file="TournamentCard.tsx">
      <div className={styles.angleWrapper}>
        <Image className={styles.angle} src={tournamentCardAngleRed} alt={name} height={80} data-sentry-element="Image" data-sentry-source-file="TournamentCard.tsx" />
        <Image className={cn(styles.angle, styles.angleHovered)} src={tournamentCardAngle} alt={name} height={80} data-sentry-element="Image" data-sentry-source-file="TournamentCard.tsx" />
      </div>

      <div className={styles.bannerImage}>
        {mediaBannerImage && <Image src={mediaBannerImage} priority quality={100} width={300} alt={name} fill style={{
        objectFit: 'contain'
      }} />}
      </div>

      {sizeInternal === 'medium' ? <div className={styles.header}>
          <div className={styles.type}>{t(eventType)}</div>
          <SpecialBadge badge={badge} />
          {theme !== 'lottery' && <div className={styles.casino}>
              <div className={styles.casinoImg}>
                <IconLabel variant="casino" />
              </div>
            </div>}
        </div> : <div className={styles.header}>
          <div className={styles.type}>{t(eventType)}</div>
          <div className={styles.badges}>
            <SpecialBadge badge={badge} skew="right" />
            {theme !== 'lottery' && <IconLabel noText variant="casino" />}
          </div>
        </div>}

      {!!title && <h3 className={styles.title}>{t(title)}</h3>}
      <h2 className={styles.prize}>{prizeFund || prize}</h2>
      {/* <div className={styles.bannerImage}>{bannerImage && <Image src={bannerImage} alt={name} layout="fill" objectFit="contain" />}</div> */}
      {Timer && <Timer status={dateTitle} className={styles.timer} key={refreshValue} onComplete={onComplete} disabled={disabledInternal} size={sizeInternal} place={<TournamentPlace place={place} score={score} size={sizeInternal} isLottery={isLottery} eventStarted={isBefore(new Date(startAt), Date.now() + 1000)} className={styles.place} />} />}
      <Button ref={aboutButtonRef} className={styles.aboutButton} fullWidth size="md" rounded as="link" variant="fulfilled" color="red" fontWeight="bold" fontSize="lg" disabled={disabledInternal} href={link} cut={false} analytics={extendAnalyticsData(analytics?.aboutButton, {
      text: aboutButtonText,
      href: link
    })} data-sentry-element="Button" data-sentry-source-file="TournamentCard.tsx">
        {aboutButtonText}
      </Button>
    </div>;
};
export { TournamentCard };
export * from './TournamentCard.types';