import type { LoadCasinoBlockGames } from '@mwl/core-lib';
import {
  allProductTypes,
  createLoadBlockGames,
  createLoadFavoritesGames,
  createLoadGames,
  isErrorResult,
} from '@mwl/core-lib';

import { loadCasinoGamesByType } from '@/utils/loadCasinoGamesByType';

export const load18Games = 18;

export const { loadGamesByIds } = createLoadGames(allProductTypes, load18Games);
export const loadCasinoFavoritesGames = createLoadFavoritesGames();

const { loadAllGames: loadAllCasinoGames, loadGamesByCategory: loadMoreInfiniteGamesByCategory } = createLoadGames([
  'casino',
]);

const { loadGamesByCategory: loadMoreNotInfiniteGamesByCategory } = createLoadGames(['casino'], load18Games);
const { loadAllGames: loadAllFastGames } = createLoadGames(['fast_games']);
const loadCasinoBlockGames = createLoadBlockGames('casino');
const loadLiveCasinoBlockGames = createLoadBlockGames('live_casino');
const { loadAllGames } = createLoadGames(['casino', 'virtual_sport', 'fast_games']);

export const loadCasinoTopGames = loadCasinoBlockGames('top');
export const loadCasinoBrandedGames = loadCasinoBlockGames('branded');
export const loadCasinoNewGames = loadCasinoBlockGames('newest');
export const loadCasinoRecommendedGames = loadCasinoBlockGames('recommended');
export const loadCasinoCrashGames = loadCasinoBlockGames('crash_games');
export const loadCasinoAllBlockGames = loadCasinoBlockGames('all');
export const loadLiveCasinoRecommendedGames = loadLiveCasinoBlockGames('recommended');

export const loadPopularGames = loadCasinoGamesByType('casino', 'top');
export const loadNewGames = loadCasinoGamesByType('casino', 'newest');

export const loadCasinoAllGames = loadAllGames();
export const loadCasinoBuyFeaturesGames = loadAllCasinoGames([], ['buy_features']);
export const loadCasinoJackpotGames = loadAllCasinoGames([], ['jackpot']);
export const loadCasinoFastGames = loadAllFastGames(['position']);

export const loadCasinoP2PGames = loadMoreNotInfiniteGamesByCategory(['p2p_table_games']);
export const loadCasinoPokerGames = loadMoreNotInfiniteGamesByCategory(['poker', 'video_poker']);
export const loadCasinoBlackjackGames = loadMoreNotInfiniteGamesByCategory(['blackjack']);
export const loadCasinoBaccaratGames = loadMoreNotInfiniteGamesByCategory(['baccarat']);

export const loadCasinoTableGames = loadMoreInfiniteGamesByCategory(['table_games']);
export const loadCasinoRouletteGames = loadMoreInfiniteGamesByCategory(['roulette']);
export const loadCasinoMoreSlotsGames = loadMoreInfiniteGamesByCategory(['slots']);

export const loadFavoritesGames: LoadCasinoBlockGames = async ({ platform: _ }) => {
  const result = await createLoadFavoritesGames()({ page: 0, size: 8 });

  if (isErrorResult(result)) {
    return result;
  }

  return { items: result.items, totalElements: result.totalElements };
};
