import type { FC } from 'react';
import { useCallback } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import { getFieldAttrValue, isPaymentFieldCopiable, isPaymentFieldHideHeader, isPaymentFieldTitle, openJivoChat, useClipboard } from '@mwl/core-lib';
import CopyIcon from '@public/assets/common/icons/copy.svg';
import { Button } from '@/components/Button/Button';
import { ButtonIcon } from '@/components/ButtonIcon/ButtonIcon';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import type { TooltipClasses } from '@/components/Tooltip/Tooltip.types';
import type { PaymentReadonlyFieldProps } from './PaymentReadonlyField.types';
import styles from './PaymentReadonlyField.module.scss';
const tooltipClasses: TooltipClasses = {
  content: styles.tooltip
};
export const PaymentReadonlyField: FC<PaymentReadonlyFieldProps> = ({
  className,
  value,
  attr,
  title,
  position
}) => {
  const {
    t
  } = useTranslation('payment');
  const {
    copied,
    copy
  } = useClipboard();
  const handleClickCopy = useCallback(() => {
    if (value) {
      copy(value);
    }
  }, [copy, value]);
  const hideTitle = isPaymentFieldHideHeader(attr);
  const showCopyButton = isPaymentFieldCopiable(attr);
  const isTitle = isPaymentFieldTitle(attr);
  const isSupportLink = getFieldAttrValue('component', attr) === 'supportLink';
  if (isSupportLink) {
    return <div className={cn(styles.jivo, styles[`${position?.horizontal}Align`])}>
        <Trans ns="payment" i18nKey={t(title ?? '')} components={[<Button key={0} className={cn(styles.link)} as="button" type="button" onClick={openJivoChat} variant="text" uppercase={false} />]} />
      </div>;
  }
  return <Tooltip classes={tooltipClasses} visible={copied} content={t('copy_success', 'Data copied successfully')} data-sentry-element="Tooltip" data-sentry-component="PaymentReadonlyField" data-sentry-source-file="PaymentReadonlyField.tsx">
      <div className={cn(styles.root, position && styles[`${position.horizontal}Align`], className)}>
        {title && !hideTitle && <span className={cn(styles.label, isTitle && styles.title)}>{t(title)}</span>}
        <div className={styles.valueContainer}>
          {showCopyButton && <ButtonIcon as="button" className={styles.copyButton} type="button" variant="text" color="red" onClick={handleClickCopy} aria-label="payment copy">
              <CopyIcon className={styles.copyIcon} />
            </ButtonIcon>}
          <span className={styles.value}>{value}</span>
        </div>
      </div>
    </Tooltip>;
};