import type { FC } from 'react';
import { getTestProps, omitTestProps } from '@mwl/core-lib';
import { GamesGridLayout } from '@/components/GamesGridLayout/GamesGridLayout';
import { LoadableItemsWrapper } from '@/components/LoadableItemsWrapper/LoadableItemsWrapper';
import { getGameAnalytics } from '@/utils';
import { CardGrid } from '../CardGrid/CardGrid';
import { GameCard } from '../GameCard/GameCard';
import type { SectionGamesProps } from './SectionGames.types';
import styles from './SectionGames.module.scss';
export const SectionGames: FC<SectionGamesProps> = ({
  games,
  title,
  href,
  onLoad,
  hasMore,
  classes,
  isError,
  isLoading,
  className,
  isInfinite,
  emptyContent,
  totalGamesCount,
  isShowItemsCount,
  scrollThreshold = 0.55,
  infiniteScrollTargetId,
  isShowBadges = true,
  analytics,
  listings,
  ...props
}) => {
  const loadableProps = isInfinite ? ({
    as: 'infinite',
    dataLength: games.length,
    next: onLoad ?? (() => {}),
    hasMore: !!hasMore,
    scrollThreshold,
    scrollContainerId: infiniteScrollTargetId
  } as const) : ({
    as: 'block'
  } as const);
  if (!games.length) {
    if (emptyContent) {
      return <GamesGridLayout className={className} title={title} classes={classes}>
          {emptyContent}
        </GamesGridLayout>;
    }
    return null;
  }
  return <GamesGridLayout {...getTestProps(props)} href={href} className={cn(styles.root, className)} title={title} classes={classes} totalGamesCount={totalGamesCount} isShowItemsCount={isShowItemsCount} data-sentry-element="GamesGridLayout" data-sentry-component="SectionGames" data-sentry-source-file="SectionGames.tsx">
      <LoadableItemsWrapper {...loadableProps} data-sentry-element="LoadableItemsWrapper" data-sentry-source-file="SectionGames.tsx">
        <CardGrid hideHasMore={isInfinite && !isError} loadNext={onLoad} hasNext={hasMore} isInfinite={isInfinite} isLoading={!!isLoading} {...omitTestProps(props)} data-sentry-element="CardGrid" data-sentry-source-file="SectionGames.tsx">
          {games.map((game, index) => <GameCard {...game} key={game.id} badges={isShowBadges ? game.badges : []} analytics={getGameAnalytics({
          analytics,
          listings,
          count: totalGamesCount || 0,
          game,
          index
        })} />)}
        </CardGrid>
      </LoadableItemsWrapper>
    </GamesGridLayout>;
};
export * from './SectionGames.types';