import { type FC, type MouseEvent, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import type { TopMarketOutcomeEmptySB, TopMarketOutcomeSB } from '@mwl/core-lib';
import { selectCouponSelectedBetsV2, selectIsCouponQuickBetsV2, selectSportFavoritesSetV2, selectUserIsAuthenticated, useLineBehaviorV2, useWebsocketLineShort } from '@mwl/core-lib';
import type { OutcomeButtonBaseOldClasses } from '@mwl/ui';
import { Button } from '@/components/Button/Button';
import { base, routes } from '@/constants';
import { useSportLinks, useTypedSelector } from '@/hooks';
import type { LineWithEntities } from '@/types';
import { MatchTeam } from '../MatchTeam/MatchTeam';
import { OutcomeButton } from '../OutcomeButton/OutcomeButton';
import { SportButton } from '../SportButton/SportButton';
import { SportButtonFavorites } from '../SportButtonFavorites/SportButtonFavorites';
import { SportIcon } from '../SportIcon/SportIcon';
import { PinnedMatchInfo } from './components/PinnedMatchInfo/PinnedMatchInfo';
import { getPinnedLineAnalytics } from './PinnedLine.analytics';
import type { PinnedLineProps } from './PinnedLine.types';
import styles from './PinnedLine.module.scss';
const outcomeAliases = ['1', 'x', '2'];
const oddClasses: OutcomeButtonBaseOldClasses = {
  empty: styles.oddEmpty,
  active: styles.oddActive,
  title: styles.oddTitle,
  increase: styles.oddIncrease,
  decrease: styles.oddDecrease
};
export const PinnedLine: FC<PinnedLineProps> = ({
  line: initialLine,
  className
}) => {
  const {
    t
  } = useTranslation('sport');
  const isAuth = useTypedSelector(selectUserIsAuthenticated);
  const favorites = useTypedSelector(selectSportFavoritesSetV2);
  const line = useWebsocketLineShort<LineWithEntities>(initialLine, base.NAME_PROJECT);
  const {
    lineHref,
    hasWidgets,
    competitors,
    hasTranslation,
    singleCompetitor
  } = useLineBehaviorV2({
    line,
    routes
  });
  const selectedBets = useSelector(selectCouponSelectedBetsV2);
  const isQuickBets = useSelector(selectIsCouponQuickBetsV2);
  const isFavorite = useMemo(() => favorites.lineIds.has(line.id), [favorites.lineIds, line.id]);
  const analytics = getPinnedLineAnalytics({
    line: initialLine,
    isFavorite: !isFavorite
  });
  const {
    SportLink,
    championshipLink,
    sportCode,
    sportIcon
  } = useSportLinks({
    data: initialLine,
    styles,
    analytics: {
      sportLink: analytics.sportCategory,
      categoryLink: analytics.categoryName,
      championShipLink: analytics.championshipName
    }
  });
  const market = line.topMarkets?.find(item => item.alias === '1x2');
  const aliasedOutcomes = useMemo<Array<TopMarketOutcomeSB | TopMarketOutcomeEmptySB>>(() => {
    if (!market) {
      return [];
    }
    const outcomesByAlias = (market?.outcomes ?? []).reduce<Dictionary<TopMarketOutcomeSB>>((acc, outcome) => {
      if (outcome.alias) {
        // eslint-disable-next-line no-param-reassign
        acc[outcome.alias] = outcome;
      }
      return acc;
    }, {});
    return outcomeAliases.map((alias, index) => {
      const outcome = outcomesByAlias[alias];
      if (!outcome) {
        return {
          id: `${market.alias}_${index}`,
          title: t(`outcome_title.${market.alias}_${alias}`),
          alias: 'empty'
        };
      }
      return {
        ...outcome,
        title: t(`outcome_title.${market.alias}_${alias}`)
      };
    });
  }, [market, t]);
  const preventDefault = useCallback((event: MouseEvent) => event.preventDefault(), []);
  return <div className={cn(styles.root, className, styles[line.stage.toLowerCase()])} data-sentry-component="PinnedLine" data-sentry-source-file="PinnedLine.tsx">
      <div className={cn(styles.row, styles.header, {
      [styles.withFavorite]: isAuth
    })}>
        <SportLink data-sentry-element="SportLink" data-sentry-source-file="PinnedLine.tsx">
          <div className={styles.headerIconWrapper}>
            <SportIcon url={sportIcon} size={20} data-sentry-element="SportIcon" data-sentry-source-file="PinnedLine.tsx" />
          </div>
        </SportLink>
        <p className={styles.headerTitle}>{championshipLink}</p>
        {isAuth && <div onClick={preventDefault} role="presentation">
            <SportButtonFavorites isFavorite={isFavorite} lineId={line.id} analytics={analytics.lineGroup} />
          </div>}
      </div>

      <div className={cn(styles.row, styles.contentRow)}>
        <SportButton as="link" href={lineHref} variant="text" className={cn(styles.content, {
        [styles.isOneCompetitor]: !!singleCompetitor
      })} fullWidth uppercase={false} analytics={analytics.matchName} data-sentry-element="SportButton" data-sentry-source-file="PinnedLine.tsx">
          <MatchTeam {...singleCompetitor ?? competitors.HOME} sportCode={sportCode} sportIcon={sportIcon} className={styles.team} classes={{
          name: styles.teamName,
          nameContainer: styles.teamNameContainer
        }} direction={singleCompetitor ? 'row' : 'column'} data-sentry-element="MatchTeam" data-sentry-source-file="PinnedLine.tsx" />

          <PinnedMatchInfo line={line} hasWidgets={hasWidgets} hasTranslation={hasTranslation} singleCompetitor={!!singleCompetitor} data-sentry-element="PinnedMatchInfo" data-sentry-source-file="PinnedLine.tsx" />
          {!singleCompetitor && <MatchTeam {...competitors.AWAY} sportCode={sportCode} sportIcon={sportIcon} className={styles.team} classes={{
          name: styles.teamName,
          image: styles.teamImage
        }} />}
        </SportButton>
      </div>

      <div className={cn(styles.row, styles.outcomes)}>
        {aliasedOutcomes.length === 0 && <div className={styles.moreBetsWrapper}>
            <Button as="link" href={lineHref} variant="fulfilled" fullWidth color="grey" fontWeight="bold" rounded cut={false} className={styles.moreBets}>
              {t('more_bets', 'More Bets')}
            </Button>
          </div>}
        {aliasedOutcomes.length > 0 && <div className={styles.outcomesWrapper}>
            {aliasedOutcomes.map(outcome => {
          const isSelectedOutcome = isQuickBets ? false : !!selectedBets.find(bet => bet.outcomeId === outcome?.id);
          const analyticsOutcome = {
            click: {
              eventName: !isSelectedOutcome ? analytics.odd.select?.eventName : analytics.odd.unselect?.eventName,
              data: {
                ...(!isSelectedOutcome ? analytics.odd.select?.data : analytics.odd.unselect?.data),
                outcomes_id: outcome.id || '',
                odds: (outcome as TopMarketOutcomeSB).odds || 0
              }
            }
          };
          return <OutcomeButton lineId={line.id} key={outcome.id} outcome={(outcome as TopMarketOutcomeSB)} variant="horizontal" className={styles.outcome} classes={oddClasses} analytics={analyticsOutcome} animationTimeout={1000} />;
        })}
          </div>}
      </div>
    </div>;
};