import type { Ref } from 'react';
import { forwardRef, memo, useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import Scrollbar from 'react-perfect-scrollbar';
import { useIsVisibleWatch } from '@mwl/ui';
import type { PerfectScrollbarWithShadowProps } from './PerfectScrollbarWithShadow.types';
import styles from './PerfectScrollbarWithShadow.module.scss';
const defaultOptions: PerfectScrollbarWithShadowProps['options'] = {
  minScrollbarLength: 35,
  maxScrollbarLength: 35,
  suppressScrollX: true,
  wheelPropagation: false
};
const BasePerfectScrollbarWithShadow = forwardRef(({
  children,
  options = {},
  className,
  isShowShadow = false,
  isSwitchOffOnMobile = true,
  onScrollUp,
  onYReachStart,
  onYReachEnd,
  onXReachEnd,
  onXReachStart,
  verticalScrollbarSpace = 'none',
  classes,
  shadowVariant = 'dark100Shadow',
  ...props
}: PerfectScrollbarWithShadowProps, ref: Ref<Scrollbar & HTMLDivElement>): JSX.Element => {
  const [isVerticalReachedScrollEnd, setIsVerticalReachedScrollEnd] = useState(false);
  const [isHorizontalReachedScrollEnd, setIsHorizontalReachedScrollEnd] = useState(false);
  const [isVerticalScrollBar, setIsVerticalScrollBar] = useState<boolean | null>(null);
  const [isHorizontalScrollBar, setIsHorizontalScrollBar] = useState<boolean | null>(null);
  const [containerElem, setContainerElem] = useState<HTMLElement>();
  const containerRefHandler = (elem: HTMLElement) => {
    if (elem && !containerElem) {
      setContainerElem(elem);
    }
  };
  const isVisible = useIsVisibleWatch(containerElem);
  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      const element = entries?.[0]?.target;
      const isHTMLDivElement = element instanceof HTMLDivElement;
      if (isHTMLDivElement && element.scrollHeight > element.offsetHeight !== isVerticalScrollBar) {
        setIsVerticalScrollBar(element.scrollHeight > element.offsetHeight);
      }
      if (isHTMLDivElement && element.scrollWidth > element.offsetWidth !== isHorizontalScrollBar) {
        setIsHorizontalScrollBar(element.scrollWidth > element.offsetWidth);
      }
    });
    if (containerElem && isVisible) {
      observer.observe(containerElem);
    }
    return () => {
      if (containerElem && observer) {
        observer.disconnect();
      }
    };
  }, [containerElem, isHorizontalScrollBar, isVerticalScrollBar, isVisible]);
  const onVerticalReachEndHandler = useCallback((container: HTMLElement) => {
    setIsVerticalReachedScrollEnd(true);
    onYReachEnd?.(container);
  }, [onYReachEnd]);
  const onVerticalReachStartHandler = useCallback((container: HTMLElement) => {
    setIsVerticalReachedScrollEnd(false);
    onYReachStart?.(container);
  }, [onYReachStart]);
  const onVerticalScrollUpHandler = useCallback((container: HTMLElement) => {
    setIsVerticalReachedScrollEnd(false);
    onScrollUp?.(container);
  }, [onScrollUp]);
  const onHorizontalReachEndHandler = useCallback((container: HTMLElement) => {
    setIsHorizontalReachedScrollEnd(true);
    onXReachEnd?.(container);
  }, [onXReachEnd]);
  const onHorizontalReachStartHandler = useCallback((container: HTMLElement) => {
    setIsHorizontalReachedScrollEnd(false);
    onXReachStart?.(container);
  }, [onXReachStart]);
  if (isMobile && isSwitchOffOnMobile) {
    return <div ref={ref} className={cn(styles.mobile, styles[`${verticalScrollbarSpace}VerticalSpace`], className)} {...props}>
          {children}
        </div>;
  }
  return <div className={cn(styles.scrollWrapper, {
    [styles.vEndShadow]: isShowShadow && isVerticalScrollBar,
    [styles.hEndShadow]: isShowShadow && isHorizontalScrollBar,
    [styles.vReachEnd]: isVerticalReachedScrollEnd,
    [styles.hReachEnd]: isHorizontalReachedScrollEnd,
    [styles.visible]: isVisible,
    [styles.showWrapperVerticalScrollbar]: isVerticalScrollBar
  }, styles[`${verticalScrollbarSpace}WrapperVerticalSpace`], styles[shadowVariant], classes?.wrapper, className)}>
        <Scrollbar className={cn(styles.root, {
      [styles.hideVerticalScrollbar]: !isVerticalScrollBar
    }, styles[`${verticalScrollbarSpace}VerticalSpace`], classes?.scrollbar)} {...props} options={{
      ...defaultOptions,
      ...options
    }} ref={ref} containerRef={containerRefHandler} onScrollUp={onVerticalScrollUpHandler} onYReachStart={onVerticalReachStartHandler} onYReachEnd={onVerticalReachEndHandler} onXReachStart={onHorizontalReachStartHandler} onXReachEnd={onHorizontalReachEndHandler}>
          {children}
        </Scrollbar>
      </div>;
});
export * from './PerfectScrollbarWithShadow.types';
export const PerfectScrollbarWithShadow = memo(BasePerfectScrollbarWithShadow);