import type { ChangeEventHandler, FC } from 'react';
import { memo, useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { removeAllSpaces, removeURLSearchParams, requestConfirmationCodeV2, selectRecoveryLogin, useForm, useSubmitForm } from '@mwl/core-lib';
import { Button } from '@/components/Button/Button';
import { FormField } from '@/components/FormField/FormField';
import { FormMessage } from '@/components/FormMessage/FormMessage';
import { checkIsEmail } from '@/components/FormRecovery/FormRecovery.utils';
import { Loader } from '@/components/Loader/Loader';
import { useRouterHistory } from '@/context';
import { useTypedSelector } from '@/hooks';
import { formRecoveryLoginObject } from '@/utils';
import { useRecoveryState } from '../../FormRecovery.context';
import { FormRecoveryTimer } from '../FormRecoveryTimer/FormRecoveryTimer';
import type { FormRecoveryLoginProps, FormRecoveryLoginValues } from './FormRecoveryLogin.types';
import { formatLogin, getFormSettings } from './FormRecoveryLogin.utils';
import styles from './FormRecoveryLogin.module.scss';
const BaseFormRecoveryLogin: FC<FormRecoveryLoginProps> = ({
  className,
  onChangeTab
}) => {
  const {
    t
  } = useTranslation('common');
  const userLogin = useTypedSelector(selectRecoveryLogin);
  const history = useRouterHistory();
  const router = useRouter();
  const {
    timer,
    onChangeState
  } = useRecoveryState();
  const {
    formState,
    changeField,
    handleSubmit
  } = useForm<FormRecoveryLoginValues>(getFormSettings(userLogin));
  const handleResetTimer = useCallback(() => onChangeState({
    timer: null,
    isCodeError: false
  }), [onChangeState]);
  const {
    state: {
      error,
      isLoading
    },
    onSubmitConfirm
  } = useSubmitForm({
    requestHandler: requestConfirmationCodeV2,
    formState: formState.values,
    onSuccess: data => {
      if (data) {
        if (data.result === 'SUCCESS') {
          onChangeState({
            login: formatLogin(formState.values.login),
            timer: data.timer
          });
          onChangeTab('code');
        } else {
          onChangeState({
            timer: data.timer,
            isCodeError: true
          });
        }
      }
    }
  });
  const handleClose = useCallback(() => {
    router.replace(history.previous || removeURLSearchParams({
      url: router.asPath
    }), undefined, {
      scroll: false,
      shallow: true
    });
  }, [history.previous, router]);
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    const {
      name,
      value
    } = event.target;
    changeField((name as keyof FormRecoveryLoginValues), removeAllSpaces(value));
  }, [changeField]);
  const submitConfirm = useCallback(async (value: FormRecoveryLoginValues) => {
    onSubmitConfirm({
      confirmationSubject: formatLogin(value.login)
    });
  }, [onSubmitConfirm]);
  const errorText = useMemo(() => {
    if (error && error?.code === 'NOT_FOUND') {
      return checkIsEmail(formState.values.login) ? t('recovery_error_email_not_found', 'Email does not exist, please enter a new value') : t('recovery_error_phone_not_found', 'There is no user with this phone number');
    }
    if (error) {
      return Array.isArray(error.message) ? t(error.message) : error.message;
    }
    if (formState.isTouched && formState.errors) {
      return t(formState.errors.login);
    }
    return null;
  }, [error, formState, t]);
  return <form className={cn(styles.root, className)} onSubmit={handleSubmit(submitConfirm)} {...formRecoveryLoginObject.nodeProps} data-sentry-component="BaseFormRecoveryLogin" data-sentry-source-file="FormRecoveryLogin.tsx">
      {errorText && <FormMessage className={styles.message}>{errorText}</FormMessage>}
      {isLoading && <Loader />}
      <FormField as="input" placeholder={t('form.field.email_or_password', 'E-mail or telephone')} value={formState.values.login} name="login" onChange={handleChange} className={styles.field} isError={Boolean(formState.isTouched && errorText)} {...formRecoveryLoginObject.subjectField.nodeProps} data-sentry-element="FormField" data-sentry-source-file="FormRecoveryLogin.tsx" />
      <Button as="button" variant={timer ? 'outlined' : 'fulfilled'} color="red" fontSize="xl" fontWeight="bold" size="md" fullWidth className={styles.button} type="submit" disabled={!!timer} rounded {...formRecoveryLoginObject.submitButton.nodeProps} data-sentry-element="Button" data-sentry-source-file="FormRecoveryLogin.tsx">
        {t('recovery.button.proceed', 'Proceed')}
        {timer && <FormRecoveryTimer date={timer} onComplete={handleResetTimer} />}
      </Button>
      <Button as="button" size="md" fontSize="xl" fontWeight="bold" fullWidth className={styles.button} type="button" onClick={handleClose} rounded color="grey" data-sentry-element="Button" data-sentry-source-file="FormRecoveryLogin.tsx">
        {t('button.cancel', `Cancel`)}
      </Button>
    </form>;
};
const FormRecoveryLogin = memo(BaseFormRecoveryLogin);
export * from './FormRecoveryLogin.types';
export { FormRecoveryLogin };