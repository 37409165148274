import type { FC } from 'react';
import { Button } from '@/components/Button/Button';
import type { ColoredButtonProps } from './ColoredButton.types';
import styles from './ColoredButton.module.scss';
const ColoredButton: FC<ColoredButtonProps> = ({
  children,
  className,
  color,
  image,
  ...props
}) => {
  return <Button as="link" className={cn(styles.root, className, styles[`${color}Color`])} {...props} fontWeight="bold" variant="text" rounded data-sentry-element="Button" data-sentry-component="ColoredButton" data-sentry-source-file="ColoredButton.tsx">
      {children}
      <div className={styles.image} style={{
      backgroundImage: `url(${image})`
    }} />
    </Button>;
};
export { ColoredButton };