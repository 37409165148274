import type { FC } from 'react';
import type { CountdownProps } from 'react-countdown';
import ReactCountdown from 'react-countdown';
import { NoSSR } from '@mwl/ui';
const Countdown: FC<CountdownProps> = props => {
  return <NoSSR data-sentry-element="NoSSR" data-sentry-component="Countdown" data-sentry-source-file="Countdown.tsx">
      <ReactCountdown {...props} data-sentry-element="ReactCountdown" data-sentry-source-file="Countdown.tsx" />
    </NoSSR>;
};
export type { CountdownProps, ReactCountdown };
export { Countdown };