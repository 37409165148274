import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { getTestProps, sendAnalyticsData } from '@mwl/core-lib';
import { DropdownBase, DropdownMenu, DropdownTrigger } from '@mwl/ui';
import ArrowIcon from '@public/assets/common/icons/arrow.svg';
import { DropdownContent } from './components/DropdownContent/DropdownContent';
import { menuTransition } from './Dropdown.data';
import type { DropdownProps } from './Dropdown.types';
import styles from './Dropdown.module.scss';
const Dropdown: FC<DropdownProps> = ({
  active,
  children,
  classes,
  className,
  customHeader,
  disabled,
  contentWidth,
  maxDesktopHeight,
  maxMobileHeight,
  parentRef,
  title,
  variant = 'fulfilled',
  onToggle,
  appendTo,
  CustomArrowIcon = ArrowIcon,
  contentHeader,
  transition = {},
  contentTestProps,
  idleTimeout,
  analytics,
  ...props
}) => {
  const activeTimeout = useRef(0);
  const dropdownClassName = cn(styles.root, styles[`${variant}Variant`], disabled && styles.disabled, className);
  const handleClick = useCallback(() => {
    onToggle();
    if (!active && analytics?.open) {
      sendAnalyticsData(analytics.open.eventName, {
        ...analytics.open.data,
        cls: dropdownClassName
      });
    }
    if (active && analytics?.close) {
      sendAnalyticsData(analytics.close.eventName, {
        ...analytics.close.data,
        cls: dropdownClassName
      });
    }
  }, [active, analytics, onToggle, dropdownClassName]);
  useEffect(() => {
    if (!idleTimeout || !active) {
      return undefined;
    }
    activeTimeout.current = window.setTimeout(() => onToggle(false), idleTimeout);
    return () => clearTimeout(activeTimeout.current);
  }, [onToggle, idleTimeout, active]);
  const content = useMemo(() => <DropdownContent maxDesktopHeight={maxDesktopHeight} maxMobileHeight={maxMobileHeight} classes={{
    content: cn(styles.content, classes?.content)
  }} parentRef={parentRef} {...contentTestProps} htmlProps={{
    onMouseEnter: () => clearTimeout(activeTimeout.current),
    onMouseLeave: () => {
      if (!idleTimeout) {
        return;
      }
      activeTimeout.current = window.setTimeout(() => onToggle(false), idleTimeout);
    }
  }}>
        {children}
      </DropdownContent>, [children, classes?.content, contentTestProps, idleTimeout, maxDesktopHeight, maxMobileHeight, onToggle, parentRef]);
  const onOpenChange = (isOpen: boolean) => {
    onToggle?.(isOpen);
    if (isOpen && analytics?.open) {
      sendAnalyticsData(analytics.open.eventName, {
        ...analytics.open.data,
        cls: dropdownClassName
      });
    }
    if (!isOpen && analytics?.close) {
      sendAnalyticsData(analytics.close.eventName, {
        ...analytics.close.data,
        cls: dropdownClassName
      });
    }
  };
  return <DropdownBase className={dropdownClassName} open={active} onOpenChange={onOpenChange} {...props} data-sentry-element="DropdownBase" data-sentry-component="Dropdown" data-sentry-source-file="Dropdown.tsx">
      <DropdownTrigger data-sentry-element="DropdownTrigger" data-sentry-source-file="Dropdown.tsx">
        <div {...getTestProps(props)} role="presentation" aria-label="dropdown" onClick={handleClick} className={cn(styles.header, classes?.header)} tabIndex={-1}>
          {!customHeader && <div className={cn(styles.headerContent, classes?.header)}>
              <div className={cn(styles.title, classes?.title)}>{title}</div>
              <CustomArrowIcon className={cn(styles.icon, classes?.arrow)} />
            </div>}
          {customHeader}
        </div>
      </DropdownTrigger>

      <DropdownMenu root={appendTo} className={cn(styles.container, styles[`${contentWidth}ContentWidth`], classes?.container)} transition={{
      ...menuTransition,
      ...transition
    }} data-sentry-element="DropdownMenu" data-sentry-source-file="Dropdown.tsx">
        {contentHeader}
        {content}
      </DropdownMenu>
    </DropdownBase>;
};
export * from './Dropdown.types';
export { Dropdown };