import type { FC } from 'react';
import { memo } from 'react';
import { useTranslation } from 'next-i18next';
import { extendAnalyticsData, selectIsFullRegistration } from '@mwl/core-lib';
import { useTypedSelector } from '@/hooks';
import type { PerfectScrollbarWithShadowProps } from '../PerfectScrollbarWithShadow/PerfectScrollbarWithShadow';
import { PerfectScrollbarWithShadow } from '../PerfectScrollbarWithShadow/PerfectScrollbarWithShadow';
import { Tab } from '../Tab/Tab';
import { Tabs } from '../Tabs/Tabs';
import { FormRegistrationEmail } from './components/FormRegistrationEmail/FormRegistrationEmail';
import { FormRegistrationExtended } from './components/FormRegistrationExtended/FormRegistrationExtended';
import { FormRegistrationPhone } from './components/FormRegistrationPhone/FormRegistrationPhone';
import { FormRegistrationSn } from './components/FormRegistrationSn/FormRegistrationSn';
import { tabsContent } from './FormRegistration.data';
import type { FormRegistrationProps, RegistrationTabsContent } from './FormRegistration.types';
import styles from './FormRegistration.module.scss';
const scrollbarOptions: PerfectScrollbarWithShadowProps['options'] = {
  suppressScrollX: false,
  suppressScrollY: true
};
const BaseFormRegistration: FC<FormRegistrationProps> = ({
  className,
  parentRef,
  variant = 'default',
  registerTestSchema,
  setTab,
  tab,
  analytics
}) => {
  const {
    t
  } = useTranslation('common');
  const isFullRegistration = useTypedSelector(selectIsFullRegistration);
  return <div className={cn(styles.root, styles[`${variant}Variant`], className)} ref={parentRef} data-sentry-component="BaseFormRegistration" data-sentry-source-file="FormRegistration.tsx">
      <div className={styles.tabContainer}>
        <PerfectScrollbarWithShadow classes={{
        scrollbar: styles.scrollbar
      }} isShowShadow isSwitchOffOnMobile={false} className={styles.tabsWrapper} options={scrollbarOptions} shadowVariant="dark80Shadow" data-sentry-element="PerfectScrollbarWithShadow" data-sentry-source-file="FormRegistration.tsx">
          <Tabs className={styles.tabs} data-sentry-element="Tabs" data-sentry-source-file="FormRegistration.tsx">
            {(Object.keys(tabsContent) as Keys<RegistrationTabsContent>).map(key => {
            const content = tabsContent[key];
            if (typeof content.isVisible === 'function' && !content.isVisible(variant, isFullRegistration)) {
              return null;
            }
            const tabAnalytics = key !== 'extended' ? extendAnalyticsData(analytics?.tabs, {
              reg_type: key,
              text: t(content.label)
            }) : undefined;
            return <Tab as="button" key={key} className={styles.tab} label={t(content.label)} onClick={() => setTab(key)} active={key === tab} analytics={tabAnalytics} {...registerTestSchema?.tabs(key).nodeProps} />;
          })}
          </Tabs>
        </PerfectScrollbarWithShadow>
      </div>

      {tab === 'phone' && <FormRegistrationPhone className={cn(styles.form)} variant={variant} parentRef={parentRef} testSchema={registerTestSchema?.formPhone} analytics={analytics?.form} />}
      {tab === 'email' && <FormRegistrationEmail className={cn(styles.form)} variant={variant} parentRef={parentRef} testSchema={registerTestSchema?.formEmail} analytics={analytics?.form} />}
      {tab === 'social' && <FormRegistrationSn className={cn(styles.form)} variant={variant} parentRef={parentRef} testSchema={registerTestSchema?.formSocial} analytics={analytics?.form} />}
      {tab === 'extended' && isFullRegistration && <FormRegistrationExtended className={cn(styles.form)} variant={variant} parentRef={parentRef} />}
    </div>;
};
const FormRegistration = memo(BaseFormRegistration);
export * from './FormRegistration.types';
export { FormRegistration };