import { useTranslation } from 'next-i18next';
import { selectUserIsAuthenticated } from '@mwl/core-lib';
import { useTypedSelector } from '@/hooks';
import type { UsePlaceContentParams } from './TournamentPlace.types';
export const usePlaceContent = ({
  isLottery,
  score,
  eventStarted,
  place
}: UsePlaceContentParams) => {
  const {
    t
  } = useTranslation('casino');
  const isAuth = useTypedSelector(selectUserIsAuthenticated);
  if (typeof place === 'undefined' || !isAuth) {
    return undefined;
  }
  if (isLottery && eventStarted && score) {
    return <>
        {t('tournament.card.tickets', 'Your tickets:')}
        &nbsp;
        {score}
      </>;
  }
  if (!isLottery && place > -1 && score) {
    return <>
        {t('tournament.card.place', 'Your place:')}
        &nbsp;
        {place}
      </>;
  }
  return undefined;
};