import type { FC } from 'react';
import { useMemo } from 'react';
import { getTestProps } from '@mwl/core-lib';
import { PinnedLine } from '@/components/PinnedLine/PinnedLine';
import { SliderCards } from '@/components/SliderCards/SliderCards';
import { sportLinesEnrichment } from '@/utils';
import { breakpoints } from './TopLines.data';
import type { TopLinesProps } from './TopLines.types';
export const TopLines: FC<TopLinesProps> = ({
  className,
  lines: linesProp,
  ...props
}) => {
  const lines = useMemo(() => linesProp ? sportLinesEnrichment(linesProp) : [], [linesProp]);
  if (!lines.length) {
    return null;
  }
  return <SliderCards {...getTestProps(props)} {...props} breakpoints={breakpoints} data-sentry-element="SliderCards" data-sentry-component="TopLines" data-sentry-source-file="TopLines.tsx">
      {lines.map(line => <PinnedLine line={line} key={line.id} />)}
    </SliderCards>;
};