import type { FC } from 'react';
import type { FormFieldLabelProps } from './FormFieldLabel.types';
import styles from './FormFieldLabel.module.scss';
const FormFieldLabel: FC<FormFieldLabelProps> = ({
  hasError,
  children,
  hasValue,
  hasApprove,
  hasOverflow = true,
  ...labelProps
}) => {
  return <label {...labelProps} className={cn(styles.label, {
    [styles.hasError]: hasError,
    [styles.hasValue]: hasValue,
    [styles.hasApprove]: hasApprove,
    [styles.hasOverflow]: hasOverflow
  }, labelProps.className)} data-sentry-component="FormFieldLabel" data-sentry-source-file="FormFieldLabel.tsx">
      {children}
    </label>;
};
export { FormFieldLabel };