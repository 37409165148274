import type { MouseEvent, MouseEventHandler, Ref } from 'react';
import { forwardRef } from 'react';
import Link from 'next/link';
import { handleEventWithAnalytics } from '@mwl/core-lib';
import type { MwlRadius } from '@mwl/ui';
import { Button as MwlButton } from '@mwl/ui';
import type { ButtonProps } from './Button.types';
import { getButtonClassNames } from './Button.utils';
const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(({
  active,
  align = 'center',
  children,
  className,
  color = 'white',
  cut = true,
  disabled,
  fontSize = 'none',
  rounded,
  size = 'none',
  skew = 'none',
  uppercase = true,
  variant = 'fulfilled',
  onClick,
  analytics,
  ...props
}, ref) => {
  const buttonClassNames = getButtonClassNames({
    active,
    className,
    color,
    cut,
    disabled,
    rounded,
    skew,
    uppercase,
    variant
  });
  const analyticsClassName: string | undefined = analytics?.click?.data?.cls;
  const analyticsData = {
    ...(analytics?.click?.data || {}),
    cls: analytics?.click?.data?.cls || buttonClassNames
  };
  const handleOnClick = handleEventWithAnalytics<MouseEvent<HTMLButtonElement | HTMLAnchorElement>>((onClick as MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> | undefined), analytics?.click?.eventName, analyticsData);
  const tagProps = props['data-cy'] ? {
    'data-cy': props['data-cy']
  } : {};
  const defaultRadius: MwlRadius = 'sm';
  const commonProps = {
    className: cn(analyticsClassName, buttonClassNames),
    onClick: handleOnClick,
    justify: align,
    radius: rounded ? defaultRadius : undefined,
    disabled,
    fontSize,
    size
  };
  if (props.as === 'link') {
    const {
      as: _,
      ...otherProps
    } = props;
    return <MwlButton {...commonProps} {...otherProps} component={Link} ref={(ref as Ref<HTMLAnchorElement>)} {...tagProps}>
          {children}
        </MwlButton>;
  }
  if (props.as === 'external') {
    const {
      as: _,
      target = '_blank',
      ...otherProps
    } = props;
    return <MwlButton {...commonProps} component="a" ref={(ref as Ref<HTMLAnchorElement>)} role="presentation" target={target} rel="noopener noreferrer" {...otherProps} {...tagProps}>
          {children}
        </MwlButton>;
  }
  const {
    as: _,
    ...otherProps
  } = props;
  return <MwlButton {...commonProps} {...otherProps} {...tagProps} ref={(ref as Ref<HTMLButtonElement>)}>
        {children}
      </MwlButton>;
});
Button.displayName = 'Button';
export * from './Button.types';
export { Button };