import type { FC } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { extendAnalyticsData } from '@mwl/core-lib';
import AndroidIcon from '@public/assets/common/icons/android.svg';
import AppleIcon from '@public/assets/common/icons/apple.svg';
import { routes } from '@/constants';
import { useTypedDispatch } from '@/hooks';
import { toggleSidebar } from '@/store/features/ui/actions';
import { ButtonIcon } from '../ButtonIcon/ButtonIcon';
import type { DownloadProps } from './Download.types';
import styles from './Download.module.scss';
export const Download: FC<DownloadProps> = ({
  variant = 'default',
  classes,
  testMarker,
  analytics
}) => {
  const {
    t
  } = useTranslation('common');
  const dispatch = useTypedDispatch();
  const router = useRouter();
  const androidHref = {
    pathname: routes.app,
    query: {
      platform: 'android'
    }
  };
  const iosHref = {
    pathname: routes.app,
    query: {
      platform: 'ios'
    }
  };
  const handleAndroidClickButton = () => {
    router.push(androidHref);
    dispatch(toggleSidebar(false));
  };
  const handleIOSClickButton = () => {
    router.push(iosHref);
    dispatch(toggleSidebar(false));
  };
  return <div {...testMarker?.button.nodeProps} className={cn(styles.root, classes?.root, styles[variant])} data-sentry-component="Download" data-sentry-source-file="Download.tsx">
      <ButtonIcon {...testMarker?.icon.nodeProps} className={cn(styles.button, classes?.button)} as="button" onClick={handleIOSClickButton} variant="text" aria-label="ios" analytics={extendAnalyticsData(analytics?.ios, {
      href: iosHref
    })} data-sentry-element="ButtonIcon" data-sentry-source-file="Download.tsx">
        <AppleIcon data-sentry-element="AppleIcon" data-sentry-source-file="Download.tsx" />
        <div className={styles.label} hidden={variant === 'icon'}>
          <div>{t('footer_download_ios_title', 'Download on the')}</div>
          <div className={cn(styles.platform, styles.platformIOS)}>
            {t('footer_download_ios_subtitle', 'App Store')}
          </div>
        </div>
      </ButtonIcon>

      <ButtonIcon {...testMarker?.icon.nodeProps} className={cn(styles.button, classes?.button)} as="button" onClick={handleAndroidClickButton} variant="text" aria-label="android" analytics={extendAnalyticsData(analytics?.android, {
      href: androidHref
    })} data-sentry-element="ButtonIcon" data-sentry-source-file="Download.tsx">
        <AndroidIcon data-sentry-element="AndroidIcon" data-sentry-source-file="Download.tsx" />
        <div className={styles.label} hidden={variant === 'icon'}>
          <div>{t('footer_download_android_title', 'Download the')}</div>
          <div className={cn(styles.platform, styles.platformAndroid)}>
            {t('footer_download_android_subtitle', 'Android App')}
          </div>
        </div>
      </ButtonIcon>
    </div>;
};