import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import type { TranslationKey } from '@mwl/core-lib';
import { selectUserCurrencySymbol } from '@mwl/core-lib';
import { useTypedSelector } from '@/hooks';
import type { PaymentFormType } from '@/types';
import { PaymentSubmit } from '../PaymentSubmit/PaymentSubmit';
import type { PaymentFormFieldsProps } from './PaymentFormFields.types';
import { formatPriceRange } from './PaymentFormFields.utils';
import styles from './PaymentFormFields.module.scss';
const translates: Record<PaymentFormType, TranslationKey> = {
  deposit: ['deposit', 'Pay {{amount}} {{currency}}'],
  payout: ['withdrawal', 'Withdrawal']
};
export const PaymentFormFields: FC<PaymentFormFieldsProps> = ({
  variant,
  method,
  children,
  footer,
  amount,
  isLoading,
  className,
  analytics
}) => {
  const {
    t
  } = useTranslation('payment');
  const currencySymbol = useTypedSelector(selectUserCurrencySymbol);
  const {
    min,
    max
  } = method;
  const priceRange = useMemo(() => formatPriceRange({
    min,
    max,
    t
  }), [max, min, t]);
  const isAmount = amount && +amount > 0 ? true : undefined;
  return <div className={cn(styles.root, className)} data-sentry-component="PaymentFormFields" data-sentry-source-file="PaymentFormFields.tsx">
      <div className={styles.content}>
        {priceRange && <div className={styles.limits}>
            <span>{t('limit_transaction', 'Limits per transaction')}:</span>{' '}
            <span className={styles.range}>{priceRange}</span>
          </div>}
        {children}

        <PaymentSubmit className={styles.submit} disabled={isLoading} analytics={analytics} data-sentry-element="PaymentSubmit" data-sentry-source-file="PaymentFormFields.tsx">
          {method.type === 'pop-up' && t('form_popup.button.submit', 'Ok')}
          {method.type !== 'pop-up' && t(translates[variant], {
          amount: isAmount && amount,
          currency: isAmount && currencySymbol
        })}
        </PaymentSubmit>
      </div>

      <div className={styles.footer}>{footer}</div>
    </div>;
};