import { type FC, useMemo } from 'react';
import { useRouter } from 'next/router';
import { CasinoFilters } from '@/components/CasinoFilters/CasinoFilters';
import { routes } from '@/constants';
import { casinoPageObject } from '@/utils';
import { filtersBySection } from '../../CasinoLayout.utils';
import { Navigation } from '../Navigation/Navigation';
import type { CasinoLayoutNavigationProps } from './CasinoLayoutNavigation.types';
import styles from './CasinoLayoutNavigation.module.scss';
export const CasinoLayoutNavigation: FC<CasinoLayoutNavigationProps> = ({
  navigation,
  productType
}) => {
  const {
    pathname
  } = useRouter();

  // NOTE: disabled in https://jira.dats.tech/browse/MWL-12086
  // const { offset, elementRef: floatMenuRef, visible } = useFloatNavMenu();

  const filters = useMemo(() => {
    if (pathname === routes.providers.provider) {
      return filtersBySection.provider;
    }
    if (pathname === routes.providers.provider) {
      return filtersBySection.liveCasinoProvider;
    }
    if (pathname.includes(routes.liveCasino.home)) {
      return filtersBySection.liveCasino;
    }
    return filtersBySection.default;
  }, [pathname]);
  return <div className={styles.wrapper} data-sentry-component="CasinoLayoutNavigation" data-sentry-source-file="CasinoLayoutNavigation.tsx">
      {/* NOTE: disabled in https://jira.dats.tech/browse/MWL-12086 */}
      {/* <div */}
      {/*   className={cn(styles.floatContainer, { */}
      {/*     [styles.visible]: visible, */}
      {/*   })} */}
      {/*   style={{ transform: `translateY(${visible ? `${offset}px` : 0})` }} */}
      {/* > */}
      {navigation && <Navigation />}

      {pathname !== routes.home && <CasinoFilters {...casinoPageObject.casinoFilters.nodeProps} productType={productType} renderFilters={filters} />}
      {/* </div> */}
    </div>;
};