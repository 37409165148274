import type { FC } from 'react';
import { memo, useCallback, useEffect } from 'react';
import { selectUserIsAuthenticated, useQueryPopup } from '@mwl/core-lib';
import { Popup } from '@/components/Popup/Popup';
import { useTypedSelector } from '@/hooks';
import { FormRecovery } from '../FormRecovery/FormRecovery';
const BasePopupRecovery: FC = () => {
  const {
    show,
    onClose
  } = useQueryPopup('recovery');
  const isAuth = useTypedSelector(selectUserIsAuthenticated);
  const handleClose = useCallback(() => onClose(), [onClose]);
  useEffect(() => {
    if (isAuth && show) {
      onClose({
        shallow: false
      });
    }
  }, [show, isAuth, onClose]);
  return <Popup show={show && !isAuth} onClose={handleClose} data-sentry-element="Popup" data-sentry-component="BasePopupRecovery" data-sentry-source-file="PopupRecovery.tsx">
      <FormRecovery defaultTab="link" data-sentry-element="FormRecovery" data-sentry-source-file="PopupRecovery.tsx" />
    </Popup>;
};
const PopupRecovery = memo(BasePopupRecovery);
export { PopupRecovery };