import { format } from 'date-fns/format';
import type { PaymentHistoryItem } from '@mwl/core-lib';
import { initialCount } from './HistoryTable.data';
import type { HistoryTableState } from './HistoryTable.types';
export const formatHistoryDate = (date: string) => {
  return format(new Date(date), 'dd.MM.yyyy HH:mm');
};
export const getInitialState = (items: Array<PaymentHistoryItem>): HistoryTableState => {
  const count = Math.min(items.length, initialCount);
  return {
    count,
    list: items.slice(0, count)
  };
};