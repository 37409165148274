import type { FC } from 'react';
import { memo, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { kebabToCamelCase } from '@mwl/core-lib';
import { MessageBase } from '@mwl/ui';
import CloseIcon from '@public/assets/common/icons/close.svg';
import CloseRoundIcon from '@public/assets/common/icons/closeRound.svg';
import DoubleCheckIcon from '@public/assets/common/icons/doubleCheck.svg';
import { Button } from '../Button/Button';
import type { MessageProps } from './Message.types';
import styles from './Message.module.scss';
const BaseMessage: FC<MessageProps> = ({
  className,
  buttonText,
  title,
  children,
  icon: customIcon,
  onClick,
  status = 'default',
  withIcon = true,
  customAction,
  buttonFullWidth = true,
  classes,
  buttonLinkProps,
  buttonColor = 'red',
  textSize = 'small',
  textColor = 'white',
  isLowLevelError = false,
  analytics
}) => {
  const {
    t
  } = useTranslation('common');
  const Icon = useMemo(() => {
    if (customIcon) {
      return customIcon;
    }
    if (status === 'success') {
      return <DoubleCheckIcon width={32} className={styles.icon} />;
    }
    if (status === 'error' && isLowLevelError) {
      return <CloseRoundIcon width={40} height={40} className={styles.icon} />;
    }
    if (status === 'error') {
      return <CloseIcon width={16} height={16} className={styles.icon} />;
    }
    return <span className={styles.default}>!</span>;
  }, [customIcon, status, isLowLevelError]);
  const buttonAnalytics = {
    click: {
      eventName: analytics?.click?.eventName,
      data: {
        ...(analytics?.click?.data || {}),
        text: buttonText || t('modal_message.ok', 'ok')
      }
    }
  };
  const buttonCommonProps = buttonLinkProps ? ({
    as: 'link',
    ...buttonLinkProps
  } as const) : ({
    as: 'button',
    onClick
  } as const);
  return <MessageBase className={cn(styles.root, styles[`${status}Variant`], className)} classes={{
    title: cn(styles.title, classes?.title),
    iconWrapper: cn(styles.iconWrapper, classes?.iconWrapper)
  }} showIcon={withIcon} icon={Icon} title={title} data-sentry-element="MessageBase" data-sentry-component="BaseMessage" data-sentry-source-file="Message.tsx">
      {children && <div className={cn(styles.text, styles[`${kebabToCamelCase(textColor)}TextColor`], styles[`${textSize}TextSize`])}>
          {children}
        </div>}
      {customAction || <Button {...buttonCommonProps} onClick={onClick} className={cn(styles.button, classes?.button)} fontSize="lg" fontWeight="bold" size="md" fullWidth={buttonFullWidth} align="center" color={buttonColor} rounded analytics={buttonAnalytics}>
          {buttonText || t('modal_message.ok', 'ok')}
        </Button>}
    </MessageBase>;
};
const Message = memo(BaseMessage);
export * from './Message.types';
export { Message };