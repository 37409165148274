import type { FC } from 'react';
import { useTimezoneDate } from '@mwl/core-lib';
import { SportClock } from '../SportClock/SportClock';
import type { LiveStageStatusProps } from './LiveStageStatus.types';
import styles from './LiveStageStatus.module.scss';
const LiveStageStatus: FC<LiveStageStatusProps> = ({
  clock,
  currentSegment,
  dayFormat = 'MM.dd',
  classes,
  className,
  status,
  beginAt,
  ...props
}) => {
  const beginAtDate = new Date(beginAt || '');
  const {
    value: dayValue
  } = useTimezoneDate({
    date: beginAtDate,
    format: dayFormat
  });
  return <span className={cn(styles.root, className)} {...props} data-sentry-component="LiveStageStatus" data-sentry-source-file="LiveStageStatus.tsx">
      <span className={cn(styles.date, classes?.date)}>{dayValue}</span>
      <span className={styles.content}>
        {clock && clock.time > 0 && <span className={cn(styles.time, classes?.time)}>
            <SportClock clock={clock} status={status} stage="LIVE" />
          </span>}

        {!!currentSegment && <span className={cn(styles.status, classes?.status)}>{currentSegment}</span>}
      </span>
    </span>;
};
export { LiveStageStatus };