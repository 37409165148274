import type { FC } from 'react';
import type { PaginationProps } from './Pagination.types';
import styles from './Pagination.module.scss';
const Pagination: FC<PaginationProps> = ({
  pages,
  activeIndex,
  onChange,
  onMouseEnter,
  onMouseLeave
}) => {
  return <div className={styles.root} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} data-sentry-component="Pagination" data-sentry-source-file="Pagination.tsx">
      {Array.from({
      length: pages
    }, (__, index) => <div key={index} role="presentation" className={cn(styles.page, {
      [styles.active]: index === activeIndex
    })} onClick={() => onChange?.(index)} />)}
    </div>;
};
export { Pagination };