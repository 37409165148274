import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'next-i18next';
import { payoutCreateKeys } from '@mwl/core-lib';
import { ModalMessage } from '@/components/ModalMessage/ModalMessage';
import type { PopupClasses } from '@/components/Popup/Popup.types';
import { useWithdrawal } from '../../Withdrawal.context';
import { ConfirmationPopup } from '../ConfirmationPopup/ConfirmationPopup';
import styles from './WithdrawalModals.module.scss';
const modalMessageClasses: PopupClasses = {
  wrapper: styles.messageWrapper
};
export const WithdrawalModals: FC = () => {
  const {
    t
  } = useTranslation('payment');
  const {
    popup,
    closePopup,
    changePayment,
    openPopup
  } = useWithdrawal();
  const content = typeof popup?.message === 'string' ? t(popup?.message) : undefined;
  const closeSuccessModal = useCallback(() => {
    closePopup();
    changePayment();
  }, [changePayment, closePopup]);
  const handleCloseConfirmation = useCallback((isSuccess?: boolean) => {
    if (isSuccess) {
      closeSuccessModal();
    } else {
      openPopup({
        type: 'warning',
        title: t(payoutCreateKeys.warning.title),
        message: t(payoutCreateKeys.warning.message),
        buttonText: t(payoutCreateKeys.warning.button)
      });
    }
  }, [closeSuccessModal, openPopup, t]);
  return <>
      <ModalMessage status="error" show={popup?.type === 'error'} title={popup?.title} content={content} buttonText={popup?.buttonText} popupClasses={modalMessageClasses} onClose={closePopup} data-sentry-element="ModalMessage" data-sentry-source-file="WithdrawalModals.tsx" />
      <ModalMessage status="success" show={popup?.type === 'success'} title={popup?.title} content={content} buttonText={popup?.buttonText} popupClasses={modalMessageClasses} onClose={closeSuccessModal} data-sentry-element="ModalMessage" data-sentry-source-file="WithdrawalModals.tsx" />
      <ModalMessage status="default" show={popup?.type === 'warning'} title={popup?.title} content={content} buttonText={popup?.buttonText || ''} popupClasses={modalMessageClasses} onClose={closePopup} data-sentry-element="ModalMessage" data-sentry-source-file="WithdrawalModals.tsx" />
      <ConfirmationPopup show={popup?.type === 'confirmation'} onClose={handleCloseConfirmation} confirmation={popup?.confirmation} data-sentry-element="ConfirmationPopup" data-sentry-source-file="WithdrawalModals.tsx" />
    </>;
};