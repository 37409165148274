import type { FC } from 'react';
import { MenuItem } from '../MenuItem/MenuItem';
import type { SidebarNavMenuProps } from './SidebarNavMenu.types';
import styles from './SidebarNavMenu.module.scss';
export const SidebarNavMenu: FC<SidebarNavMenuProps> = ({
  list
}) => {
  return <nav className={styles.root} data-sentry-component="SidebarNavMenu" data-sentry-source-file="SidebarNavMenu.tsx">
      {list?.map(menuItem => <MenuItem {...menuItem} id={menuItem.key} key={menuItem.key} />)}
    </nav>;
};