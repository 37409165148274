import type { FC } from 'react';
import { memo } from 'react';
import bonusItemImage from '@public/assets/bonus/images/casinoBonus.webp';
import { Image } from '@/components/Image/Image';
import type { BonusItemProps } from './BonusItem.types';
import styles from './BonusItem.module.scss';
const BaseBonusItem: FC<BonusItemProps> = ({
  title,
  text,
  active,
  comingSoon,
  image,
  onClick
}) => {
  return <div className={cn(styles.root, {
    [styles.active]: active,
    [styles.comingSoon]: comingSoon
  })} onClick={onClick} role="presentation" data-sentry-component="BaseBonusItem" data-sentry-source-file="BonusItem.tsx">
      <div className={styles.image}>
        <Image src={image || bonusItemImage} alt="Bonus" width={61} height={42} data-sentry-element="Image" data-sentry-source-file="BonusItem.tsx" />
      </div>
      <div className={styles.rules}>
        <span className={styles.title}>{title}</span>
        <span className={styles.text}>{text}</span>
      </div>
    </div>;
};
const BonusItem = memo(BaseBonusItem);
export { BonusItem };