import type { FC } from 'react';
import { useAviator } from '@mwl/core-lib';
import BuyBonusIcon from '@public/assets/bonus/icons/buyBonus.svg';
import AviatorTextIcon from '@public/assets/common/icons/aviatorText.svg';
import type { ButtonAsLink, ButtonProps } from '@/components/Button/Button';
import { Button } from '@/components/Button/Button';
import { routes } from '@/constants';
import type { NavMenuItem as NavMenuItemType } from '@/types';
import styles from './NavMenuItem.module.scss';
interface NavMenuItemProps extends Omit<ButtonAsLink, 'as' | 'variant' | 'type'>, Pick<NavMenuItemType, 'type'> {
  isActive: boolean;
}
const NavMenuItem: FC<NavMenuItemProps> = ({
  isActive,
  href,
  type,
  ...props
}) => {
  const aviatorHref = useAviator({
    routes
  });
  const commonProps: ButtonProps = {
    as: 'link',
    href,
    variant: isActive ? 'fulfilled' : 'text',
    skew: 'right',
    fontSize: 'sm',
    fontWeight: 'bold',
    className: cn(styles.item, {
      [styles.active]: isActive
    })
  };
  if (type === 'buy_bonus') {
    return <Button {...commonProps} color={isActive ? 'red' : 'yellow'} {...props}>
        <BuyBonusIcon className={styles.buyBonusIcon} />
        {props.children}
      </Button>;
  }
  if (type === 'aviator') {
    return <Button as="link" variant="text" href={aviatorHref} color="aviator" className={styles.aviator}>
        <AviatorTextIcon />
      </Button>;
  }
  return <Button {...commonProps} color={isActive ? 'red' : 'white'} {...props} data-sentry-element="Button" data-sentry-component="NavMenuItem" data-sentry-source-file="NavMenuItem.tsx" />;
};
export { NavMenuItem };