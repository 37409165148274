import type { FC } from 'react';
import { ButtonIconBase } from '@mwl/ui';
import { Button } from '../Button/Button';
import type { ButtonIconProps } from './ButtonIcon.types';
import styles from './ButtonIcon.module.scss';
const ButtonIcon: FC<ButtonIconProps> = ({
  cover,
  rounded,
  size = 'auto',
  variant = 'text',
  className,
  ...props
}) => {
  return <ButtonIconBase className={cn(styles.button, className, {
    [styles.rounded]: rounded,
    [styles.cover]: cover
  })} component={Button} {...props} variant={variant} size={size} data-sentry-element="ButtonIconBase" data-sentry-component="ButtonIcon" data-sentry-source-file="ButtonIcon.tsx" />;
};
export * from './ButtonIcon.types';
export { ButtonIcon };