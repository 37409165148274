import type { FC } from 'react';
import { memo, useEffect } from 'react';
import { handleEventWithAnalytics, usePrevious, useSwitchValue } from '@mwl/core-lib';
import { FormFieldHiddenBase } from '@mwl/ui';
import EyeIcon from '@public/assets/common/icons/eye.svg';
import LockIcon from '@public/assets/common/icons/lock.svg';
import { FormField } from '@/components/FormField/FormField';
import type { FormFieldHiddenProps } from './FormFieldHidden.types';
import styles from './FormFieldHidden.module.scss';
const BaseFormFieldHidden: FC<FormFieldHiddenProps> = ({
  withEye = true,
  resetFieldSecureValue,
  autoComplete = 'off',
  classes = {},
  analytics,
  ...props
}) => {
  const prevResetValue = usePrevious(resetFieldSecureValue);
  const {
    value: isSecure,
    toggle: toggleSecure,
    on: activateSecure
  } = useSwitchValue(true);
  const {
    on: secureOnAnalytics,
    off: secureOffAnalytics,
    ...fieldAnalytics
  } = analytics || {};
  const toggleSecureAnalytics = isSecure ? secureOnAnalytics : secureOffAnalytics;
  useEffect(() => {
    if (resetFieldSecureValue !== prevResetValue) {
      activateSecure();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activateSecure, resetFieldSecureValue]);
  const formFieldClasses = {
    ...classes,
    button: cn(classes?.button, styles.button),
    componentWrapper: classes?.component
  };
  const handleToggleSecure = handleEventWithAnalytics(toggleSecure, toggleSecureAnalytics?.eventName, {
    ...(toggleSecureAnalytics?.data || {}),
    cls: styles.icon
  });
  return <FormFieldHiddenBase {...props} isSecure={isSecure} onEyeClick={handleToggleSecure} eyeIcon={<EyeIcon className={styles.icon} />} classes={formFieldClasses} className={cn(styles.field, props.className, !isSecure && styles.unsecure)} component={FormField} as="input" prefix={<LockIcon className={cn(styles.icon, props.isError && styles.hasError)} />} autoComplete={autoComplete} analytics={fieldAnalytics} data-sentry-element="FormFieldHiddenBase" data-sentry-component="BaseFormFieldHidden" data-sentry-source-file="FormFieldHidden.tsx" />;
};
const FormFieldHidden = memo(BaseFormFieldHidden);
export { FormFieldHidden };