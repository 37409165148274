import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { selectGameMode, selectIsErrorPage, useQueryPopupOpen } from '@mwl/core-lib';
import type { ButtonAsLink } from '@/components/Button/Button';
import { Button } from '@/components/Button/Button';
import { routes } from '@/constants/routes';
import { useMediaWidthDown, useTypedSelector } from '@/hooks';
import { headerObject } from '@/utils';
import styles from './SignInButton.module.scss';
const SignInButton: FC = () => {
  const {
    t
  } = useTranslation();
  const {
    createLinkProps
  } = useQueryPopupOpen();
  const is850Screen = useMediaWidthDown('screen850');
  const isErrorPage = useTypedSelector(selectIsErrorPage);
  const gameMode = useTypedSelector(selectGameMode);
  const loginProps = useMemo<Omit<ButtonAsLink, 'children' | 'as'>>(() => {
    if (isErrorPage) {
      return createLinkProps({
        queryName: 'login',
        pathname: routes.home
      });
    }
    return createLinkProps({
      queryName: 'login'
    });
  }, [isErrorPage, createLinkProps]);
  const registerProps = useMemo<Omit<ButtonAsLink, 'children' | 'as'>>(() => {
    if (isErrorPage) {
      return createLinkProps({
        queryName: 'register',
        pathname: routes.home
      });
    }
    return createLinkProps({
      queryName: 'register'
    });
  }, [isErrorPage, createLinkProps]);
  return <>
      {!is850Screen && <Button {...headerObject.account.signInButton.nodeProps} variant="fulfilled" {...loginProps} as="link" color="white" fontWeight="bold" className={cn(styles.signButton, gameMode && styles.gameMode)} {...headerObject.account.signInButton.nodeProps}>
          <div className={styles.text}>{t('button.signin', 'sign in')}</div>
        </Button>}
      <Button {...headerObject.account.signUpButton.nodeProps} variant="fulfilled" {...registerProps} color="red" as="link" fontWeight="bold" rounded className={cn(styles.signButton, gameMode && styles.gameMode)} {...headerObject.account.signUpButton.nodeProps} data-sentry-element="Button" data-sentry-source-file="SignInButton.tsx">
        <div className={styles.text}>{t('button.signup', 'sign up')}</div>
      </Button>
    </>;
};
export { SignInButton };