import type { FooterAnalytics } from './Footer.types';

export const analytics: FooterAnalytics = {
  download: {
    android: {
      click: {
        eventName: 'footer_downloadApp_android_icon_click',
      },
    },
    ios: {
      click: {
        eventName: 'footer_downloadApp_ios_icon_click',
      },
    },
  },
  providers: {
    click: {
      eventName: 'footer_providers_link_click',
    },
  },
};
