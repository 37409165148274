import type { FC } from 'react';
import { memo, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { useQueryPopupOpen } from '@mwl/core-lib';
import { Button } from '@/components/Button/Button';
import type { ButtonHaveAccountProps } from './ButtonHaveAccount.types';
const BaseButtonHaveAccount: FC<ButtonHaveAccountProps> = ({
  className
}) => {
  const {
    t
  } = useTranslation('common');
  const {
    createLinkProps
  } = useQueryPopupOpen();
  const linkProps = useMemo(() => createLinkProps({
    queryName: 'login'
  }), [createLinkProps]);
  return <Button as="link" variant="fulfilled" size="md" fontSize="xl" fontWeight="bold" fullWidth rounded className={cn(className)} {...linkProps} data-sentry-element="Button" data-sentry-component="BaseButtonHaveAccount" data-sentry-source-file="ButtonHaveAccount.tsx">
      {t('auth.have_account', 'I have an account')}
    </Button>;
};
const ButtonHaveAccount = memo(BaseButtonHaveAccount);
export * from './ButtonHaveAccount.types';
export { ButtonHaveAccount };