import type { RegistrationTabsContent } from './FormRegistration.types';
export const tabsContent: RegistrationTabsContent = {
  extended: {
    label: ['auth.extended', 'Extended'],
    isVisible: (variant, isFullRegistration) => variant === 'default' && isFullRegistration
  },
  phone: {
    label: ['auth.telephone', 'Telephone']
  },
  email: {
    label: ['auth.email', 'E-mail']
  },
  social: {
    label: ['auth.social_network', 'Socials']
  }
};