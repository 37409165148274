import type { Country } from '@mwl/core-lib';
import { baseFilterPredicate, removeAllSpaces } from '@mwl/core-lib';
import { FlagIcon } from '@mwl/ui';
import type { RegistrationPhoneOption } from '@/types';
import type { SelectProps } from '../Select/Select';
import type { FormFieldPhoneOption } from './FormFieldPhone.types';
export const filterItem: SelectProps<FormFieldPhoneOption>['filter'] = (search: string) => {
  const filter = search.replace(/[^0-9]/g, '');
  return baseFilterPredicate(filter);
};
export const getFieldFormat = (value: string, replacement = '#'): string | undefined => {
  if (!value) {
    return undefined;
  }
  return value.replace(/\S/gi, replacement);
};
export const createFieldMask = (value: string, replacement = '#') => {
  return removeAllSpaces(getFieldFormat(value, replacement) || '').split('');
};
export const formatCountriesToPhoneOptions = <T extends keyof Country,>(countries: Array<Country>, ...names: Array<T>): Array<RegistrationPhoneOption> => {
  return countries.map(country => {
    const {
      phone_mask,
      phone_prefix,
      fullImage,
      iso_alpha2_code
    } = country;
    const result = ({} as Country);
    if (names?.length) {
      names.forEach(name => {
        result[name] = (country[name] as Country[T]);
      });
    }
    return {
      ...result,
      label: phone_prefix,
      value: phone_prefix,
      format: phone_mask,
      country: iso_alpha2_code,
      prefix: <FlagIcon image={fullImage} size="sm" />
    };
  });
};