import type { FC } from 'react';
import { memo } from 'react';
import type { FiltersRequest } from '@mwl/core-lib';
import { noop, selectUserCurrency, useLoadableItemsV3 } from '@mwl/core-lib';
import { SectionGames } from '@/components/SectionGames/SectionGames';
import { GAMES_SECTION_LOAD_SIZE } from '@/constants';
import { useTypedSelector } from '@/hooks';
import type { GameCardProps } from '../GameCard/GameCard.types';
import type { SectionGamesLoadableProps } from './SectionGamesLoadable.types';
const BaseSectionGamesLoadable: FC<SectionGamesLoadableProps> = ({
  loadGames,
  isInfinite,
  resetParams,
  onLoadFinished,
  emptyContent = null,
  totalElements,
  isShowItemsCount,
  initialData,
  itemsLoadSize = GAMES_SECTION_LOAD_SIZE,
  ...props
}) => {
  const currency = useTypedSelector(selectUserCurrency);
  const {
    isError,
    hasMore,
    itemsLength,
    loadedPageNum,
    loadMoreHandler,
    totalElements: totalGamesCount,
    displayItems: displayGames
  } = useLoadableItemsV3<GameCardProps>({
    currency,
    addRowsCount: isInfinite ? 0 : 2,
    resetParams: (resetParams as FiltersRequest),
    onLoadFinished: onLoadFinished ?? noop,
    loadItems: loadGames,
    itemsLoadSize,
    columnsCount: 0,
    initialData
  });
  if (!itemsLength) {
    return <>{emptyContent}</>;
  }
  const canHasMore = totalElements ? displayGames.length < totalElements : hasMore;
  return <SectionGames games={displayGames} onLoad={loadMoreHandler} totalGamesCount={isShowItemsCount ? totalGamesCount : undefined} hasMore={canHasMore} isInfinite={isInfinite && !isError} loadedPageNum={loadedPageNum} {...props} data-sentry-element="SectionGames" data-sentry-component="BaseSectionGamesLoadable" data-sentry-source-file="SectionGamesLoadable.tsx" />;
};
export * from './SectionGamesLoadable.types';
export const SectionGamesLoadable = memo(BaseSectionGamesLoadable);