import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import { Button } from '@/components/Button/Button';
import { Checkbox } from '@/components/Checkbox/Checkbox';
import { FormMessage } from '@/components/FormMessage/FormMessage';
import { Loader } from '@/components/Loader/Loader';
import { useHandleChange, useThirdStepRegistrationForm } from './ThirdStepRegistration.hooks';
import type { ThirdStepRegistrationProps } from './ThirdStepRegistration.types';
import styles from './ThirdStepRegistration.module.scss';
const ThirdStepRegistration: FC<ThirdStepRegistrationProps> = ({
  className,
  formData
}) => {
  const {
    t
  } = useTranslation('common');
  const [checkedAll, setCheckedAll] = useState(false);
  const {
    changeField,
    formState,
    isLoading,
    error,
    handleSubmit
  } = useThirdStepRegistrationForm({
    formData
  });
  const {
    handleChange,
    handleChangeAll
  } = useHandleChange({
    changeField,
    formState
  });
  useEffect(() => {
    setCheckedAll(Object.values(formState.values).every(Boolean));
  }, [formState.values]);
  return <>
      {error && error.type !== '/phone-already-exists' && error.type !== '/email-already-exists' && <FormMessage className={styles.message}>
          {Array.isArray(error.message) ? t(error.message) : error.message}
        </FormMessage>}
      <form className={cn(styles.root, className)} onSubmit={handleSubmit}>
        {isLoading && <Loader />}
        <div className={styles.row}>
          <Checkbox name="age" checked={formState.values.age} classes={{
          box: styles.checkboxBox
        }} onChange={handleChange} className={cn(styles.column, styles.checkbox)} variant={formState.isTouched && formState.errors?.age ? 'red' : 'default'} data-sentry-element="Checkbox" data-sentry-source-file="ThirdStepRegistration.tsx">
            <Trans i18nKey="common:register.full.confirm_1" data-sentry-element="Trans" data-sentry-source-file="ThirdStepRegistration.tsx">I am 18 years or older</Trans>
          </Checkbox>
          <Checkbox name="playingForYourOwnMoney" checked={formState.values.playingForYourOwnMoney} classes={{
          box: styles.checkboxBox
        }} onChange={handleChange} className={cn(styles.column, styles.checkbox)} variant={formState.isTouched && formState.errors?.playingForYourOwnMoney ? 'red' : 'default'} data-sentry-element="Checkbox" data-sentry-source-file="ThirdStepRegistration.tsx">
            <Trans i18nKey="common:register.full.confirm_2" data-sentry-element="Trans" data-sentry-source-file="ThirdStepRegistration.tsx">I am playing for myself and with my own money</Trans>
          </Checkbox>
        </div>
        <div className={styles.row}>
          <Checkbox name="firstAndOnlyRegistration" classes={{
          box: styles.checkboxBox
        }} checked={formState.values.firstAndOnlyRegistration} onChange={handleChange} className={cn(styles.column, styles.checkbox)} variant={formState.isTouched && formState.errors?.firstAndOnlyRegistration ? 'red' : 'default'} data-sentry-element="Checkbox" data-sentry-source-file="ThirdStepRegistration.tsx">
            <Trans i18nKey="common:register.full.confirm_3" data-sentry-element="Trans" data-sentry-source-file="ThirdStepRegistration.tsx">This is my first and only registration on</Trans>{' '}
            <a className={styles.checkboxLink} href="https://banzai.bet/rules">
              banzai.bet
            </a>
          </Checkbox>
          <Checkbox name="termsAndConditions" classes={{
          box: styles.checkboxBox
        }} checked={formState.values.termsAndConditions} onChange={handleChange} className={cn(styles.column, styles.checkbox)} variant={formState.isTouched && formState.errors?.termsAndConditions ? 'red' : 'default'} data-sentry-element="Checkbox" data-sentry-source-file="ThirdStepRegistration.tsx">
            <Trans i18nKey="common:register.full.confirm_4" data-sentry-element="Trans" data-sentry-source-file="ThirdStepRegistration.tsx">
              I have read and accept the{' '}
              <a className={styles.checkboxLink} href="https://banzai.bet/rules">
                terms and conditions
              </a>
            </Trans>
          </Checkbox>
        </div>
        <div className={styles.row}>
          <Checkbox name="gamblingWebsite" classes={{
          box: styles.checkboxBox
        }} checked={formState.values.gamblingWebsite} onChange={handleChange} className={styles.checkbox} variant={formState.isTouched && formState.errors?.gamblingWebsite ? 'red' : 'default'} data-sentry-element="Checkbox" data-sentry-source-file="ThirdStepRegistration.tsx">
            <Trans i18nKey="common:register.full.confirm_5" data-sentry-element="Trans" data-sentry-source-file="ThirdStepRegistration.tsx">
              I have not self-excluded from any gambling website in the past 12 months
            </Trans>
          </Checkbox>
        </div>
        <div className={styles.row}>
          <Checkbox name="declareInformation" classes={{
          box: styles.checkboxBox
        }} checked={formState.values.declareInformation} onChange={handleChange} className={styles.checkbox} variant={formState.isTouched && formState.errors?.declareInformation ? 'red' : 'default'} data-sentry-element="Checkbox" data-sentry-source-file="ThirdStepRegistration.tsx">
            <Trans i18nKey="common:register.full.confirm_6" data-sentry-element="Trans" data-sentry-source-file="ThirdStepRegistration.tsx">
              I declare that all information provided by me to{' '}
              <a className={styles.checkboxLink} href="https://banzai.bet/rules">
                banzai.bet
              </a>{' '}
              is correct and can be verified and I understand that providing any form of incorrect information will void
              all winnings of my plays on{' '}
              <a className={styles.checkboxLink} href="https://banzai.bet/rules">
                banzai.bet
              </a>
            </Trans>
          </Checkbox>
        </div>
        <div className={styles.row}>
          <Checkbox name="awareLimitations" classes={{
          box: styles.checkboxBox
        }} checked={formState.values.awareLimitations} onChange={handleChange} className={styles.checkbox} variant={formState.isTouched && formState.errors?.awareLimitations ? 'red' : 'default'} data-sentry-element="Checkbox" data-sentry-source-file="ThirdStepRegistration.tsx">
            <Trans i18nKey="common:register.full.confirm_7" data-sentry-element="Trans" data-sentry-source-file="ThirdStepRegistration.tsx">
              I am aware and acknowledge that{' '}
              <a className={styles.checkboxLink} href="https://banzai.bet/rules">
                banzai.bet
              </a>{' '}
              sets certain limitations to the maximum prizes that can be won per day/week/month and/or can be withdrawn
              per day/week/month
            </Trans>
          </Checkbox>
        </div>
        <div className={styles.row}>
          <Checkbox name="confirm-all" classes={{
          box: styles.checkboxBox
        }} checked={checkedAll} onChange={handleChangeAll} className={cn(styles.checkbox, styles.checkboxAll)} variant="default" data-sentry-element="Checkbox" data-sentry-source-file="ThirdStepRegistration.tsx">
            <Trans i18nKey="common:register.full.confirm_all" data-sentry-element="Trans" data-sentry-source-file="ThirdStepRegistration.tsx">confirm all</Trans>
          </Checkbox>
        </div>

        <div className={styles.buttonContainer}>
          <Button as="button" variant="fulfilled" color="yellow" fontSize="xl" fontWeight="bold" size="md" fullWidth className={styles.column} type="submit" data-sentry-element="Button" data-sentry-source-file="ThirdStepRegistration.tsx">
            {t('auth.finish', 'Finish')}
          </Button>
        </div>
      </form>
    </>;
};
export { ThirdStepRegistration };