import type { FC } from 'react';
import { memo, useMemo } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import { useQueryPopupOpen } from '@mwl/core-lib';
import { Button } from '@/components/Button/Button';
import styles from './NotRecognize.module.scss';
const BaseNotRecognize: FC = () => {
  const {
    t
  } = useTranslation('common');
  const {
    createLinkProps
  } = useQueryPopupOpen();
  const registerLinkProps = useMemo(() => createLinkProps({
    queryName: 'register'
  }), [createLinkProps]);
  const loginLinkProps = useMemo(() => createLinkProps({
    queryName: 'login'
  }), [createLinkProps]);
  return <div className={styles.root} data-sentry-component="BaseNotRecognize" data-sentry-source-file="NotRecognize.tsx">
      <div className={styles.icon} />
      <h3 className={styles.header}>{t('not_recognize.title', `We didn't recognize you`)}</h3>
      <p className={styles.text}>
        <Trans ns="common" i18nKey="not_recognize.text" defaults="To play for real money, you need <br/> to log in or register" data-sentry-element="Trans" data-sentry-source-file="NotRecognize.tsx" />
      </p>

      <div className={styles.buttonContainer}>
        <Button as="link" variant="fulfilled" size="md" color="red" fontSize="lg" fontWeight="bold" fullWidth className={styles.button} rounded {...registerLinkProps} data-sentry-element="Button" data-sentry-source-file="NotRecognize.tsx">
          {t('not_recognize.register', 'Sign Up')}
        </Button>
        <Button as="link" variant="fulfilled" size="md" fontSize="lg" fontWeight="bold" fullWidth color="white" className={styles.button} rounded {...loginLinkProps} data-sentry-element="Button" data-sentry-source-file="NotRecognize.tsx">
          {t('not_recognize.login', `Sign In`)}
        </Button>
      </div>
    </div>;
};
const NotRecognize = memo(BaseNotRecognize);
export { NotRecognize };