import type { FC } from 'react';
import { extendAnalyticsData, getProviderUrl } from '@mwl/core-lib';
import { Image } from '@/components/Image/Image';
import { routes } from '@/constants';
import { providersPageObject } from '@/utils';
import { Button } from '../Button/Button';
import type { ProviderCardProps } from './ProviderCard.types';
import styles from './ProviderCard.module.scss';
const ProviderCard: FC<ProviderCardProps> = ({
  title,
  banner,
  width = 'fix',
  style,
  className,
  analytics
}) => {
  const providerLink = getProviderUrl({
    providerTitle: title,
    providerPathname: routes.providers.provider
  });
  return <Button {...providersPageObject.card.nodeProps} as="link" variant="text" href={providerLink} className={cn(styles.root, className, {
    [styles.fix]: width === 'fix',
    [styles.full]: width === 'full'
  })} style={style} analytics={extendAnalyticsData(analytics, {
    href: providerLink
  })} data-sentry-element="Button" data-sentry-component="ProviderCard" data-sentry-source-file="ProviderCard.tsx">
      <div className={styles.contentWrapper}>
        <div className={styles.arrow} />
        {banner && <Image src={banner} width={350} alt={title} fill />}
      </div>
    </Button>;
};
export { ProviderCard };