import type { FC } from 'react';
import type { OutcomeButtonBaseOldClasses, OutcomeButtonBaseOldProps } from '@mwl/ui';
import { OutcomeButtonBaseOld } from '@mwl/ui';
import styles from './OutcomeButton.module.scss';
const defaultClasses: OutcomeButtonBaseOldClasses = {
  frozen: styles.frozen,
  empty: styles.empty,
  title: styles.title,
  value: styles.value,
  active: styles.active,
  decrease: styles.decrease,
  increase: styles.increase,
  vertical: styles.vertical,
  horizontal: styles.horizontal
};
const OutcomeButton: FC<OutcomeButtonBaseOldProps> = ({
  className,
  classes,
  ...props
}) => {
  return <OutcomeButtonBaseOld {...props} className={cn(styles.root, className)} classes={{
    frozen: cn(defaultClasses.frozen, classes?.frozen),
    empty: cn(defaultClasses.empty, classes?.empty),
    title: cn(defaultClasses.title, classes?.title),
    value: cn(defaultClasses.value, classes?.value),
    active: cn(defaultClasses.active, classes?.active),
    decrease: cn(defaultClasses.decrease, classes?.decrease),
    increase: cn(defaultClasses.increase, classes?.increase),
    vertical: cn(defaultClasses.vertical, classes?.vertical),
    horizontal: cn(defaultClasses.horizontal, classes?.horizontal)
  }} data-sentry-element="OutcomeButtonBaseOld" data-sentry-component="OutcomeButton" data-sentry-source-file="OutcomeButton.tsx" />;
};
export { OutcomeButton };