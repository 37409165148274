import type { FC } from 'react';
import { Button } from '../Button/Button';
import type { SportButtonProps } from './SportButton.types';
import styles from './SportButton.module.scss';
const SportButton: FC<SportButtonProps> = ({
  className,
  withoutTextShadow = true,
  ...rest
}) => {
  return <Button className={cn(styles.root, className, withoutTextShadow && styles.withoutTextShadow)} {...rest} data-sentry-element="Button" data-sentry-component="SportButton" data-sentry-source-file="SportButton.tsx" />;
};
export { SportButton };