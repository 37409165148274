import type { FC } from 'react';
import { useMemo } from 'react';
import { useMediaWidthDown } from '@/hooks';
import { Carousel } from '../Carousel/Carousel';
import { analytics } from './SliderBanner.analytics';
import type { SliderBannerProps } from './SliderBanner.types';
const SliderBanner: FC<SliderBannerProps> = ({
  banners,
  testMarker
}) => {
  const isSmallScreen = useMediaWidthDown('screenSm');
  const sliderOptions = useMemo(() => banners?.[isSmallScreen ? 'mobile' : 'desktop'] || [], [banners, isSmallScreen]);
  return <Carousel {...testMarker?.nodeProps} options={sliderOptions} slideTestMarker={testMarker?.slide} autoSlideDisableOnHover analytics={analytics} data-sentry-element="Carousel" data-sentry-component="SliderBanner" data-sentry-source-file="SliderBanner.tsx" />;
};
export { SliderBanner };