import { forwardRef, memo } from 'react';
import { getTestProps, handleEventWithAnalytics } from '@mwl/core-lib';
import { PaymentCardBase } from '@mwl/ui';
import type { PaymentCardProps } from './PaymentCard.types';
import styles from './PaymentCard.module.scss';
const BasePaymentCard = forwardRef<HTMLButtonElement, PaymentCardProps>(({
  className,
  classes,
  identity,
  image,
  footer,
  discount,
  active = false,
  onClick,
  analytics,
  ...props
}, ref) => {
  const paymentCardClassName = cn(styles.root, className, {
    [styles.active]: active
  }, active && classes?.active);
  const handleClick = () => onClick?.(identity);
  return <PaymentCardBase ref={ref} {...getTestProps(props)} className={paymentCardClassName} classes={{
    image: cn(styles.image, classes?.image),
    footer: cn(styles.text, classes?.text, styles.footer),
    header: styles.discount
  }} onClick={handleEventWithAnalytics(handleClick, analytics?.click?.eventName, {
    ...(analytics?.click?.data || {}),
    cls: paymentCardClassName
  })} header={discount ? <span>{discount}%</span> : null} image={image} footer={footer} radius="lg" />;
});
export * from './PaymentCard.types';
const PaymentCard = memo(BasePaymentCard);
export { PaymentCard };