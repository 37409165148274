import type { FC } from 'react';
import { getTestProps } from '@mwl/core-lib';
import { PerfectScrollbar } from '@/components/PerfectScrollbar/PerfectScrollbar';
import { scrollbarOptions } from './DropdownContent.data';
import { useContentMaxHeight } from './DropdownContent.hooks';
import type { DropdownContentProps } from './DropdownContent.types';
import styles from './DropdownContent.module.scss';
const DropdownContent: FC<DropdownContentProps> = ({
  children,
  className,
  classes,
  maxDesktopHeight = 230,
  maxMobileHeight = 150,
  parentRef,
  htmlProps = {},
  ...props
}) => {
  const maxHeight = useContentMaxHeight({
    maxMobileHeight,
    maxDesktopHeight,
    parentRef
  });
  return <PerfectScrollbar isSwitchOffOnMobile={false} options={scrollbarOptions} className={cn(styles.root, className)} data-sentry-element="PerfectScrollbar" data-sentry-component="DropdownContent" data-sentry-source-file="DropdownContent.tsx">
      <div {...getTestProps(props)} className={cn(styles.content, classes?.content)} style={{
      maxHeight
    }} {...htmlProps}>
        {children}
      </div>
    </PerfectScrollbar>;
};
export { DropdownContent };