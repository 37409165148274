import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'next-i18next';
import type { DepositFormValues } from '@mwl/core-lib';
import { getDepositFormSettings, useDeposit, useForm, useSubmitDepositForm } from '@mwl/core-lib';
import ArrowIcon from '@public/assets/common/icons/arrowRightOutlined.svg';
import { Button } from '@/components/Button/Button';
import { Loader } from '@/components/Loader/Loader';
import { Switch } from '@/components/Switch/Switch';
import { PaymentFieldList } from '@/modules/payment/components/PaymentFieldList/PaymentFieldList';
import { PaymentFormFields } from '@/modules/payment/components/PaymentFormFields/PaymentFormFields';
import { PaymentFormTextFields } from '@/modules/payment/components/PaymentFormTextFields/PaymentFormTextFields';
import { usePaymentEditableFields } from '@/modules/payment/hooks/usePaymentEditableFields';
import type { PaymentFieldValue } from '@/modules/payment/types';
import { popupsObject } from '@/utils';
import { useDepositFormAmountData } from '../../hooks/useDepositFormAmountData/useDepositFormAmountData';
import { useUpdateDepositFormAmount } from '../../hooks/useUpdateDepositFormAmount/useUpdateDepositFormAmount';
import { BonusPacketSlider } from '../BonusPacketSlider/BonusPacketSlider';
import { useDepositFormAnalytics } from './DepositForm.analytics.hooks';
import type { DepositFormProps } from './DepositForm.types';
import styles from './DepositForm.module.scss';
export const DepositForm: FC<DepositFormProps> = ({
  analytics
}) => {
  const {
    t
  } = useTranslation('payment');
  const {
    payment,
    useBonus,
    bonuses,
    toggleUseBonus,
    changePayment,
    content
  } = useDeposit();
  const {
    changeField,
    formState,
    handleSubmit,
    resetForm
  } = useForm<DepositFormValues>(getDepositFormSettings({
    t,
    fields: payment?.fieldList
  }));
  const {
    editableFields,
    staticFields
  } = usePaymentEditableFields(payment);
  const {
    total,
    bonusAmount
  } = useDepositFormAmountData({
    value: formState.values.amount
  });
  const {
    state,
    onSubmitConfirm
  } = useSubmitDepositForm({
    formState,
    identity: payment?.identity ?? ''
  });
  const {
    fields: fieldsAnalytics,
    bonus: bonusAnalytics,
    submit: submitAnalytics
  } = useDepositFormAnalytics(analytics, formState, state);
  useUpdateDepositFormAmount({
    resetForm,
    min: payment?.min,
    values: formState.values
  });
  const handleClickBack = useCallback(() => {
    changePayment();
  }, [changePayment]);
  const handleChange = useCallback((name: keyof DepositFormValues) => (value: PaymentFieldValue) => changeField(name, value), [changeField]);
  if (!payment || content.form !== 'create') {
    return null;
  }
  const formFooter = <div className={styles.footer}>
      {formState.values.amount && <div className={styles.footerData}>
          {t('balance_after', 'Balance after deposit')}: <span className={styles.value}>{total}</span>
        </div>}
      {bonusAmount && <div className={styles.footerData}>
          {t('from_bonuses', 'Of which bonuses')}: <span className={styles.value}>{bonusAmount}</span>
        </div>}
      {payment.commission?.percent && <div className={styles.footerData}>
          {t('commission', 'Commission')}: <span className={styles.value}>{payment.commission?.percent}%</span>
        </div>}
    </div>;
  return <>
      <Button className={styles.backButton} as="button" variant="text" onClick={handleClickBack} align="left" fontWeight="bold" color="white" data-sentry-element="Button" data-sentry-source-file="DepositForm.tsx">
        <ArrowIcon className={styles.backIcon} data-sentry-element="ArrowIcon" data-sentry-source-file="DepositForm.tsx" />
        {t('back.button_refill', 'Back')}
      </Button>
      <form {...popupsObject.deposit.payment.formWrapper(payment.identity).nodeProps} onSubmit={handleSubmit(onSubmitConfirm)} className={styles.form}>
        {state.isLoading && <Loader />}

        <PaymentFormTextFields formState={formState} fields={staticFields} data-sentry-element="PaymentFormTextFields" data-sentry-source-file="DepositForm.tsx" />

        {!!bonuses.length && <div className={styles.useBonus}>
            {t('use_bonuses', 'Use bonus?')}
            <Switch {...popupsObject.deposit.payment.useBonusPacketsSwitch.nodeProps} active={useBonus} onChange={toggleUseBonus} analytics={bonusAnalytics?.switch} />
          </div>}

        {useBonus && !!bonuses.length && <BonusPacketSlider {...popupsObject.deposit.payment.bonusPacketsSlider.nodeProps} className={styles.slider} amount={formState.values.amount ? Number(formState.values.amount) : 0} analytics={bonusAnalytics?.slider} />}

        <PaymentFormFields variant="deposit" amount={formState.values.amount} method={payment} footer={formFooter} isLoading={state.isLoading} analytics={submitAnalytics} data-sentry-element="PaymentFormFields" data-sentry-source-file="DepositForm.tsx">
          <PaymentFieldList className={styles.field} groups={payment.groupList} fields={editableFields} formState={formState} commission={payment.commission} onChange={handleChange} analytics={fieldsAnalytics} data-sentry-element="PaymentFieldList" data-sentry-source-file="DepositForm.tsx" />
        </PaymentFormFields>
      </form>
    </>;
};