import type { SelectOption } from './NativeSelect.types';
const checkValuesEqual = (option: SelectOption, value: SelectOption, index?: number) => {
  const isEqWhenString = option.value === value.value;
  const isEqWhenNumber = typeof option.value === 'number' && option.value === +value.value;
  const isIndexEq = typeof index !== 'undefined' ? index === value.index : true;
  return (isEqWhenNumber || isEqWhenString) && isIndexEq;
};
export const optionToValue = (option?: Partial<SelectOption>) => {
  if (!option) {
    return undefined;
  }
  return JSON.stringify({
    value: option.value,
    label: option.label,
    index: option.index
  });
};
export const valueFromOption = <T extends SelectOption,>(value: string): T | undefined => {
  try {
    return JSON.parse(value);
  } catch {
    return undefined;
  }
};
interface FindValueParams<T extends SelectOption> {
  options: Array<T>;
  value?: T;
}
export const findValue = <T extends SelectOption,>({
  options,
  value
}: FindValueParams<T>) => {
  if (!value) {
    return undefined;
  }
  return options.find((option, index) => checkValuesEqual(option, value, index));
};