import type { FC } from 'react';
import { useCallback } from 'react';
import { useDeposit, useQueryPopup } from '@mwl/core-lib';
import { ModalMessage } from '@/components/ModalMessage/ModalMessage';
import { PopupDeclineStatus } from '../PopupDeclineStatus/PopupDeclineStatus';
import { PopupPaymentStatus } from '../PopupPaymentStatus/PopupPaymentStatus';
import type { DepositModalsProps } from './DepositModals.types';
import styles from './DepositModals.module.scss';
const popupClasses = {
  wrapper: styles.popupWrapper
};
export const DepositModals: FC<DepositModalsProps> = ({
  analytics
}) => {
  const {
    content,
    changeContent
  } = useDeposit();
  const {
    onClose
  } = useQueryPopup();
  const resetContent = useCallback(() => {
    changeContent({
      form: 'create'
    });
  }, [changeContent]);
  const closeOnSuccess = () => onClose();
  return <>
      <ModalMessage popupClasses={popupClasses} show={content.form === 'error'} status="error" onClose={resetContent} title={content.title} content={content.message} buttonText={content.buttonText} analytics={analytics?.error} data-sentry-element="ModalMessage" data-sentry-source-file="DepositModals.tsx" />
      <ModalMessage popupClasses={popupClasses} show={content.form === 'success'} status="success" onClose={closeOnSuccess} title={content.title} content={content.message} buttonText={content.buttonText} analytics={analytics?.success} data-sentry-element="ModalMessage" data-sentry-source-file="DepositModals.tsx" />
      <PopupPaymentStatus data-sentry-element="PopupPaymentStatus" data-sentry-source-file="DepositModals.tsx" />
      <PopupDeclineStatus data-sentry-element="PopupDeclineStatus" data-sentry-source-file="DepositModals.tsx" />
    </>;
};