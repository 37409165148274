import type { FC } from 'react';
import { competitorLeadSideIcons } from './SportCompetitorLeadSideIcon.constants';
import type { SportCompetitorLeadSideIconProps } from './SportCompetitorLeadSideIcon.types';
import { getCompetitorLeadSide } from './SportCompetitorLeadSideIcon.utils';
import styles from './SportCompetitorLeadSideIcon.module.scss';
export const SportCompetitorLeadSideIcon: FC<SportCompetitorLeadSideIconProps> = ({
  className,
  side,
  line
}) => {
  const {
    sport
  } = line;
  const sportCode = sport.code;
  const CompetitorLeadSideIcon = sportCode && competitorLeadSideIcons[sportCode];
  const competitorLeadSide = getCompetitorLeadSide(line);
  const isShowIcon = CompetitorLeadSideIcon && side === competitorLeadSide;
  return <span className={cn(styles.root, className)} data-sentry-component="SportCompetitorLeadSideIcon" data-sentry-source-file="SportCompetitorLeadSideIcon.tsx">
      {isShowIcon && <CompetitorLeadSideIcon className={styles.icon} />}
    </span>;
};