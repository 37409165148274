import { useCallback } from 'react';
import { requestConfirmationCode, useSubmitForm } from '@mwl/core-lib';
import { useRecoveryState } from '../../FormRecovery.context';
import type { FormRecoveryCodeValues } from './FormRecoveryCode.types';
export const useSendCode = (login: string, values: FormRecoveryCodeValues) => {
  const {
    onChangeState
  } = useRecoveryState();
  const {
    state,
    onSubmitConfirm
  } = useSubmitForm({
    requestHandler: requestConfirmationCode,
    formState: values,
    onSuccess: data => {
      if (data) {
        onChangeState({
          timer: data.timer
        });
      }
    }
  });
  const requestCode = useCallback(() => {
    onSubmitConfirm({
      confirmationSubject: login
    });
  }, [onSubmitConfirm, login]);
  return {
    ...state,
    requestCode
  };
};