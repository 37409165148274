import type { FC } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import { Button } from '@/components/Button/Button';
import { Timer } from '../Timer/Timer';
import type { FormTimerErrorProps } from './FormTimerError.types';
import styles from './FormTimerError.module.scss';
export const FormTimerError: FC<FormTimerErrorProps> = ({
  timer,
  onComplete,
  onCancel
}) => {
  const {
    t
  } = useTranslation(['payment', 'common']);
  const hasTimer = !!timer && +timer > 0;
  return <div className={styles.root} data-sentry-component="FormTimerError" data-sentry-source-file="FormTimerError.tsx">
      {hasTimer && <p className={styles.errorMessage}>
          <Trans i18nKey="error.code_limit_timer" components={[<Timer date={timer} onComplete={onComplete} className={styles.timer} key="error.code_limit_timer" />]} ns="payment">
            {`Attempts to send the code have been exhausted. The following code can be requested in <0></0>`}
          </Trans>
        </p>}
      {!hasTimer && <p className={styles.errorMessage}>
          {t('confirmation_error.code_limit_payment', 'Exceeded allowed number of withdrawal attempts')}
        </p>}

      <Button className={styles.button} as="button" type="button" onClick={onCancel} fontSize="xl" variant="fulfilled" color="red" size="md" fullWidth rounded data-sentry-element="Button" data-sentry-source-file="FormTimerError.tsx">
        {t('payment:need_confirmation.cancel_payout', `Cancel Payout`)}
      </Button>
    </div>;
};