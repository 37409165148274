import type { FC } from 'react';
import { memo } from 'react';
import { useTranslation } from 'next-i18next';
import ArrowIcon from '@public/assets/common/icons/arrowRightOutlined.svg';
import { Button } from '@/components/Button/Button';
import type { PaymentFormHeaderProps } from './PaymentFormHeader.types';
import styles from './PaymentFormHeader.module.scss';
const BasePaymentFormHeader: FC<PaymentFormHeaderProps> = ({
  className,
  onClose
}) => {
  const {
    t
  } = useTranslation('payment');
  return <div className={cn(styles.root, className)} data-sentry-component="BasePaymentFormHeader" data-sentry-source-file="PaymentFormHeader.tsx">
      <Button as="button" variant="text" onClick={onClose} align="left" fontWeight="bold" color="white" data-sentry-element="Button" data-sentry-source-file="PaymentFormHeader.tsx">
        <ArrowIcon className={styles.backIcon} data-sentry-element="ArrowIcon" data-sentry-source-file="PaymentFormHeader.tsx" />
        {t('deposit_button_back', 'Back')}
      </Button>
    </div>;
};
export const PaymentFormHeader = memo(BasePaymentFormHeader);