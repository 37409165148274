import type { FC } from 'react';
import { memo, useCallback, useState } from 'react';
import { handleEventWithAnalytics } from '@mwl/core-lib';
import { Accordion as MwlAccordion, AccordionContent, AccordionControl } from '@mwl/ui';
import DefaultArrowIcon from '@public/assets/common/icons/arrow.svg';
import type { AccordionProps } from './Accordion.types';
import styles from './Accordion.module.scss';
const BaseAccordion: FC<AccordionProps> = ({
  ArrowIcon,
  className,
  classes,
  title,
  titleUppercase = true,
  opened = false,
  analytics,
  children,
  onOpen,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(opened);
  const handleClick = useCallback(() => {
    setIsOpen(open => !open);
    onOpen?.(isOpen);
  }, [isOpen, onOpen]);
  const analyticsEvent = isOpen ? analytics?.close : analytics?.open;
  const analyticsClassName: string | undefined = analyticsEvent?.data?.cls;
  const Icon = ArrowIcon ?? DefaultArrowIcon;
  return <MwlAccordion expanded={isOpen} className={cn(styles.root, className)} chevron={<Icon className={styles.icon} />} {...rest} data-sentry-element="MwlAccordion" data-sentry-component="BaseAccordion" data-sentry-source-file="Accordion.tsx">
      <AccordionControl className={cn(analyticsClassName, classes?.button)} classes={{
      chevron: cn(classes?.iconContainer)
    }} onClick={handleEventWithAnalytics(handleClick, analyticsEvent?.eventName, analyticsEvent?.data)} data-sentry-element="AccordionControl" data-sentry-source-file="Accordion.tsx">
        <span className={cn(styles.text, {
        [styles.uppercase]: titleUppercase
      }, classes?.text)}>{title}</span>
      </AccordionControl>
      <AccordionContent className={classes?.content} data-sentry-element="AccordionContent" data-sentry-source-file="Accordion.tsx">{children}</AccordionContent>
    </MwlAccordion>;
};
const Accordion = memo(BaseAccordion);
export { Accordion };