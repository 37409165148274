import type { Analytics } from '@mwl/core-lib';

import { routes } from '@/constants';

import type { PopupPaymentAnalytics } from './PopupPayment.types';

export const getDepositAnalytics = (currency: string): PopupPaymentAnalytics => {
  const data = {
    currency,
    page_type: 'modal',
  };

  const firstStepData = {
    ...data,
    step: 1,
  };

  const secondStepData = {
    ...data,
    step: 2,
  };

  return {
    popup: {
      open: {
        eventName: 'deposit_open',
        data,
      },
      closeIconClick: {
        eventName: 'deposit_xIcon_close',
        data,
      },
    },
    content: {
      payment: {
        open: {
          eventName: 'deposit_paymentType_default_open',
          data,
        },
        click: {
          eventName: 'deposit_paymentType_open',
          data,
        },
      },
      form: {
        fields: {
          quickTips: {
            click: {
              eventName: 'deposit_amountBtn_click',
              data: firstStepData,
            },
          },
          amount: {
            change: {
              eventName: 'deposit_amount_input',
              data: firstStepData,
            },
          },
          submit: {
            error: {
              eventName: 'deposit_form_front_error',
              data: firstStepData,
            },
          },
        },
        bonus: {
          slider: {
            prev: {
              click: {
                eventName: 'deposit_bonusChousenBaner_slider_swipe',
                data: { ...firstStepData, position: 'left' },
              },
            },
            next: {
              click: {
                eventName: 'deposit_bonusChousenBaner_slider_swipe',
                data: { ...firstStepData, position: 'right' },
              },
            },
            info: {
              click: {
                eventName: 'deposit_bonusChousenBaner_iIcon_click',
                data: firstStepData,
              },
            },
            card: {
              click: {
                eventName: 'deposit_bonusChousenBaner_img_click',
                data: firstStepData,
              },
            },
          },
          switch: {
            on: {
              eventName: 'deposit_bonusActivate_tabSelect_on',
              data: firstStepData,
            },
            off: {
              eventName: 'deposit_bonusActivate_tabSelect_off',
              data: firstStepData,
            },
          },
        },
        submit: {
          click: {
            eventName: 'deposit_form_submit',
            data: firstStepData,
          },
          success: {
            eventName: 'deposit_form_success',
            data: firstStepData,
          },
          error: {
            eventName: 'deposit_form_back_error',
            data: firstStepData,
          },
        },
      },
      qrCode: {
        click: {
          eventName: 'deposit_walletNumber_copyIcon_click',
          data: secondStepData,
        },
      },
    },
    modals: {
      error: {
        open: {
          eventName: 'popup_paymentError_open',
        },
        outsideClick: {
          eventName: 'popup_paymentError_outsideClick_close',
        },
        closeIconClick: {
          eventName: 'popup_paymentError_xIcon_click',
        },
        click: {
          eventName: 'popup_paymentError_okBtn_click',
        },
      },
      success: {
        open: {
          eventName: 'popup_paymentSuccess_open',
        },
        outsideClick: {
          eventName: 'popup_paymentSuccess_outsideClick_close',
        },
        closeIconClick: {
          eventName: 'popup_paymentSuccess_xIcon_click',
        },
        click: {
          eventName: 'popup_paymentSuccess_okBtn_click',
        },
      },
    },
  };
};

export const getUserIsFrozenAnalytics = (auth_type: string): Analytics => {
  const data = { auth_type };
  return {
    open: {
      eventName: 'popup_userFrozen_open',
      data,
    },
    closeIconClick: {
      eventName: 'popup_userFrozen_xIcon_close',
      data,
    },
    outsideClick: {
      eventName: 'popup_userFrozen_outsideClick_close',
      data,
    },
    click: {
      eventName: 'popup_userFrozen_detailsBtn_click',
      data: {
        ...data,
        href: routes.faq,
      },
    },
  };
};
