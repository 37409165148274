import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { extendAnalyticsData, useCountryList } from '@mwl/core-lib';
import type { SelectOption } from '@mwl/ui';
import { Button } from '@/components/Button/Button';
import { Loader } from '@/components/Loader/Loader';
import { PaymentUploadField } from '@/modules/payment/components/PaymentUploadField/PaymentUploadField';
import type { PaymentFieldValue } from '../../types';
import { PaymentDateField } from '../PaymentDateField/PaymentDateField';
import { PaymentFieldPhone } from '../PaymentFieldPhone/PaymentFieldPhone';
import { PaymentQrCodeField } from '../PaymentQrCodeField/PaymentQrCodeField';
import { PaymentReadonlyField } from '../PaymentReadonlyField/PaymentReadonlyField';
import { PaymentSelect } from '../PaymentSelect/PaymentSelect';
import { PaymentSelectCountryCode } from '../PaymentSelectCountryCode/PaymentSelectCountryCode';
import { PaymentTextField } from '../PaymentTextField/PaymentTextField';
import type { PaymentFieldOnChange, PaymentFieldProps } from './PaymentField.types';
import styles from './PaymentField.module.scss';
export const PaymentField = <T extends PaymentFieldValue,>({
  className,
  type,
  parentRef,
  onChange,
  hidden = false,
  value,
  title,
  quickTipsHide,
  readOnly,
  onFocus,
  onBlur,
  captions,
  quickTips,
  commission,
  amountWithCommission,
  valueCountryCode,
  analytics,
  ...restProps
}: PaymentFieldProps<T>) => {
  const {
    t
  } = useTranslation('payment');
  const {
    allCountries: countries
  } = useCountryList();
  const amountAnalytics = restProps.name === 'amount' ? extendAnalyticsData(analytics?.amount, {
    amount: value
  }) : undefined;
  const getPhoneData = (phone: string, countryCode: string) => {
    const code = countryCode ? countries?.find(item => item.iso_alpha3_code === countryCode)?.phone_prefix : countries[0].phone_prefix;
    return {
      code: code || '',
      countryCode,
      number: phone ? phone.slice(code?.length) : ''
    };
  };
  const getField = () => {
    if (readOnly) {
      const textValue = type === 'select' ? (value as SelectOption).value : (value as string | null);
      return <PaymentReadonlyField {...restProps} className={className} title={title} value={textValue ? t(textValue) : ''} />;
    }
    switch (type) {
      case 'number':
      case 'text':
      case 'hidden':
        return <PaymentTextField {...restProps} title={title} value={(value as string)} onChange={(onChange as PaymentFieldOnChange<string>)} onFocus={onFocus} onBlur={onBlur} type={type} analytics={amountAnalytics} />;
      case 'phoneNumber':
        if (!countries.length) {
          return <div className={styles.loaderWrapper}>
              <Loader className={styles.loader} />
            </div>;
        }
        return <PaymentFieldPhone {...restProps} parentRef={parentRef} availableCountryCodes={restProps.availableCountryCodes} onChange={(onChange as PaymentFieldOnChange<PaymentFieldValue>)} initValue={getPhoneData((value as string), (valueCountryCode as string))} />;
      case 'select':
        return <PaymentSelect {...restProps} parentRef={parentRef} onChange={(onChange as PaymentFieldOnChange<PaymentFieldValue>)} value={(value as SelectOption)} title={title} options={restProps.options || []} />;
      case 'date':
        return <PaymentDateField {...restProps} title={title} value={(value as string)} onChange={(onChange as PaymentFieldOnChange<string>)} />;
      case 'phoneCountryCodeByAlpha3Code':
        return <PaymentSelectCountryCode {...restProps} parentRef={parentRef} onChange={(onChange as PaymentFieldOnChange<PaymentFieldValue>)} value={(value as SelectOption)} title={title} options={restProps.options || []} />;
      case 'qrCode':
        return <PaymentQrCodeField value={(value as string)} />;
      case 'presignedUrlUploadField':
        return <PaymentUploadField value={(value as string)} {...restProps} title={title} onChange={(onChange as PaymentFieldOnChange<string>)} />;
      default:
        return null;
    }
  };
  const aboveContent = useMemo(() => {
    const aboveKey = captions?.find(caption => caption.position === 'above')?.value ?? '';
    if (!commission) {
      return null;
    }
    return aboveKey ? <div className={cn(styles.caption, styles.above)}>
        {t(aboveKey, 'Commission for deposit will be {{commission}}%', {
        commission: commission.percent
      })}
      </div> : null;
  }, [captions, commission, t]);
  const belowContent = useMemo(() => {
    const belowKey = captions?.find(caption => caption.position === 'below')?.value ?? '';
    if (commission?.type !== 'INCLUDED') {
      return null;
    }
    return belowKey ? <div className={cn(styles.caption, styles.below)}>
        {t(belowKey, 'Balance after deposit will be {{balanceAfter}}', {
        balanceAfter: amountWithCommission
      })}
      </div> : null;
  }, [captions, amountWithCommission, commission, t]);
  return <div className={cn(styles.root, className, {
    [styles.hidden]: type === 'hidden' || hidden
  })} data-sentry-component="PaymentField" data-sentry-source-file="PaymentField.tsx">
      {aboveContent}
      {getField()}
      {belowContent}
      {!quickTipsHide && !!quickTips?.length && <div className={styles.quickTips}>
          {quickTips.map((quickTip, index) => <Button key={index + quickTip.key} as="button" type="button" className={cn(styles.quickItem, {
        [styles.active]: value === quickTip.value
      })} onClick={() => onChange((quickTip.value as T))} cut={false} rounded analytics={extendAnalyticsData(analytics?.quickTips, {
        amount: quickTip.value,
        text: quickTip.value
      })}>
              {quickTip.value}
            </Button>)}
        </div>}
    </div>;
};