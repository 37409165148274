import type { Analytics } from '@mwl/core-lib';

export const SEARCH_CLICK = 'casinoMenu_search_click';
export const SEARCH_REQUEST = 'casinoMenu_search_request';
export const SEARCH_CLEAR = 'casinoMenu_searchClear_click';

export const providerAnalytics: Analytics<'open' | 'close'> = {
  open: {
    eventName: 'casinoFilter_provider_open',
  },
  close: {
    eventName: 'casinoFilter_provider_close',
  },
};

export const genreAnalytics: Analytics<'open' | 'close'> = {
  open: {
    eventName: 'casinoFilter_genre_open',
  },
  close: {
    eventName: 'casinoFilter_genre_close',
  },
};

export const featureAnalytics: Analytics<'open' | 'close'> = {
  open: {
    eventName: 'casinoFilter_feature_open',
  },
  close: {
    eventName: 'casinoFilter_feature_close',
  },
};

export const showFiltersAnalytics: Analytics<'click'> = {
  click: {
    eventName: 'casinoFilter_dropdown_open',
  },
};

export const hideFiltersAnalytics: Analytics<'click'> = {
  click: {
    eventName: 'casinoFilter_dropdown_close',
  },
};
