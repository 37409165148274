import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { extendAnalyticsData, selectCasinoFavoritesList } from '@mwl/core-lib';

import { useTypedSelector } from '@/hooks';

import type { NavMenuItem } from '@/types';

import { isMenuItemActive } from '../CasinoLayout.utils';

interface UseMenuItemsProps {
  topItems: Array<NavMenuItem>;
  navItems: Array<NavMenuItem>;
}

/**
 * Хук для создания элементов меню.
 * @param topItems - Список элементов меню для верхнего уровня.
 * @param navItems - Список элементов меню для навигации.
 * @returns Объект с отфильтрованными элементами меню.
 */
export function useMenuItems({ topItems, navItems }: UseMenuItemsProps) {
  const { pathname, query } = useRouter();

  const favorites = useTypedSelector(selectCasinoFavoritesList);
  const favoritesCount = Object.values(favorites).filter(Boolean).length;

  return useMemo(() => {
    const processItems = (menuItems: Array<NavMenuItem>, startPosition: number = 0) =>
      menuItems.map((menuItem, index) => {
        const isActive = isMenuItemActive(menuItem.href, pathname, query);

        return {
          ...menuItem,
          isActive,
          count: menuItem.slug === 'favorite' && favoritesCount ? favoritesCount : undefined,
          analytics: extendAnalyticsData(menuItem.analytics, {
            position: startPosition + index + 1,
            count: topItems.length + navItems.length,
          }),
        };
      });

    return {
      topItems: processItems(topItems),
      navItems: processItems(navItems, topItems.length),
    };
  }, [favoritesCount, navItems, pathname, query, topItems]);
}
