import type { FC } from 'react';
import { useTranslation } from 'next-i18next';
import { useQueryPopup } from '@mwl/core-lib';
import { ModalMessage } from '@/components/ModalMessage/ModalMessage';
import { getPopupData } from './PopupPaymentStatus.utils';
export const PopupPaymentStatus: FC = () => {
  const {
    t
  } = useTranslation('common');
  const {
    show,
    value,
    onClose
  } = useQueryPopup('status');
  const data = getPopupData(value);
  const handleClose = () => onClose({
    closeOnlyCurrentPopup: true
  });
  return <ModalMessage show={show} onClose={handleClose} status={data?.status || 'default'} buttonText={data?.buttonText ? t(data.buttonText) : undefined} content={t(data?.message || '')} data-sentry-element="ModalMessage" data-sentry-component="PopupPaymentStatus" data-sentry-source-file="PopupPaymentStatus.tsx" />;
};