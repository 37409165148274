import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import type { PaymentMethodFieldOption } from '@mwl/core-lib';
import { useCountryList } from '@mwl/core-lib';
import type { SelectOption } from '@mwl/ui';
import { PaymentSelect } from '../PaymentSelect/PaymentSelect';
import type { PaymentSelectCountryCodeProps } from './PaymentSelectCountryCode.types';
export const PaymentSelectCountryCode: FC<PaymentSelectCountryCodeProps> = ({
  availableCountryCodes,
  value,
  onChange,
  ...props
}) => {
  const {
    countries
  } = useCountryList();
  const options = useMemo(() => {
    return countries.reduce<Array<PaymentMethodFieldOption>>((acc, country) => {
      const {
        phone_prefix,
        iso_alpha3_code
      } = country;
      if (!availableCountryCodes?.length || availableCountryCodes?.includes(iso_alpha3_code)) {
        acc.push({
          title: phone_prefix,
          level: '',
          value: phone_prefix
        });
      }
      return acc;
    }, []);
  }, [availableCountryCodes, countries]);
  const selected = useMemo(() => {
    if (typeof value === 'string') {
      const selectedCountry = countries.find(option => option.iso_alpha3_code === value);
      return selectedCountry ? {
        value: selectedCountry.phone_prefix,
        label: selectedCountry.phone_prefix
      } : value;
    }
    return value;
  }, [countries, value]);
  useEffect(() => {
    if (typeof value === 'string' && selected && typeof selected === 'object') {
      onChange?.(selected);
    }
  }, [onChange, selected, value]);
  return <PaymentSelect {...props} onChange={onChange} value={(selected as SelectOption)} options={options || []} data-sentry-element="PaymentSelect" data-sentry-component="PaymentSelectCountryCode" data-sentry-source-file="PaymentSelectCountryCode.tsx" />;
};