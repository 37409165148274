import type { FC } from 'react';
import { memo, useCallback } from 'react';
import { selectSettingsLoaded, selectSettingsPageLuckyWheel, useAccessCookie, useQueryPopup } from '@mwl/core-lib';
import { Popup } from '@/components/Popup/Popup';
import { Wheel } from '@/components/Wheel/Wheel';
import { useTypedSelector } from '@/hooks';
import styles from './PopupWheel.module.scss';
const classes = {
  wrapper: styles.wrapper,
  container: styles.popupContainer,
  close: styles.close
};
const BasePopupWheel: FC = () => {
  const {
    show,
    onClose
  } = useQueryPopup('wheel');
  const isLuckyWheelEnabled = useTypedSelector(selectSettingsPageLuckyWheel);
  const isSettingsLoaded = useTypedSelector(selectSettingsLoaded);
  const hasWheelCookie = useAccessCookie('wheel');
  const isShow = show && isSettingsLoaded && isLuckyWheelEnabled && !!hasWheelCookie || null;
  const handleClose = useCallback(() => onClose(), [onClose]);
  return <Popup show={isShow} onClose={handleClose} className={styles.popup} classes={classes} closeButtonColor="default" disableClickOutside data-sentry-element="Popup" data-sentry-component="BasePopupWheel" data-sentry-source-file="PopupWheel.tsx">
      <Wheel data-sentry-element="Wheel" data-sentry-source-file="PopupWheel.tsx" />
    </Popup>;
};
export const PopupWheel = memo(BasePopupWheel);