import type { ParsedUrlQuery } from 'querystring';
import type { CasinoFilterType, LinkUrl } from '@mwl/core-lib';
import { getLinkIsActive, inlineQueries } from '@mwl/core-lib';

export const filtersBySection: Dictionary<Array<CasinoFilterType>> = {
  default: ['genre', 'feature', 'provider'],
  provider: ['genre', 'feature'],
  liveCasino: ['provider'],
};

export const isMenuItemActive = (href: LinkUrl, pathname: string, query: ParsedUrlQuery) => {
  const inlinedPathname = inlineQueries(pathname, query);
  return getLinkIsActive(inlinedPathname, href);
};
