import type { FC } from 'react';
import BurgerIcon from '@public/assets/common/icons/burger.svg';
import CloseIcon from '@public/assets/common/icons/close.svg';
import { CutButton } from '@/components/CutButton/CutButton';
import { useTypedDispatch } from '@/hooks';
import { toggleSidebar } from '@/store/features/ui/actions';
import type { MenuButtonProps } from './MenuButton.types';
const MenuButton: FC<MenuButtonProps> = ({
  shouldOpen,
  ...restProps
}) => {
  const dispatch = useTypedDispatch();
  const handleClick = () => dispatch(toggleSidebar(shouldOpen));
  return <CutButton onClick={handleClick} {...restProps} aria-label="burger" data-sentry-element="CutButton" data-sentry-component="MenuButton" data-sentry-source-file="MenuButton.tsx">
      {shouldOpen ? <BurgerIcon /> : <CloseIcon />}
    </CutButton>;
};
export { MenuButton };