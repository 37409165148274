import type { FC } from 'react';
import { getGameAnalytics } from '@/utils';
import { GameCard } from '../GameCard/GameCard';
import { SliderCards } from '../SliderCards/SliderCards';
import type { GameCardsSliderProps } from './GameCardsSlider.types';
const GameCardsSlider: FC<GameCardsSliderProps> = ({
  games,
  maxDisplayItemsCount,
  totalElements,
  breakpoints,
  analytics,
  classes,
  ...props
}) => {
  const gamesToShow = games.slice(0, maxDisplayItemsCount || games.length);
  if (!gamesToShow.length) {
    return null;
  }
  return <SliderCards totalGamesCount={totalElements} breakpoints={breakpoints} analytics={analytics?.slider} {...props} data-sentry-element="SliderCards" data-sentry-component="GameCardsSlider" data-sentry-source-file="GameCardsSlider.tsx">
      {gamesToShow.map((game, index) => <GameCard className={classes?.card} key={game.id} {...game} analytics={getGameAnalytics({
      analytics: analytics?.game,
      listings: props.listings,
      count: gamesToShow.length,
      game,
      index
    })} />)}
    </SliderCards>;
};
export { GameCardsSlider };