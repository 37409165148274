import type { ChangeEventHandler, FC } from 'react';
import { memo, useCallback } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import qs from 'qs';
import { getURLSearchParams, loginSuccess, removeAllSpaces, resetPasswordByCodeId, useForm, useSubmitFormAuth } from '@mwl/core-lib';
import { Button } from '@/components/Button/Button';
import { FormFieldHidden } from '@/components/FormFieldHidden/FormFieldHidden';
import { FormMessage } from '@/components/FormMessage/FormMessage';
import { Loader } from '@/components/Loader/Loader';
import { useTypedDispatch } from '@/hooks';
import type { FormRecoveryLinkProps, FormRecoveryLinkValues } from './FormRecoveryLink.types';
import { formSettings } from './FormRecoveryLink.utils';
import styles from './FormRecoveryLink.module.scss';
const BaseFormRecoveryLink: FC<FormRecoveryLinkProps> = ({
  className
}) => {
  const {
    t
  } = useTranslation('common');
  const dispatch = useTypedDispatch();
  const router = useRouter();
  const {
    changeField,
    formState,
    handleSubmit
  } = useForm<FormRecoveryLinkValues>(formSettings);
  const {
    state: {
      error,
      isLoading
    },
    onSubmitConfirm
  } = useSubmitFormAuth({
    requestHandler: resetPasswordByCodeId,
    formState: formState.values,
    onSuccess: data => {
      if (data?.token) {
        dispatch(loginSuccess({
          token: data.token
        }));
      }
    }
  });
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    const {
      name,
      value
    } = event.target;
    changeField((name as keyof FormRecoveryLinkValues), removeAllSpaces(value));
  }, [changeField]);
  const submitConfirm = useCallback(async ({
    noSearchNewPassword
  }: FormRecoveryLinkValues) => {
    const result = qs.parse(getURLSearchParams(router.asPath));
    const {
      codeId
    } = (result as {
      codeId?: string;
    });
    if (codeId) {
      onSubmitConfirm({
        password: noSearchNewPassword,
        codeId
      });
    }
  }, [onSubmitConfirm, router.asPath]);
  return <form className={cn(styles.root, className)} onSubmit={handleSubmit(submitConfirm)} data-sentry-component="BaseFormRecoveryLink" data-sentry-source-file="FormRecoveryLink.tsx">
      {error && <FormMessage className={styles.message}>
          {Array.isArray(error.message) ? t(error.message) : error.message}
        </FormMessage>}
      {isLoading && <Loader />}
      <FormFieldHidden placeholder={t('form.field.new_password', 'New password')} value={formState.values.noSearchNewPassword} onChange={handleChange} name="noSearchNewPassword" className={styles.field} isError={Boolean(formState.isTouched && formState.errors?.noSearchNewPassword)} data-sentry-element="FormFieldHidden" data-sentry-source-file="FormRecoveryLink.tsx" />
      <FormFieldHidden placeholder={t('form.field.confirm_password', 'Confirm password')} value={formState.values.noSearchConfirmNewPassword} onChange={handleChange} name="noSearchConfirmNewPassword" className={styles.field} isError={Boolean(formState.isTouched && formState.errors?.noSearchConfirmNewPassword)} data-sentry-element="FormFieldHidden" data-sentry-source-file="FormRecoveryLink.tsx" />
      <Button as="button" variant="fulfilled" color="red" fontSize="xl" fontWeight="bold" size="md" fullWidth className={styles.button} type="submit" rounded data-sentry-element="Button" data-sentry-source-file="FormRecoveryLink.tsx">
        {t('recovery.button.save', 'Save')}
      </Button>
    </form>;
};
const FormRecoveryLink = memo(BaseFormRecoveryLink);
export * from './FormRecoveryLink.types';
export { FormRecoveryLink };