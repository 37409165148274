import type { FC } from 'react';
import { useTranslation } from 'next-i18next';
import { capitalizeFirstLetter } from '@mwl/core-lib';
import { Image } from '@/components/Image/Image';
import { imageRankMap } from './Rank.data';
import type { RankProps } from './Rank.types';
import styles from './Rank.module.scss';
export const Rank: FC<RankProps> = ({
  rank
}) => {
  const {
    t
  } = useTranslation();
  return <div className={styles.root} data-sentry-component="Rank" data-sentry-source-file="Rank.tsx">
      <div className={styles.title}>{t('sidebar.userRank.rank', 'Rank')}</div>
      <div className={styles.text}>
        {t(`sidebar.userRank.${rank.toLowerCase()}`, capitalizeFirstLetter(rank.toLowerCase()))}
      </div>
      <div className={styles.ranks}>
        <Image src={imageRankMap[rank]} className={styles.img} alt={`rank ${rank.toLowerCase()}`} data-sentry-element="Image" data-sentry-source-file="Rank.tsx" />
      </div>
    </div>;
};