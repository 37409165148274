import type { FC } from 'react';
import type { LoyaltyTournament } from '@mwl/core-lib';
import { SliderCards } from '@/components/SliderCards/SliderCards';
import { TournamentCard } from '@/components/TournamentCard/TournamentCard';
import { formatTournamentToTournamentCard } from '@/utils';
import type { TournamentCardAnalytics } from '@/types';
import type { TournamentsProps } from './Tournaments.types';
import styles from './Tournaments.module.scss';
const Tournaments: FC<TournamentsProps> = ({
  tournaments,
  breakpoints,
  maxDisplayItemsCount,
  analytics,
  ...props
}) => {
  const tournamentsToShow = tournaments.slice(0, maxDisplayItemsCount || tournaments.length);
  const getAnalytics = (index: number, item: LoyaltyTournament): TournamentCardAnalytics => {
    const data = {
      count: tournamentsToShow.length,
      position: index + 1,
      href: item.link
    };
    return {
      card: {
        click: {
          eventName: analytics?.tournament?.card?.click?.eventName,
          data: {
            ...(analytics?.tournament?.card?.click?.data || {}),
            ...data
          }
        }
      },
      aboutButton: {
        click: {
          eventName: analytics?.tournament?.aboutButton?.click?.eventName,
          data: {
            ...(analytics?.tournament?.aboutButton?.click?.data || {}),
            ...data
          }
        }
      }
    };
  };
  if (!tournamentsToShow.length) {
    return null;
  }
  return <SliderCards totalGamesCount={tournamentsToShow.length} breakpoints={breakpoints} sliderProps={{
    classes: {
      slide: styles.slide,
      wrapper: styles.wrapper
    }
  }} analytics={analytics?.slider} {...props} data-sentry-element="SliderCards" data-sentry-component="Tournaments" data-sentry-source-file="Tournaments.tsx">
      {tournamentsToShow.map((item, index) => {
      return <TournamentCard key={item.uuid} {...formatTournamentToTournamentCard(item)} link={item.link} analytics={getAnalytics(index, item)} />;
    })}
    </SliderCards>;
};
export { Tournaments };