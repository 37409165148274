import type { GameBlockType, ProductType } from '@mwl/core-lib';
import { createLoadBlockGames, isErrorResult } from '@mwl/core-lib';

import type { SectionGamesLoadableLoad } from '@/components/SectionGamesLoadable/SectionGamesLoadable.types';

/**
 * Функция для загрузки игр по типу и блоку.
 */
export const loadCasinoGamesByType =
  (productType: ProductType, type: GameBlockType): SectionGamesLoadableLoad =>
  async ({ page, platform, currency }, _, axiosInstance) => {
    const result = await createLoadBlockGames(productType)(type)({ platform, currency }, axiosInstance);

    if (isErrorResult(result)) {
      return result;
    }

    return {
      items: result.items,
      page: page + 1,
      maxCount: result.items?.length,
      hasMore: false,
    };
  };
