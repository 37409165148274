import type { FC } from 'react';
import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { extendAnalyticsData, getGameBaseUrl, getProviderUrl, selectUserCurrency, selectUserCurrencySymbol, selectUserIsAuthenticated } from '@mwl/core-lib';
import PlayIcon from '@public/assets/loyalty/icons/play.svg';
import { routes } from '@/constants';
import { useTypedSelector } from '@/hooks';
import { commonObject } from '@/utils';
import { Button } from '../Button/Button';
import { Image } from '../Image/Image';
import { Label } from '../Label/Label';
import type { ConvertedGameBadge, GameCardProps } from './GameCard.types';
import { getButtonPlayProps, getLimitText } from './GameCard.utils';
import styles from './GameCard.module.scss';
export const convertedGameBadge = ({
  newest: 'new',
  tourney: 'tournament'
} as const);
const GameCard: FC<GameCardProps> = ({
  id,
  title,
  style,
  image,
  className,
  productType,
  hasDemoMode,
  badges,
  withPlayButton,
  withProviderButton = true,
  playButtonSize = 'default',
  minBet,
  maxBet,
  enabledCurrencies,
  analytics,
  provider
}) => {
  const {
    t
  } = useTranslation('casino');
  const router = useRouter();
  const currency = useTypedSelector(selectUserCurrency);
  const currencySign = useTypedSelector(selectUserCurrencySymbol);
  const isAuth = useTypedSelector(selectUserIsAuthenticated);
  const playButtonText = t('game.mode.play', 'Play');
  const demoButtonText = t('game.mode.demo', 'Demo');
  const baseUrl = getGameBaseUrl({
    productType,
    casinoGameUrl: routes.casino.game,
    liveCasinoGameUrl: routes.liveCasino.game
  });
  const buttonPlayProps = getButtonPlayProps({
    id,
    isAuth,
    pathname: router.asPath,
    url: baseUrl
  });
  const demoHref = {
    pathname: baseUrl,
    query: {
      gameId: id,
      mode: 'demo'
    }
  };
  const providerLink = getProviderUrl({
    providerTitle: provider?.title,
    providerPathname: routes.providers.provider
  });
  const convertedBadgeNames = useMemo<Array<ConvertedGameBadge>>(() => {
    if (!badges || withPlayButton) {
      return [];
    }
    return badges.map(badge => {
      if (badge === 'newest' || badge === 'tourney') {
        return convertedGameBadge[badge];
      }
      return badge;
    });
  }, [badges, withPlayButton]);
  return <div {...commonObject.gameCard(id.toString()).nodeProps} title={title} className={cn(styles.root, className)} style={style} role="presentation" data-sentry-component="GameCard" data-sentry-source-file="GameCard.tsx">
      <div className={styles.background}>
        <Image src={image} fill alt={title || 'Game Card'} className={styles.backgroundImage} data-sentry-element="Image" data-sentry-source-file="GameCard.tsx" />
      </div>
      <div className={styles.labels}>
        {convertedBadgeNames?.map((label, index) => <Label skew={index % 2 === 0 ? 'left' : 'right'} variant={label} key={label} className={styles.label} />)}
      </div>
      {(minBet || maxBet) && <div className={styles.limits}>{getLimitText(minBet, maxBet, currencySign, currency, enabledCurrencies)}</div>}
      <div className={styles.actions}>
        <div className={styles.actionsWrapper}>
          {withProviderButton && provider?.title && <Button {...commonObject.gameCard(id.toString()).provider.nodeProps} as="link" href={providerLink} variant="text" color="white" className={styles.providerButton} fontWeight="bold" analytics={extendAnalyticsData(analytics?.providerButton, {
          text: demoButtonText
        })}>
              {provider?.title}
            </Button>}

          <div className={styles.playButtonContainer}>
            {withPlayButton ? <Button {...commonObject.gameCard(id.toString()).play.nodeProps} {...buttonPlayProps} as="link" variant="text" color="red" className={cn(styles.playIcon, styles[`${playButtonSize}ButtonSize`])} fontWeight="bold">
                <PlayIcon />
              </Button> : <Button {...commonObject.gameCard(id.toString()).play.nodeProps} {...buttonPlayProps} as="link" variant="fulfilled" color="red" className={styles.playButton} fontWeight="bold" analytics={extendAnalyticsData(analytics?.playButton, {
            text: playButtonText
          })}>
                {playButtonText}
              </Button>}
          </div>

          {hasDemoMode && <Button {...commonObject.gameCard(id.toString()).demo.nodeProps} as="link" href={demoHref} variant="text" color="white" className={styles.demoButton} fontWeight="bold" analytics={extendAnalyticsData(analytics?.demoButton, {
          text: demoButtonText
        })}>
              {demoButtonText}
            </Button>}
        </div>
      </div>
    </div>;
};
export { GameCard };