import type { AppProps } from 'next/app';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import { FingerprintProvider, GrowthBookProvider, initializeAxios } from '@mwl/core-lib';
import { MwlProvider } from '@mwl/ui';
import '@/styles/base.scss';
import { AppsFlyerBanner } from '@/components/AppsFlyerBanner/AppsFlyerBanner';
import { ModalMessageApp } from '@/components/ModalMessageApp/ModalMessageApp';
import { PaymentWidget } from '@/components/PaymentWidget/PaymentWidget';
import { PopupAuth } from '@/components/PopupAuth/PopupAuth';
import { PopupWheel } from '@/components/PopupWheel/PopupWheel';
import { ReCaptcha } from '@/components/ReCaptcha/ReCaptcha';
import { fingerprintEndpoint, theme } from '@/constants';
import { RouterHistoryProvider } from '@/context';
import { Favicon } from '@/Layouts/Favicon/Favicon';
import { Meta } from '@/Layouts/Meta/Meta';
import { Preloader } from '@/Layouts/Preloader/Preloader';
import { PopupPayment } from '@/modules/payment/components/PopupPayment/PopupPayment';
import { SetupProvider } from '@/providers/Setup/SetupProvider';
import { wrapperStore } from '@/store/store';
import nextI18NextConfig from '../../next-i18next.config';
initializeAxios();
const App = ({
  Component,
  pageProps
}: AppProps) => {
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
      </Head>
      <Favicon data-sentry-element="Favicon" data-sentry-source-file="_app.tsx" />
      <Meta data-sentry-element="Meta" data-sentry-source-file="_app.tsx" />
      <AppsFlyerBanner data-sentry-element="AppsFlyerBanner" data-sentry-source-file="_app.tsx" />
      <Preloader data-sentry-element="Preloader" data-sentry-source-file="_app.tsx" />
      <RouterHistoryProvider data-sentry-element="RouterHistoryProvider" data-sentry-source-file="_app.tsx">
        <FingerprintProvider endpoint={fingerprintEndpoint} brandName="Banzai" data-sentry-element="FingerprintProvider" data-sentry-source-file="_app.tsx">
          <GrowthBookProvider data-sentry-element="GrowthBookProvider" data-sentry-source-file="_app.tsx">
            <MwlProvider theme={theme} data-sentry-element="MwlProvider" data-sentry-source-file="_app.tsx">
              <SetupProvider data-sentry-element="SetupProvider" data-sentry-source-file="_app.tsx">
                <PopupAuth data-sentry-element="PopupAuth" data-sentry-source-file="_app.tsx" />
                <PopupPayment data-sentry-element="PopupPayment" data-sentry-source-file="_app.tsx" />
                <ModalMessageApp data-sentry-element="ModalMessageApp" data-sentry-source-file="_app.tsx" />
                <PopupWheel data-sentry-element="PopupWheel" data-sentry-source-file="_app.tsx" />
                <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
              </SetupProvider>
            </MwlProvider>
          </GrowthBookProvider>
        </FingerprintProvider>
      </RouterHistoryProvider>
      <ReCaptcha style={{
      display: 'none'
    }} size="invisible" badge="inline" data-sentry-element="ReCaptcha" data-sentry-source-file="_app.tsx" />
      <PaymentWidget data-sentry-element="PaymentWidget" data-sentry-source-file="_app.tsx" />
    </>;
};
export default wrapperStore.withRedux(appWithTranslation(App, nextI18NextConfig));