import type { FC } from 'react';
import { DepositProvider, selectUserProfileBonusType, selectUserRefillCount } from '@mwl/core-lib';
import { useTypedSelector } from '@/hooks';
import { DepositContent } from './components/DepositContent/DepositContent';
import { DepositModals } from './components/DepositModals/DepositModals';
import type { DepositProps } from './Deposit.types';
export const Deposit: FC<DepositProps> = ({
  analytics
}) => {
  const userRefillCount = useTypedSelector(selectUserRefillCount);
  const userBonusType = useTypedSelector(selectUserProfileBonusType);
  const isHideBonuses = userRefillCount === 0 && (!userBonusType || userBonusType === 'REFUSAL');
  return <DepositProvider isHideBonuses={isHideBonuses} data-sentry-element="DepositProvider" data-sentry-component="Deposit" data-sentry-source-file="Deposit.tsx">
      <DepositContent analytics={analytics?.content} data-sentry-element="DepositContent" data-sentry-source-file="Deposit.tsx" />
      <DepositModals analytics={analytics?.modals} data-sentry-element="DepositModals" data-sentry-source-file="Deposit.tsx" />
    </DepositProvider>;
};