import type { FC } from 'react';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import type { Swiper } from 'swiper/types';
import { extendAnalyticsData, getTestProps } from '@mwl/core-lib';
import ArrowLeftDouble from '@public/assets/common/icons/arrowLeftDouble.svg';
import ArrowLeftIcon from '@public/assets/common/icons/arrowLeftRound.svg';
import ArrowRightIcon from '@public/assets/common/icons/arrowRightRound.svg';
import { Button } from '@/components/Button/Button';
import { ButtonIcon } from '@/components/ButtonIcon/ButtonIcon';
import { ProviderCard } from '@/components/ProviderCard/ProviderCard';
import type { SliderClasses } from '@/components/Slider/Slider';
import { Slider } from '@/components/Slider/Slider';
import { getAnalyticsMethods } from '@/components/SliderCards/SliderCard.analytics';
import { listings } from '@/constants';
import { commonObject } from '@/utils';
import { sliderParams } from './Providers.data';
import type { ProvidersProps } from './Providers.types';
import styles from './Providers.module.scss';
const sliderClasses: SliderClasses = {
  wrapper: styles.sliderWrapper,
  slide: styles.slide
};
export const Providers: FC<ProvidersProps> = ({
  title,
  href,
  className,
  analytics,
  items,
  ...props
}) => {
  const {
    t
  } = useTranslation();
  const prevRef = useRef<HTMLDivElement | null>(null);
  const nextRef = useRef<HTMLDivElement | null>(null);

  // const { providers } = useCasinoProviders();

  const {
    onTouchEnd,
    onNavigationPrev,
    onNavigationNext
  } = getAnalyticsMethods(analytics?.slider, {
    listings: listings.providers
  });
  const onSwiperInit = useCallback((swiper: Swiper) => {
    const {
      navigation
    } = swiper.params;
    if (typeof navigation === 'boolean' || navigation == null) {
      return;
    }
    navigation.prevEl = prevRef.current;
    navigation.nextEl = nextRef.current;
    navigation.disabledClass = styles.disabledButton;
    swiper.navigation?.init();
  }, []);
  if (!items.length) {
    return null;
  }
  return <div {...getTestProps(props)} className={cn(styles.root, className)} data-sentry-component="Providers" data-sentry-source-file="Providers.tsx">
      <div className={styles.header}>
        <h2 {...commonObject.gamesGridLayout.title.nodeProps} className={styles.title}>
          <span className={styles.text}>{title}</span>
        </h2>

        <div className={styles.arrows}>
          <div className={cn(styles.arrow, styles.prev)} ref={prevRef}>
            <ButtonIcon as="button" variant="text" size="auto" rounded className={styles.arrowButton} aria-label="providers prev" data-sentry-element="ButtonIcon" data-sentry-source-file="Providers.tsx">
              <ArrowLeftIcon width={24} height={24} data-sentry-element="ArrowLeftIcon" data-sentry-source-file="Providers.tsx" />
            </ButtonIcon>
          </div>
          <div className={cn(styles.arrow, styles.next)} ref={nextRef}>
            <ButtonIcon as="button" variant="text" size="auto" rounded className={styles.arrowButton} aria-label="providers next" data-sentry-element="ButtonIcon" data-sentry-source-file="Providers.tsx">
              <ArrowRightIcon width={24} height={24} data-sentry-element="ArrowRightIcon" data-sentry-source-file="Providers.tsx" />
            </ButtonIcon>
          </div>

          {href && <Button {...commonObject.gamesGridLayout.allButton.nodeProps} as="link" href={href} color="white" variant="fulfilled" cut={false} className={styles.all} fontWeight="bold" analytics={extendAnalyticsData(analytics?.slider?.moreButton, {
          listings: listings.providers,
          text: t('all'),
          href
        })}>
              {t('all')}
              <ArrowLeftDouble width={14} height={12} />
            </Button>}
        </div>
      </div>

      <Slider {...sliderParams} className={styles.slider} classes={sliderClasses} navigation={{
      prevEl: prevRef?.current,
      nextEl: nextRef?.current,
      disabledClass: styles.disabledButton
    }} onInit={onSwiperInit} onTouchEnd={onTouchEnd} onNavigationNext={onNavigationNext} onNavigationPrev={onNavigationPrev} data-sentry-element="Slider" data-sentry-source-file="Providers.tsx">
        {items.map((provider, index) => <ProviderCard className={styles.providerCard} key={provider.id} {...provider} width="auto" analytics={extendAnalyticsData(analytics?.provider, {
        listings: listings.providers,
        img: provider.image,
        count: items.length,
        position: index + 1
      })} />)}
      </Slider>
    </div>;
};