import { forwardRef } from 'react';
import { useTranslation } from 'next-i18next';
import { FormFieldSearchBase } from '@mwl/ui';
import CloseIcon from '@public/assets/common/icons/close.svg';
import SearchIcon from '@public/assets/common/icons/search.svg';
import { FormField } from '@/components/FormField/FormField';
import type { FormFieldSearchProps } from './FormFieldSearch.types';
import styles from './FormFieldSearch.module.scss';
const FormFieldSearch = forwardRef<HTMLInputElement, FormFieldSearchProps>(({
  className,
  classes = {},
  value,
  onChange,
  placeholder,
  color,
  ...props
}, ref) => {
  const {
    t
  } = useTranslation();
  const defaultPlaceholder = t('search_placeholder', 'Search...');
  return <FormFieldSearchBase {...props} ref={ref} component={FormField} as="input" placeholder={placeholder ?? defaultPlaceholder} className={cn(styles.field, className)} classes={{
    ...classes,
    clearBtn: cn(styles.clearBtn, classes?.clearBtn),
    searchBtn: cn(styles.searchBtn, classes?.searchBtn)
  }} searchIcon={<SearchIcon />} clearIcon={<CloseIcon width={10} height={10} />} value={value} onChange={onChange} />;
});
export * from './FormFieldSearch.types';
export { FormFieldSearch };