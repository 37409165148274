import { selectCasinoFavoritesList, selectUserIsAuthenticated } from '@mwl/core-lib';

import type { MenuItemRenderCondition, NavMenuItem } from '@/types';

import type { MediaWidthSize } from './useMediaWidthDown';
import { useMediaWidthDown } from './useMediaWidthDown';
import { useTypedSelector } from './useTypedSelector';

interface Params {
  items: Array<NavMenuItem>;
  mobileWidth?: MediaWidthSize;
}

/**
 * Создает функцию для проверки условия отображения элемента меню.
 * @param condition Условие для проверки.
 */
function when<T extends MenuItemRenderCondition>(condition: T) {
  return {
    /**
     * Ограничивает отображение элемента меню на основе заданных условий.
     * @param restricted Список условий, при которых элемент не должен отображаться.
     */
    restrict(restricted: Array<Exclude<MenuItemRenderCondition, T>>) {
      return (item: NavMenuItem, actual: Array<MenuItemRenderCondition>): boolean => {
        if (item.render?.includes(condition)) {
          // Проверяем, не пересекаются ли ограничения с текущими условиями
          return !restricted.some((value) => actual.includes(value));
        }

        // Если условие не включено в render, элемент остается без изменений
        return true;
      };
    },
  };
}

const conditions = [
  when('auth').restrict(['not_auth']),
  when('not_auth').restrict(['auth']),
  when('mobile').restrict(['desktop']),
  when('desktop').restrict(['mobile']),
  when('favorites_empty').restrict(['favorites_non_empty']),
  when('favorites_non_empty').restrict(['favorites_empty']),
];

/**
 * Фильтрует элементы меню на основе текущих условий отображения.
 * @param items Список элементов меню.
 * @param mobileWidth Размер ширины экрана для определения мобильного устройства.
 * @returns Отфильтрованный список элементов меню.
 */
export const useRenderMenuItems = ({ items, mobileWidth = 'screenXs' }: Params) => {
  const isAuth = useTypedSelector(selectUserIsAuthenticated);
  const isMobile = useMediaWidthDown(mobileWidth);

  const favorites = useTypedSelector(selectCasinoFavoritesList);
  const favoritesCount = Object.values(favorites).filter(Boolean).length;

  // Формируем список текущих условий на основе аутентификации и ширины экрана
  const actual: Array<MenuItemRenderCondition> = [
    isAuth ? 'auth' : 'not_auth',
    isMobile ? 'mobile' : 'desktop',
    favoritesCount > 0 ? 'favorites_non_empty' : 'favorites_empty',
  ];

  return items.filter((item) => {
    // Проверяем, удовлетворяет ли элемент всем условиям
    return conditions.every((fn) => fn(item, actual));
  });
};
