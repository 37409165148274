import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { ReCAPTCHAProps } from 'react-google-recaptcha';
import ReactGoogleRecaptcha from 'react-google-recaptcha';
import { RECAPTCHA_ENABLE_EVENT, selectSettingsLoaded, selectSettingsVerification, selectUserLocale, setRecaptchaToken } from '@mwl/core-lib';
import { useTypedDispatch, useTypedSelector } from '@/hooks';
interface ReCaptchaProps extends Omit<ReCAPTCHAProps, 'sitekey'> {}
export const ReCaptcha: FC<ReCaptchaProps> = props => {
  const rootRef = useRef<ReactGoogleRecaptcha | null>(null);
  const [recaptchaScript, setRecaptchaScript] = useState(false);
  const dispatch = useTypedDispatch();
  const settingsLoaded = useTypedSelector(selectSettingsLoaded);
  const verificationSettings = useTypedSelector(selectSettingsVerification);
  const currentLocaleCode = useTypedSelector(selectUserLocale);
  const recaptchaExecute = useCallback(() => {
    setRecaptchaScript(true);
    if (verificationSettings?.enabled) {
      rootRef.current?.execute();
    }
  }, [verificationSettings]);
  useEffect(() => {
    window.addEventListener(RECAPTCHA_ENABLE_EVENT, recaptchaExecute);
    return () => {
      window.removeEventListener(RECAPTCHA_ENABLE_EVENT, recaptchaExecute);
    };
  }, [recaptchaExecute]);
  const handleChange = useCallback((token: string | null) => {
    if (!token) {
      return;
    }
    dispatch(setRecaptchaToken(token));
    rootRef.current?.reset();
  }, [dispatch]);
  const siteKey = useMemo(() => {
    if (!verificationSettings || !verificationSettings.recaptchaKeys.WEB) {
      return undefined;
    }
    return verificationSettings.recaptchaKeys.WEB;
  }, [verificationSettings]);
  if (!recaptchaScript || !settingsLoaded || !siteKey || !verificationSettings?.enabled) {
    return null;
  }
  return <ReactGoogleRecaptcha {...props} asyncScriptOnLoad={recaptchaExecute} key={currentLocaleCode} onChange={handleChange} hl={currentLocaleCode} ref={rootRef} sitekey={siteKey} data-sentry-element="ReactGoogleRecaptcha" data-sentry-component="ReCaptcha" data-sentry-source-file="ReCaptcha.tsx" />;
};