import { useTranslation } from 'next-i18next';
import type { FormBase, PaymentMethod, PaymentMethodField, PaymentMethodFieldCaptionPosition, PaymentMethodGroup } from '@mwl/core-lib';
import { isPaymentFieldHidden, isPaymentFieldReadonly } from '@mwl/core-lib';
import type { PaymentFieldValue } from '../../types';
import { PaymentField } from '../PaymentField/PaymentField';
import type { PaymentFieldListProps } from './PaymentFieldList.types';
import styles from './PaymentFieldList.module.scss';
interface GetAmountWithCommissionParams extends Pick<PaymentMethod, 'commission'> {
  amount?: string | number | null;
}
const getAmountWithCommission = ({
  amount,
  commission
}: GetAmountWithCommissionParams): number => {
  if (amount === null || amount === undefined) {
    return 0;
  }
  if (commission?.type === 'INCLUDED') {
    return +amount - +amount * (commission.percent / 100);
  }
  return +amount;
};
export const PaymentFieldList = <T extends FormBase,>({
  className,
  commission,
  fields,
  groups = [],
  parentRef,
  formState,
  quickTipsHide,
  onChange,
  analytics
}: PaymentFieldListProps<T>) => {
  const {
    t
  } = useTranslation('payment');
  const renderedGroups = new Set<string>();
  const amountWithCommission = getAmountWithCommission({
    amount: (formState.values.amount as string | undefined),
    commission
  });
  const renderField = (field: PaymentMethodField, fieldClass?: string) => {
    return <div className={fieldClass} key={field.name} data-sentry-component="renderField" data-sentry-source-file="PaymentFieldList.tsx">
        <PaymentField {...field} parentRef={parentRef} quickTipsHide={quickTipsHide} value={((formState.values[field.name] || '') as PaymentFieldValue)} onChange={onChange(field.name)} isError={Boolean(formState.isTouched && formState.errors?.[field.name])} readOnly={isPaymentFieldReadonly(field)} hidden={isPaymentFieldHidden(field, formState.values)} commission={commission} amountWithCommission={amountWithCommission} analytics={analytics} data-sentry-element="PaymentField" data-sentry-source-file="PaymentFieldList.tsx" />
        {Boolean(formState.isTouched && formState.errors?.[field.name]) && <div className={styles.error}>{formState.errors[field.name]}</div>}
      </div>;
  };
  const renderGroupCaption = (group: PaymentMethodGroup, position: PaymentMethodFieldCaptionPosition) => {
    const key = group.captions?.find(caption => caption.position === position)?.value ?? '';
    if (!key) {
      return null;
    }
    return <div className={cn(styles.caption, styles[position])} data-sentry-component="renderGroupCaption" data-sentry-source-file="PaymentFieldList.tsx">{t(key)}</div>;
  };
  const renderGroup = (groupName: string) => {
    const targetGroup = groups.find(group => group.name === groupName);
    const groupFields = fields?.filter(field => field.group === groupName);
    if (groupFields?.length && targetGroup) {
      renderedGroups.add(groupName);
      return <div className={className} key={groupName}>
          {renderGroupCaption(targetGroup, 'above')}
          <div className={styles.group}>{groupFields.map(field => renderField(field, styles.field))}</div>
          {renderGroupCaption(targetGroup, 'below')}
        </div>;
    }
    return null;
  };
  return <>
      {fields?.map(field => {
      if (field.group) {
        return !renderedGroups.has(field.group) ? renderGroup(field.group) : null;
      }
      return renderField(field, className);
    })}
    </>;
};