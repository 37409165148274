import type { FC } from 'react';
import { useRouter } from 'next/router';
import { casinoNavMenu, liveCasinoNavMenu, routes, sidebarTopSectionNavMenuCasino, sidebarTopSectionNavMenuLiveCasino } from '@/constants';
import { useRenderMenuItems } from '@/hooks';
import { useMenuItems } from '../../hooks/useMenuItems';
import { SidebarNavMenu } from '../SidebarNavMenu/SidebarNavMenu';
import { SidebarTopSection } from '../SidebarTopSection/SidebarTopSection';
import styles from './Sidebar.module.scss';
export const Sidebar: FC = () => {
  const router = useRouter();
  const menuNavItems = router.asPath.includes(routes.liveCasino.home) ? liveCasinoNavMenu : casinoNavMenu;
  const menuItemsTop = router.asPath.includes(routes.liveCasino.home) ? sidebarTopSectionNavMenuLiveCasino : sidebarTopSectionNavMenuCasino;
  const filteredTopItems = useRenderMenuItems({
    items: menuItemsTop
  });
  const filteredNavItems = useRenderMenuItems({
    items: menuNavItems
  });
  const {
    navItems,
    topItems
  } = useMenuItems({
    navItems: filteredNavItems,
    topItems: filteredTopItems
  });
  return <div className={styles.wrapper} data-sentry-component="Sidebar" data-sentry-source-file="Sidebar.tsx">
      <aside className={styles.root}>
        <SidebarTopSection list={topItems} data-sentry-element="SidebarTopSection" data-sentry-source-file="Sidebar.tsx" />
        <SidebarNavMenu list={navItems} data-sentry-element="SidebarNavMenu" data-sentry-source-file="Sidebar.tsx" />
      </aside>
    </div>;
};