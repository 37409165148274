import type { GetPinnedLineAnalytics } from './PinnedLine.types';
export const getPinnedLineAnalytics: GetPinnedLineAnalytics = ({
  line,
  isFavorite
}) => {
  const sportCategory = line.sport.title;
  const category = line.category.title;
  const championships = line.championship.title;
  const matchId = line.id;
  const lineId = line.id;
  const listings = 'topEvents';
  return {
    sportCategory: {
      click: {
        eventName: 'sportTopEvents_sportCategory_click',
        data: {
          sport_category: sportCategory,
          category,
          championships,
          match_id: matchId,
          line_id: lineId,
          listings
        }
      }
    },
    categoryName: {
      click: {
        eventName: 'sportTopEvents_categoryName_click',
        data: {
          sport_category: sportCategory,
          category,
          listings
        }
      }
    },
    championshipName: {
      click: {
        eventName: 'sportTopEvents_championshipName_click',
        data: {
          sport_category: sportCategory,
          category,
          championships,
          line_id: lineId,
          listings
        }
      }
    },
    matchName: {
      click: {
        eventName: 'sportTopEvents_matchName_click',
        data: {
          sport_category: sportCategory,
          category,
          championships,
          match_id: matchId,
          line_id: lineId,
          listings
        }
      }
    },
    lineGroup: {
      click: {
        eventName: isFavorite ? 'sportTopEvents_lineGroup_addFavorite_icon_click' : 'sportTopEvents_lineGroup_delFavorite_icon_click',
        data: {
          sport_category: sportCategory,
          category,
          championships,
          line_id: lineId,
          listings
        }
      }
    },
    odd: {
      select: {
        eventName: 'sportTopEvents_odd_select',
        data: {
          sport_category: sportCategory,
          category,
          championships,
          match_id: matchId,
          line_id: lineId,
          listings
        }
      },
      unselect: {
        eventName: 'sportTopEvents_odd_unselect',
        data: {
          sport_category: sportCategory,
          category,
          championships,
          match_id: matchId,
          line_id: lineId,
          listings
        }
      }
    }
  };
};