import type { FC } from 'react';
import { useRouter } from 'next/router';
import { selectCasinoWinners } from '@mwl/core-lib';
import { SliderBanner } from '@/components/SliderBanner/SliderBanner';
import { Winners } from '@/components/Winners/Winners';
import { routes } from '@/constants';
import { useTypedSelector } from '@/hooks';
import { casinoLayoutObject } from '@/utils';
import type { CasinoLayoutBannersProps } from './CasinoLayoutBanners.types';
import styles from './CasinoLayoutBanners.module.scss';
export const CasinoLayoutBanners: FC<CasinoLayoutBannersProps> = ({
  banners
}) => {
  const {
    asPath
  } = useRouter();
  const winners = useTypedSelector(selectCasinoWinners);
  const isLiveCasinoPage = asPath.includes(routes.liveCasino.home);
  if (!banners) {
    return null;
  }
  return <div className={styles.banner} data-sentry-component="CasinoLayoutBanners" data-sentry-source-file="CasinoLayoutBanners.tsx">
      <div {...casinoLayoutObject.banner.wrapper.nodeProps} className={styles.carousel}>
        <SliderBanner banners={banners} testMarker={casinoLayoutObject.banner} data-sentry-element="SliderBanner" data-sentry-source-file="CasinoLayoutBanners.tsx" />
      </div>
      <div className={cn(styles.winners, {
      [styles.contentHidden]: !winners.length
    })}>
        <Winners {...casinoLayoutObject.winnersList.nodeProps} productType={isLiveCasinoPage ? 'live_casino' : 'casino'} data-sentry-element="Winners" data-sentry-source-file="CasinoLayoutBanners.tsx" />
      </div>
    </div>;
};