import type { FC } from 'react';
import { memo, useEffect } from 'react';
import { selectUserCurrency, useLoadableItems } from '@mwl/core-lib';
import { useTypedSelector } from '@/hooks';
import { SectionProviders } from '../SectionProviders/SectionProviders';
import type { LoadableProvidersBlockProps } from './LoadableProvidersBlock.types';
import { loadProviders, providersSettingsList } from './LoadableProvidersBlock.utils';
const BaseProviderCardBlock: FC<LoadableProvidersBlockProps> = ({
  resetParams,
  isInfinite,
  requestParams = {},
  setItemsCount,
  onLoadFinished = () => null,
  ...props
}) => {
  const currency = useTypedSelector(selectUserCurrency);
  const {
    displayItems,
    itemsLength,
    loadMoreHandler,
    loadMore,
    isError
  } = useLoadableItems({
    loadItems: loadProviders(requestParams),
    onLoadFinished,
    settingsList: providersSettingsList,
    resetParams,
    currency
  });
  useEffect(() => {
    setItemsCount?.(itemsLength);
  }, [itemsLength, setItemsCount]);
  return <SectionProviders providers={displayItems} loadNext={loadMoreHandler} isInfinite={isInfinite && !isError} hasNext={Boolean(loadMore)} {...props} data-sentry-element="SectionProviders" data-sentry-component="BaseProviderCardBlock" data-sentry-source-file="LoadableProvidersBlock.tsx" />;
};
const ProviderCardBlock = memo(BaseProviderCardBlock);
export * from './LoadableProvidersBlock.types';
export { ProviderCardBlock };