import type { TFunction } from 'next-i18next';
import type { PaymentItem, PaymentMethod } from '@mwl/core-lib';
import { calcAvailableWithdrawal, findPaymentData } from '@mwl/core-lib';
import styles from '../../Withdrawal.module.scss';
export const formatWithdrawalMethods = (methods: Array<PaymentMethod>, currencySymbol: string, userBalance: number, t: TFunction): Array<PaymentItem> => {
  if (!methods.length) {
    return [];
  }
  return methods.map<PaymentItem>(({
    title,
    identity,
    form,
    type,
    commission
  }) => {
    const {
      max,
      min
    } = findPaymentData(form.fieldList);
    const availableAmount = calcAvailableWithdrawal({
      fields: form.fieldList,
      userBalance
    });
    const footer = <div className={styles.cardFooter}>
        <span>{t<string>('common:prise.available_withdrawal', 'Available:')}</span>
        <span className={styles.minValue}>
          {currencySymbol} {availableAmount}
        </span>
      </div>;
    return {
      title,
      identity,
      type,
      min: min ? parseFloat(min) : undefined,
      max: max ? parseFloat(max) : undefined,
      commission,
      fieldList: form.fieldList,
      method: form.method,
      action: form.action,
      groupList: form.groupList,
      card: {
        image: form.logo ?? '',
        footer
      }
    };
  });
};