import type { FC } from 'react';
import { memo, useCallback } from 'react';
import { sendAnalyticsData, useAnalyticsOnPopupOpen } from '@mwl/core-lib';
import { PopupBase } from '@mwl/ui';
import { CloseButton } from '../CloseButton/CloseButton';
import type { PopupProps } from './Popup.types';
import styles from './Popup.module.scss';
const BasePopup: FC<PopupProps> = ({
  className,
  show,
  children,
  classes,
  disableClickOutside,
  disableScrollOnFocus = true,
  closeButtonColor = 'white',
  radius = 'md',
  analytics,
  onClose,
  closeButtonVariant = 'cut',
  ...restProps
}) => {
  const handleClickOutside = useCallback(() => {
    if (!disableClickOutside && analytics?.outsideClick) {
      sendAnalyticsData(analytics.outsideClick.eventName, analytics.outsideClick.data);
    }
  }, [analytics?.outsideClick, disableClickOutside]);
  const analyticsCloseIconClass: string | undefined = analytics?.closeIconClick?.data?.cls;
  const analyticsOutsideClickClass: string | undefined = analytics?.outsideClick?.data?.cls;
  const buttonIconClassName = cn(analyticsCloseIconClass, styles.close, classes?.close);
  const analyticsButtonIconData = {
    ...(analytics?.closeIconClick?.data || {}),
    cls: analyticsCloseIconClass || buttonIconClassName
  };
  useAnalyticsOnPopupOpen(show, analytics);
  const closeButton = <CloseButton color={closeButtonColor} className={buttonIconClassName} classes={{
    closeIcon: classes?.closeIcon
  }} analytics={{
    click: {
      eventName: analytics?.closeIconClick?.eventName,
      data: analyticsButtonIconData
    }
  }} cut={closeButtonVariant === 'cut'} onClick={onClose} />;
  return <PopupBase className={cn(styles.root, className)} classes={{
    container: cn(styles.container, classes?.container, analyticsOutsideClickClass),
    wrapper: cn(styles.wrapper, classes?.wrapper),
    overlay: classes?.overlay
  }} show={!!show} onClose={onClose} closeButton={closeButton} onClickOutside={handleClickOutside} isDisableScrollOnInputFocus={disableScrollOnFocus} radius={radius} disableClickOutside={disableClickOutside} {...restProps} data-sentry-element="PopupBase" data-sentry-component="BasePopup" data-sentry-source-file="Popup.tsx">
      {children}
    </PopupBase>;
};
const Popup = memo(BasePopup);
export * from './Popup.types';
export { Popup };