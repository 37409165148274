import type { FC } from 'react';
import { useTranslation } from 'next-i18next';
import ArrowIcon from '@public/assets/common/icons/close.svg';
import { BonusPacketCardButton } from '@/components/BonusPacketCard/components/BonusPacketCardButton/BonusPacketCardButton';
import { ButtonIcon } from '@/components/ButtonIcon/ButtonIcon';
import { PerfectScrollbar } from '@/components/PerfectScrollbar/PerfectScrollbar';
import { useMaxHeight } from '@/hooks';
import type { BonusPacketCardInfoProps } from './BonusPacketCardInfo.types';
import styles from './BonusPacketCardInfo.module.scss';
const BonusPacketCardInfo: FC<BonusPacketCardInfoProps> = ({
  className,
  classes,
  available,
  withButton,
  title,
  description,
  packetDetails,
  parentRef,
  onClose
}) => {
  const {
    t
  } = useTranslation('common');
  const maxHeight = useMaxHeight(parentRef);
  return <div className={cn(styles.root, className, classes?.infoRoot)} style={{
    maxHeight
  }} data-sentry-component="BonusPacketCardInfo" data-sentry-source-file="BonusPacketCardInfo.tsx">
      <div className={styles.header}>
        <h3 className={styles.title}>{title}</h3>

        <ButtonIcon as="button" type="button" size="auto" rounded className={cn(styles.button, classes?.closeButton)} onClick={onClose} aria-label="bonus packet card info close" data-sentry-element="ButtonIcon" data-sentry-source-file="BonusPacketCardInfo.tsx">
          <ArrowIcon className={cn(styles.icon, classes?.closeIcon)} data-sentry-element="ArrowIcon" data-sentry-source-file="BonusPacketCardInfo.tsx" />
        </ButtonIcon>
      </div>
      <PerfectScrollbar className={cn(styles.scrollbar, classes?.scrollbar)} data-sentry-element="PerfectScrollbar" data-sentry-source-file="BonusPacketCardInfo.tsx">
        {[...packetDetails, ...packetDetails].map(({
        key,
        value
      }, index) => <div className={cn(styles.line, classes?.line)} key={`${key}_${index}`}>
            <p className={styles.lineTitle}>{t(key)}</p>
            <p className={styles.lineText}>{value}</p>
          </div>)}
      </PerfectScrollbar>
      {description && <div className={styles.footer}>
          <p className={styles.footerTitle}>{t('bonus_packet.conditions', 'Conditions')}</p>
          <p className={styles.footerText}>{t(description)}</p>
        </div>}
      {withButton && <div className={styles.buttonContainer}>
          <BonusPacketCardButton available={available} className={styles.buttonFooter} />
        </div>}
    </div>;
};
export { BonusPacketCardInfo };