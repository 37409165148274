import type { FC } from 'react';
import { memo } from 'react';
import { useTranslation } from 'next-i18next';
import medalImageMobile from '@public/assets/register/images/ballsAndMedal.webp';
import groupMobileImage from '@public/assets/register/images/chipAndDice.webp';
import groupImage from '@public/assets/register/images/groupSpaced.webp';
import medalImage from '@public/assets/register/images/medal.webp';
import { Image } from '@/components/Image/Image';
import { useRegister } from '@/context';
import { useMediaWidthDown } from '@/hooks';
import type { AuthContainerHeaderProps } from './AuthContainerHeader.types';
import styles from './AuthContainerHeader.module.scss';
const BaseAuthContainerHeader: FC<AuthContainerHeaderProps> = ({
  className,
  variant,
  showText = true
}) => {
  const {
    t
  } = useTranslation('notification');
  const {
    selectedBonus
  } = useRegister();
  const isSmallScreen = useMediaWidthDown('screenXs');
  const medalImageVariant = isSmallScreen && variant !== 'login' ? medalImageMobile : medalImage;
  const groupImageVariant = isSmallScreen && variant !== 'login' ? groupMobileImage : groupImage;
  const depositText = selectedBonus?.type === 'REFUSAL' ? t('reg_popup_without_bonuses', 'Without Bonuses') : t('reg_popup_for_the_first_deposit', 'For the first deposit');
  const prizeText = selectedBonus?.type === 'REFUSAL' ? t('reg_popup_title_without_bonuses', 'Registration') : selectedBonus?.bonusTitle;
  return <div className={cn(styles.root, className, styles[`${variant}Variant`])} data-sentry-component="BaseAuthContainerHeader" data-sentry-source-file="AuthContainerHeader.tsx">
      <div className={styles.medalImg}>
        <Image src={medalImageVariant} alt="Medal" style={{
        width: '100%',
        height: 'auto'
      }} priority data-sentry-element="Image" data-sentry-source-file="AuthContainerHeader.tsx" />
      </div>
      {showText && <div className={styles.text}>
          <span className={styles.bonus}>{prizeText}</span>
          <span className={styles.deposit}>{depositText}</span>
        </div>}
      <div className={styles.groupImg}>
        <Image src={groupImageVariant} alt="Bonuses" style={{
        width: '100%',
        height: 'auto'
      }} priority data-sentry-element="Image" data-sentry-source-file="AuthContainerHeader.tsx" />
      </div>
    </div>;
};
const AuthContainerHeader = memo(BaseAuthContainerHeader);
export { AuthContainerHeader };