import type { FC } from 'react';
import { useMemo } from 'react';
import type { PaymentLayoutClasses } from '../../../../components/PaymentLayout/PaymentLayout';
import { PaymentLayout } from '../../../../components/PaymentLayout/PaymentLayout';
import { usePayloadWithdrawal } from '../../hooks/usePayloadWithdrawal/usePayloadWithdrawal';
import { useWithdrawal } from '../../Withdrawal.context';
import { PayoutBanForm } from '../PayoutBanForm/PayoutBanForm';
import { WithdrawalForm } from '../WithdrawalForm/WithdrawalForm';
import { WithdrawalModals } from '../WithdrawalModals/WithdrawalModals';
import styles from './WithdrawalContent.module.scss';
const layoutClasses: PaymentLayoutClasses = {
  methods: styles.methods,
  form: styles.form,
  content: styles.content
};
export const WithdrawalContent: FC = () => {
  const {
    isLoading,
    methods,
    prohibitionReasons
  } = usePayloadWithdrawal();
  const {
    payment,
    changePayment
  } = useWithdrawal();
  const warningForm = useMemo(() => {
    if (payment && prohibitionReasons.includes('PAYOUT_BAN')) {
      return <PayoutBanForm />;
    }
    return null;
  }, [payment, prohibitionReasons]);
  return <PaymentLayout className={cn(styles.root, {
    [styles.withWarning]: !!warningForm
  })} classes={layoutClasses} payment={payment?.method} methods={methods} isLoading={isLoading} onChangePayment={changePayment} data-sentry-element="PaymentLayout" data-sentry-component="WithdrawalContent" data-sentry-source-file="WithdrawalContent.tsx">
      {warningForm}
      {payment && !warningForm && <WithdrawalForm />}
      <WithdrawalModals data-sentry-element="WithdrawalModals" data-sentry-source-file="WithdrawalContent.tsx" />
    </PaymentLayout>;
};