import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import type { CountdownRendererFn } from 'react-countdown';
import Countdown from 'react-countdown';
import { zeroPad } from '@mwl/core-lib';
import { NoSSR } from '@mwl/ui';
import type { SportClockProps } from './SportClock.types';
export const SportClock: FC<SportClockProps> = ({
  clock,
  status,
  stage,
  className,
  classes
}) => {
  const countdownRef = useRef<Countdown | null>(null);
  const date = useMemo(() => {
    if (!clock.time) {
      return null;
    }
    let newDateTimeStamp: number;
    if (clock.direction === 'BACKWARD') {
      const delta = (Date.now() - new Date(clock.tickedAt).getTime()) / 1000;
      const secondsTillEnd = delta >= clock.time ? clock.time : clock.time - delta;
      newDateTimeStamp = Date.now() + secondsTillEnd * 1000;
    }
    if (clock.stopped || status === 'CLOSED' && stage === 'LIVE') {
      newDateTimeStamp = new Date().getTime() - clock.time * 1000;
    } else {
      newDateTimeStamp = new Date(clock.tickedAt).getTime() - clock.time * 1000;
    }
    return new Date(newDateTimeStamp);

    // Если всегда обновлять date, то будут постоянные скачки времени (между серверным и клиентским временем есть задержка).
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clock.stopped, clock.direction]);
  useEffect(() => {
    if (countdownRef.current) {
      const isClockRunning = !clock.stopped;
      const isClockPaused = clock.stopped || status === 'CLOSED' && stage === 'LIVE';
      if (isClockRunning && !countdownRef.current.isStarted()) {
        countdownRef.current.start();
      }
      if (isClockPaused) {
        countdownRef.current.pause();
      }
    }
  }, [clock.stopped, status, stage]);
  const rendererCountdown: CountdownRendererFn = useCallback(({
    hours,
    minutes,
    seconds
  }) => {
    return <>
          <span className={classes?.minutes}>{zeroPad(hours * 60 + minutes, 2)}</span>:
          <span className={classes?.seconds}>{zeroPad(seconds, 2)}</span>
        </>;
  }, [classes]);
  if (!date) {
    return null;
  }
  return <NoSSR data-sentry-element="NoSSR" data-sentry-component="SportClock" data-sentry-source-file="SportClock.tsx">
      <div className={className}>
        <Countdown ref={countdownRef} date={date} overtime={clock.direction !== 'BACKWARD'} renderer={rendererCountdown} data-sentry-element="Countdown" data-sentry-source-file="SportClock.tsx" />
      </div>
    </NoSSR>;
};