import type { UrlObject } from 'url';
import { getTestProps } from '@mwl/core-lib';

import { routes } from '@/constants';
import { userMenuObject } from '@/utils';

import type { SidebarMenuElements } from './Sidebar.types';

export const getSidebarMenuElements = (aviatorHref: UrlObject): SidebarMenuElements => {
  return [
    {
      opened: true,
      static: true,
      children: [
        {
          ...getTestProps(userMenuObject.promoSection.promoItem('bonus').nodeProps),
          label: ['sidebar.menu.bonus', 'bonus'],
          icon: '/assets/sidebar/icons/bonuses.svg',
          link: routes.bonuses,
          analytics: {
            click: {
              eventName: 'userMenu_promo_link_click',
              data: {
                href: routes.bonuses,
                position: 1,
                count: 5,
              },
            },
          },
        },
        {
          ...getTestProps(userMenuObject.promoSection.promoItem('promo').nodeProps),
          label: ['sidebar.menu.promo', 'promo'],
          icon: '/assets/sidebar/icons/promo.svg',
          link: routes.promo.home,
          analytics: {
            click: {
              eventName: 'userMenu_promo_link_click',
              data: {
                href: routes.promo.home,
                position: 2,
                count: 5,
              },
            },
          },
        },
        {
          ...getTestProps(userMenuObject.promoSection.promoItem('tournaments').nodeProps),
          label: ['sidebar.menu.tournaments', 'tournaments'],
          icon: '/assets/sidebar/icons/tournaments.svg',
          link: routes.tournaments.home,
          analytics: {
            click: {
              eventName: 'userMenu_promo_link_click',
              data: {
                href: routes.tournaments.home,
                position: 3,
                count: 5,
              },
            },
          },
        },
        {
          ...getTestProps(userMenuObject.promoSection.promoItem('loyalty').nodeProps),
          label: ['sidebar.menu.loyalty', 'loyalty program'],
          icon: '/assets/sidebar/icons/loyaltyProgram.svg',
          link: routes.loyalty,
          onlyAuth: true,
          analytics: {
            click: {
              eventName: 'userMenu_promo_link_click',
              data: {
                href: routes.loyalty,
                position: 4,
                count: 5,
              },
            },
          },
        },
        {
          ...getTestProps(userMenuObject.promoSection.promoItem('store').nodeProps),
          label: ['sidebar.menu.store', 'gift store'],
          icon: '/assets/sidebar/icons/giftStore.svg',
          link: routes.store,
          onlyAuth: true,
          analytics: {
            click: {
              eventName: 'userMenu_promo_link_click',
              data: {
                href: routes.store,
                position: 5,
                count: 5,
              },
            },
          },
        },
      ],
    },
    ...(process.env.SPORT_ACCESS
      ? [
          {
            label: ['sidebar.menu.sportsBet', 'sports bet'],
            onlyMobile: true,
            icon: '/assets/sidebar/icons/sportsBet.svg',
            children: [
              {
                label: ['sidebar.menu.live', 'live'],
                link: {
                  pathname: routes.sport.home,
                  query: { st: 'LIVE' },
                },
              },
              {
                label: ['sidebar.menu.pregame', 'pregame'],
                link: {
                  pathname: routes.sport.home,
                  query: { st: 'PREGAME' },
                },
              },
              // { label: ['sidebar.menu.betHistory', 'bet history'], link: routes.sport.history, onlyAuth: true },
              // { label: ['sidebar.menu.favorites', 'favorites'], link: routes.sport.favorites },
            ],
          },
        ]
      : []),
    {
      label: ['sidebar.menu.lucky_wheel', 'lucky wheel'],
      icon: '/assets/sidebar/images/luckyWheel.webp',
      isLuckyWheelBlock: true,
    },
    {
      ...getTestProps(userMenuObject.casinoSection.nodeProps),
      label: ['sidebar.menu.casino', 'casino'],
      link: routes.casino.home,
      icon: '/assets/sidebar/icons/casino.svg',
      onlyMobile: true,
    },
    {
      ...getTestProps(userMenuObject.liveCasinoSection.nodeProps),
      label: ['sidebar.menu.liveCasino', 'live casino'],
      link: routes.liveCasino.home,
      icon: '/assets/sidebar/icons/liveCasino.svg',
      onlyMobile: true,
    },
    {
      ...getTestProps(userMenuObject.aviatorSection.nodeProps),
      label: ['sidebar.menu.aviator', 'aviator'],
      link: aviatorHref,
      icon: '/assets/sidebar/icons/aviator.svg',
      onlyMobile: true,
    },
    {
      ...getTestProps(userMenuObject.bonusBuySection.nodeProps),
      label: ['sidebar.menu.buyBonus', 'buy bonus'],
      link: routes.casino.buyBonus,
      icon: '/assets/sidebar/icons/buyBonus.svg',
      onlyMobile: true,
    },
    { isDivider: true },
    {
      ...getTestProps(userMenuObject.faqSection.nodeProps),
      label: ['sidebar.menu.faq', 'faq'],
      link: routes.faq,
      icon: '/assets/sidebar/icons/faq.svg',
    },
    {
      ...getTestProps(userMenuObject.historySection.nodeProps),
      label: ['sidebar.menu.request_history', 'request history'],
      link: routes.profile.requestHistory,
      icon: '/assets/sidebar/icons/requestHistory.svg',
      onlyAuth: true,
    },
    {
      ...getTestProps(userMenuObject.supportSection.nodeProps),
      label: ['sidebar.menu.support', 'support'],
      link: '',
      icon: '/assets/sidebar/icons/support.svg',
      clickHandlerType: 'support',
    },
    { isDivider: true },
    {
      isDownloadBlock: true,
      analytics: {
        android: {
          click: {
            eventName: 'sidebar_downloadApp_android_icon_click',
          },
        },
        ios: {
          click: {
            eventName: 'sidebar_downloadApp_ios_icon_click',
          },
        },
      },
    },
    // TODO Временно скрываем в рамках MWL-6896
    // {
    //   header: ['sidebar.menu.timeZone', 'time zone'],
    //   label: ['sidebar.menu.timeZone', 'time zone'],
    //   children: [],
    // },
    // {
    //   header: ['sidebar.menu.oddsFormat', 'odds format'],
    //   label: ['sidebar.menu.decimal', 'decimal'],
    //   children: [],
    // },
  ];
};
