import type { FC } from 'react';
import CloseIcon from '@public/assets/common/icons/close.svg';
import { ButtonIcon } from '../ButtonIcon/ButtonIcon';
import { CutButton } from '../CutButton/CutButton';
import type { CloseButtonProps } from './CloseButton.types';
const CloseButton: FC<CloseButtonProps> = ({
  cut,
  classes,
  ...props
}) => {
  if (!cut) {
    return <ButtonIcon {...props} as="button" variant="fulfilled" aria-label="close">
        <CloseIcon className={classes?.closeIcon} />
      </ButtonIcon>;
  }
  return <CutButton {...props} aria-label="close" data-sentry-element="CutButton" data-sentry-component="CloseButton" data-sentry-source-file="CloseButton.tsx">
      <CloseIcon className={classes?.closeIcon} data-sentry-element="CloseIcon" data-sentry-source-file="CloseButton.tsx" />
    </CutButton>;
};
export { CloseButton };