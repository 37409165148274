import type { FC } from 'react';
import Link from 'next/link';
import { Trans, useTranslation } from 'next-i18next';
import { extendAnalyticsData } from '@mwl/core-lib';
import CloseRoundIcon from '@public/assets/common/icons/closeRound.svg';
import { Button } from '@/components/Button/Button';
import type { MessageClasses } from '@/components/Message/Message.types';
import { ModalMessage } from '@/components/ModalMessage/ModalMessage';
import type { PopupClasses } from '@/components/Popup/Popup.types';
import type { UserIsFrozenPopupProps } from './UserIsFrozenPopup.types';
import styles from './UserIsFrozenPopup.module.scss';
const popupClasses: PopupClasses = {
  wrapper: styles.wrapper
};
const messageClasses: MessageClasses = {
  iconWrapper: styles.iconWrapper,
  title: styles.title
};
export const UserIsFrozenPopup: FC<UserIsFrozenPopupProps> = ({
  show,
  onClose,
  onClick,
  analytics
}) => {
  const {
    t
  } = useTranslation('payment');
  const buttonText = t('user_frozen.button', 'Ok');
  return <ModalMessage popupClasses={popupClasses} title={t('user_frozen.title', 'Account frozen')} icon={<CloseRoundIcon className={styles.icon} />} status="error" show={show} onClose={onClose} className={styles.root} classes={messageClasses} analytics={analytics} customAction={<>
          <p className={styles.description}>
            <Trans i18nKey="user_frozen.description" ns="payment" components={[<Link key={0} className={styles.email} href="mailto:id@banzai.bet" />]}>
              {`To protect your account, we have temporarily frozen it. Contact support, they will definitely help you: <0>id@banzai.bet</0>`}
            </Trans>
          </p>
          <Button as="button" onClick={onClick} fontSize="lg" fontWeight="bold" size="md" fullWidth align="center" color="red" rounded analytics={extendAnalyticsData(analytics, {
      text: buttonText
    })}>
            {buttonText}
          </Button>
        </>} data-sentry-element="ModalMessage" data-sentry-component="UserIsFrozenPopup" data-sentry-source-file="UserIsFrozenPopup.tsx" />;
};