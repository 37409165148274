import type { FC } from 'react';
import { memo } from 'react';
import { useTranslation } from 'next-i18next';
import { extendAnalyticsData } from '@mwl/core-lib';
import { Button } from '@/components/Button/Button';
import type { ButtonSignInProps } from './ButtonSignIn.types';
const BaseButtonSignIn: FC<ButtonSignInProps> = ({
  className,
  analytics,
  ...props
}) => {
  const {
    t
  } = useTranslation('common');
  const signInButtonText = t('button.signin', 'Sign In');
  return <Button as="button" variant="fulfilled" color="red" fontSize="lg" fontWeight="bold" size="md" fullWidth className={className} type="submit" rounded analytics={extendAnalyticsData(analytics, {
    text: signInButtonText
  })} {...props} data-sentry-element="Button" data-sentry-component="BaseButtonSignIn" data-sentry-source-file="ButtonSignIn.tsx">
      {signInButtonText}
    </Button>;
};
const ButtonSignIn = memo(BaseButtonSignIn);
export * from './ButtonSignIn.types';
export { ButtonSignIn };