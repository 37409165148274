import type { FC } from 'react';
import { memo } from 'react';
import ShieldIcon from '@public/assets/common/icons/shield.svg';
import type { ProgressBarProps } from './ProgressBar.types';
import styles from './ProgressBar.module.scss';
const BaseProgressBar: FC<ProgressBarProps> = ({
  variant = 'line',
  showShadow = false,
  value,
  percent,
  showValue = true,
  className,
  classes,
  size = 90,
  radiusValue
}) => {
  if (variant === 'circle') {
    const center = size / 2;
    const trackWidth = 16;
    const progressWidth = 16;
    const radius = radiusValue || center - Math.max(trackWidth, progressWidth) / 2;
    const dashArray = 2 * Math.PI * radius;
    const dashOffset = dashArray * ((100 - percent) / 100);
    return <div className={cn(styles.circle, className)}>
        <svg className={styles.circleSvg} style={{
        width: size,
        height: size
      }}>
          <circle className={cn(styles.track, classes?.track)} cx={center} cy={center} fill="transparent" r={radius} strokeWidth={trackWidth} />
          <circle className={cn(styles.progress, classes?.progress)} cx={center} cy={center} fill="transparent" r={radius} strokeWidth={progressWidth} strokeDasharray={dashArray} strokeDashoffset={dashOffset} strokeLinecap="round" />
        </svg>
        {showValue && <span className={styles.value}>{value}</span>}
      </div>;
  }
  return <div className={cn(styles.line, className)} data-sentry-component="BaseProgressBar" data-sentry-source-file="ProgressBar.tsx">
      <div className={cn(styles.progressContainer, classes?.container)}>
        <div className={cn(styles.progress, classes?.bar)} style={{
        width: `${percent}%`
      }} />
        {showShadow && <div className={styles.shadow} />}
      </div>
      {showValue && <div className={styles.valueContainer}>
          <ShieldIcon className={styles.shieldIcon} width={26} height={36} />
          <span className={styles.value}>{value}</span>
        </div>}
    </div>;
};
const ProgressBar = memo(BaseProgressBar);
export * from './ProgressBar.types';
export { ProgressBar };