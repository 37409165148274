import { type FC, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import type { CasinoFilterListItem as CasinoFilterListItemType, CasinoFilterType, TestProps } from '@mwl/core-lib';
import { getTestProps, omitTestProps, sendAnalyticsData, setCasinoFilters, setCasinoFiltersSearchValue, setCasinoFiltersSearchVisible } from '@mwl/core-lib';
import { Checkbox } from '@/components/Checkbox/Checkbox';
import { useTypedDispatch } from '@/hooks';
import { FILTER_ITEM_CHECK, FILTER_ITEM_UNCHECK } from './CasinoFilterListItem.analytics';
interface CasinoFilterListItemProps extends TestProps {
  type: CasinoFilterType;
  item: CasinoFilterListItemType;
  selectedItems: Array<CasinoFilterListItemType>;
  totalCount: number;
  index: number;
}
export const CasinoFilterListItem: FC<CasinoFilterListItemProps> = ({
  type,
  item,
  selectedItems,
  totalCount,
  index,
  ...props
}) => {
  const dispatch = useTypedDispatch();
  const {
    t
  } = useTranslation('casino');
  const checkboxRef = useRef<HTMLInputElement>(null);
  const itemLabelText = t(`main_filter.${type}.${item.title.toLowerCase()}`, item.title);
  const updateFilter = (id: string) => {
    dispatch(setCasinoFilters({
      type,
      id
    }));
    dispatch(setCasinoFiltersSearchVisible(false));
    dispatch(setCasinoFiltersSearchValue(''));
    const isSelected = selectedItems.find(selected => selected.id === id);

    // новое состояние должно быть учтено в количестве выбранных элементов
    const selectedCount = isSelected ? selectedItems.length - 1 : selectedItems.length + 1;
    sendAnalyticsData(isSelected ? FILTER_ITEM_UNCHECK : FILTER_ITEM_CHECK, {
      cls: checkboxRef.current?.className,
      text: itemLabelText,
      count: totalCount,
      count_selected: selectedCount,
      position: index + 1,
      id: item.id,
      filter_name: type
    });
  };
  return <Checkbox {...getTestProps(props)} ref={checkboxRef} wrapperProps={{
    'data-id': item.id
  }} onChange={() => updateFilter(String(item.id))} variant="default" checked={item.selected} {...omitTestProps(props)} data-sentry-element="Checkbox" data-sentry-component="CasinoFilterListItem" data-sentry-source-file="CasinoFilterListItem.tsx">
      {itemLabelText}
    </Checkbox>;
};