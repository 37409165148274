import type { FC } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import { PaymentStatusLink } from '../PaymentStatusLink/PaymentStatusLink';
import type { PaymentStatusProps } from './PaymentStatus.types';
export const PaymentStatus: FC<PaymentStatusProps> = ({
  item,
  onOpenConfirmPopup
}) => {
  const {
    t
  } = useTranslation('payment');
  const translationKey = `payment_status.${item.status?.toLowerCase()}`;
  if (item.status !== 'WAITING_FOR_CONFIRMATION') {
    return <>{item.status && t(translationKey)}</>;
  }
  return <Trans i18nKey={translationKey} components={[<PaymentStatusLink item={item} onOpenConfirmPopup={onOpenConfirmPopup} key={translationKey} />]} ns="payment" data-sentry-element="Trans" data-sentry-component="PaymentStatus" data-sentry-source-file="PaymentStatus.tsx">
      {`Waiting for the  <0>confirmation</0>`}
    </Trans>;
};