import type { FC } from 'react';
import NotFound from '@public/assets/common/images/notFound.webp';
import { Button } from '@/components/Button/Button';
import { Image } from '@/components/Image/Image';
import type { SearchNotFoundProps } from './SearchNotFound.types';
import styles from './SearchNotFound.module.scss';
const SearchNotFound: FC<SearchNotFoundProps> = ({
  onClick,
  title,
  text,
  buttonText,
  className,
  buttonAnalytics
}) => {
  return <div className={cn(styles.root, className)} data-sentry-component="SearchNotFound" data-sentry-source-file="SearchNotFound.tsx">
      <div className={styles.image}>
        <Image src={NotFound} priority className={styles.image} alt={title} height={124} data-sentry-element="Image" data-sentry-source-file="SearchNotFound.tsx" />
      </div>
      <h2 className={styles.title}>{title}</h2>
      {!!text && <p className={styles.text}>{text}</p>}
      <Button onClick={onClick} className={styles.button} as="button" rounded variant="fulfilled" color="red" cut={false} fontSize="lg" fontWeight="bold" size="md" analytics={buttonAnalytics} data-sentry-element="Button" data-sentry-source-file="SearchNotFound.tsx">
        {buttonText}
      </Button>
    </div>;
};
export { SearchNotFound };