import type { FC } from 'react';
import { Button } from '@/components/Button/Button';
import { usePlaceContent } from './TournamentPlace.hooks';
import type { TournamentPlaceProps } from './TournamentPlace.types';
import styles from './TournamentPlace.module.scss';
export const TournamentPlace: FC<TournamentPlaceProps> = ({
  size,
  className,
  ...rest
}) => {
  const content = usePlaceContent(rest);
  if (!content) {
    return null;
  }
  if (size === 'small') {
    return <div className={styles.place}>{content}</div>;
  }
  return <Button as="button" align="center" variant="fulfilled" skew="left" className={cn(styles.button, className)} fontSize="sm" fontWeight="bold" color="red" size="sm" uppercase data-sentry-element="Button" data-sentry-component="TournamentPlace" data-sentry-source-file="TournamentPlace.tsx">
      {content}
    </Button>;
};