import type { FC } from 'react';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import type { BaseQueryPopupParams, BonusPacketRegistrationType } from '@mwl/core-lib';
import { resetRecoveryLogin, selectUserIsAuthenticated, sendAnalyticsData, usePrevious, useQueryPopup, useRegistrationBonuses } from '@mwl/core-lib';
import { ANIMATION_TIME } from '@mwl/ui';
import type { FormLoginTab } from '@/components/FormLogin/FormLogin';
import { FormLogin } from '@/components/FormLogin/FormLogin';
import { FormRecovery } from '@/components/FormRecovery/FormRecovery';
import type { PopupClasses } from '@/components/Popup/Popup';
import { Popup } from '@/components/Popup/Popup';
import { registrationBonuses } from '@/constants';
import { useTypedDispatch, useTypedSelector } from '@/hooks';
import { rootObject } from '@/utils/markerTree';
import { NotRecognize } from './components/NotRecognize/NotRecognize';
import { RegisterContainer } from './components/RegisterContainer/RegisterContainer';
import type { PopupAuthAnalytics } from './PopupAuth.analytics';
import { getLoginAnalytics, getRegisterAnalytics } from './PopupAuth.analytics';
import { useRegisterTabs } from './PopupAuth.hooks';
import styles from './PopupAuth.module.scss';
const popupRegisterClasses: PopupClasses = {
  close: styles.close,
  wrapper: styles.wrapper,
  container: styles.container
};
const popupLoginClasses: PopupClasses = {
  container: styles.container,
  close: styles.close
};
const initialTab: FormLoginTab = 'phone';
const BasePopupAuth: FC = () => {
  const {
    show,
    activeParam,
    onClose
  } = useQueryPopup<Array<BaseQueryPopupParams>>('login', 'register', 'reset_password', 'not_recognize');
  const prevParam = usePrevious(activeParam);
  const bonuses = useRegistrationBonuses(registrationBonuses);
  const {
    registrationTab,
    setRegistrationTab,
    initialRegistrationTab
  } = useRegisterTabs();
  const [localShow, setLocalShow] = useState(false);
  const [registrationBonusType, setRegistrationBonusType] = useState<BonusPacketRegistrationType>(bonuses[0].type);
  const [loginTab, setLoginTab] = useState<FormLoginTab>(initialTab);
  const router = useRouter();
  const dispatch = useTypedDispatch();
  const isAuth = useTypedSelector(selectUserIsAuthenticated);
  const showAuthPopup = !isAuth && localShow;
  const prevShowAuthPopup = usePrevious(showAuthPopup);
  const {
    popup: popupAnalytics,
    ...analytics
  } = useMemo<Partial<PopupAuthAnalytics>>(() => {
    if (activeParam === 'register') {
      return getRegisterAnalytics(registrationTab, registrationBonusType);
    }
    if (activeParam === 'login') {
      return getLoginAnalytics(loginTab);
    }
    return {};
  }, [activeParam, registrationTab, registrationBonusType, loginTab]);
  const handleClose = useCallback(() => {
    setLocalShow(false);
    setLoginTab(initialTab);
    setTimeout(() => {
      onClose({
        closeOnlyCurrentPopup: !!router.query.wheel
      });
      dispatch(resetRecoveryLogin());
    }, ANIMATION_TIME);
  }, [onClose, router.query.wheel, dispatch]);
  const handleAfterClose = useCallback(() => {
    setRegistrationTab(initialRegistrationTab);
  }, [initialRegistrationTab, setRegistrationTab]);
  useEffect(() => {
    if (isAuth && show) {
      onClose();
      dispatch(resetRecoveryLogin());
    }
  }, [dispatch, isAuth, onClose, show]);
  useEffect(() => {
    if (typeof show !== 'undefined') {
      setRegistrationBonusType(bonuses[0].type);
      setLocalShow(show);
    }
  }, [bonuses, show]);
  useEffect(() => {
    if (showAuthPopup && prevShowAuthPopup && activeParam !== prevParam) {
      sendAnalyticsData(popupAnalytics?.open?.eventName, popupAnalytics?.open?.data);
    }
  }, [activeParam, showAuthPopup, prevShowAuthPopup, prevParam, popupAnalytics?.open]);
  return <Popup show={showAuthPopup} onClose={handleClose} onAfterClose={handleAfterClose} classes={activeParam === 'register' ? popupRegisterClasses : popupLoginClasses} className={cn(styles.popup, {
    [styles.popupWithWheel]: !!router.query.wheel
  })} disableScrollOnFocus={false} analytics={popupAnalytics} {...rootObject.popups.auth.nodeProps} data-sentry-element="Popup" data-sentry-component="BasePopupAuth" data-sentry-source-file="PopupAuth.tsx">
      {activeParam === 'login' && <FormLogin tab={loginTab} setTab={setLoginTab} analytics={analytics} />}
      {activeParam === 'reset_password' && <FormRecovery />}
      {activeParam === 'register' && <RegisterContainer tab={registrationTab} setTab={setRegistrationTab} changeBonusType={setRegistrationBonusType} analytics={analytics} />}
      {activeParam === 'not_recognize' && <NotRecognize />}
    </Popup>;
};
const PopupAuth = memo(BasePopupAuth);
export { PopupAuth };